import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { userServiceBaseUrl } from "../user/User.axios";
import { Customer } from "./Customer.types";
/**
 * fetches customer for given uid
 * @param axios network instance
 * @param customerUid uid of customer that should be fetched
 * @returns customer when successful or null on error
 */
export const getCustomer = (
  axios: AxiosInstance,
  customerUid: string
): Promise<Customer | null> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve(null);
  return axios
    .get(`${userServiceBaseUrl}/customer/`, {
      params: { customerUid: customerUid },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error while loading customers", err.message);
      return null;
    });
};
