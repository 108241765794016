import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { userServiceBaseUrl } from "../user/User.axios";
import { Beacon } from "./Beacon.types";

/**
 * API method to update a single {@link Beacon}
 * @param axios  axios instance
 * @param beacon  beacon to update
 * @returns  true if update was successful, false otherwise
 */
export const updateBeacon = async (
  axios: AxiosInstance,
  beacon: Beacon
): Promise<boolean> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve(false);
  return axios
    .post(`${userServiceBaseUrl}/beacon/update/`, beacon)
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Beacon update couldn't be performed", err.message);
      return false;
    });
};
/**
 * API method to update many {@link Beacon}s
 * @param axios  axios instance
 * @param beacons  beacons to update
 * @returns  true if update was successful, false otherwise
 */
export const updateManyBeacons = async (
  axios: AxiosInstance,
  beacons: Beacon[]
): Promise<boolean> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve(false);
  return axios
    .post(`${userServiceBaseUrl}/beacon/update/many/`, beacons)
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Beacon update couldn't be performed", err.message);
      return false;
    });
};

/**
 *  API method to load all {@link Beacon}s
 * @param axios  axios instance
 * @returns  The list of all beacons, can be empty
 */
export const getAllCustomerBeacons = async (
  axios: AxiosInstance,
  customerUid: string
): Promise<Beacon[]> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${userServiceBaseUrl}/beacon/customer/all/`, {
      params: { customerUid },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Beacons for customer couldn't be loaded", err.message);
      return [];
    });
};
