import axios, { AxiosInstance } from "axios";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { loadUser } from "../user/User.axios";
import { handleDates } from "../Axios.utils";
import { User } from "../user/User.types";

/**
 * The context model which is available in the useUser hook
 */
interface UserContextData {
  user: User;
  axios: AxiosInstance;
  update: () => Promise<void>;
}

/**
 * Created react context
 */
const UserContext = createContext<UserContextData>(undefined!);

/**
 * A user provider which provides the user context to all children
 *
 * @param fallback The fallback component which is rendered if the user is not logged in
 * @param loading The loading component which is rendered while the user is loading
 * @param children The children which are rendered if the user is logged in
 */
export const UserProvider: React.FC<{
  fallback?: ReactNode;
  loading?: ReactNode;
  children: ReactNode;
}> = ({ fallback, loading, children }) => {
  const [authUser, authLoading] = useAuthState(auth);
  const [contextState, setContextState] = useState<{
    user: User;
    axios: AxiosInstance;
  }>();

  /**
   * Function to redefine the axios instance with the current Id Token and fetch the current user
   */
  const update = useCallback(async () => {
    if (!authUser) {
      setContextState(undefined);
      return;
    }

    // --- Axios ---
    const token = await authUser.getIdToken();
    const instance = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    });

    instance.interceptors.response.use((originalResponse) => {
      handleDates(originalResponse.data);
      return originalResponse;
    });

    // --- User ---
    const user: User | null = await loadUser(instance, authUser.uid);

    // set states
    setContextState(user ? { user, axios: instance } : undefined);
  }, [authUser]);

  /**
   * effect to load the data of the currently logged in user.
   * triggers everytime the auth state change.
   */
  useEffect(() => {
    if (authLoading) return;
    update();
  }, [authLoading, update]);

  // user & axios is defined therefore logged in
  if (!!contextState)
    return (
      <UserContext.Provider
        value={{
          user: contextState.user,
          axios: contextState.axios,
          update,
        }}
      >
        {children}
      </UserContext.Provider>
    );

  // auth not loading and uauth user undefined therefore logged out
  if (!authLoading && !authUser) return <>{fallback}</>;

  // anywhere else should be in loading state
  return <>{loading}</>;
};

/**
 * Simple shortcut for `useContext(UserContext)`.
 *
 * @returns {@link UserContext}
 */
export const useUser = () => useContext(UserContext);
