import { LoaderComponent } from "articon-component-library";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateOrUpdateUser from "../../components/CreateOrUpdateUser";
import { User } from "../../utils/user/User.types";

const UserEdit: React.FC = () => {
  const navigate = useNavigate();
  const locationState = useLocation<{ selectedUser: User }>();
  const selectedUser: User | undefined = useMemo(
    () => locationState.state?.selectedUser,
    [locationState]
  );

  return selectedUser ? (
    <CreateOrUpdateUser
      userToEdit={selectedUser}
      updateUserLocal={() => navigate(-1)}
    />
  ) : (
    <LoaderComponent />
  );
};

export default UserEdit;
