import { useNavigate } from "react-router-dom";
import CreateOrUpdateStagingRoom from "../../components/CreateOrUpdateStagingRoom";

const StagingRoomCreate: React.FC = () => {
  const navigate = useNavigate();

  return (
    <CreateOrUpdateStagingRoom updateStagingRoomLocal={() => navigate(-1)} />
  );
};

export default StagingRoomCreate;
