import React from "react";
import { useNavigate } from "react-router-dom";
import CreateOrUpdateUser from "../../components/CreateOrUpdateUser";

const UserCreate: React.FC<{}> = () => {
  const navigate = useNavigate();

  return <CreateOrUpdateUser updateUserLocal={() => navigate(-1)} />;
};

export default UserCreate;
