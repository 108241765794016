/**
 * Declare a listener
 */
export type Listener<Data = unknown> = (data: Data) => void;

/**
 * Event manager for (un)subscribing to and emitting events
 */
export class EventManager<Events extends Record<string, any> = {}> {
  /**
   * Map of events and their listeners
   */
  private eventTarget: Map<keyof Events, Set<Listener>> = new Map();

  /**
   * Subscribe to an event
   *
   * @param event the event to subscribe to
   * @param listener the listener to call when the event is emitted
   */
  on<Event extends keyof Events, Data = Events[Event]>(
    event: Event,
    listener: Listener<Data>
  ): void {
    let listeners = this.eventTarget.get(event);
    if (!listeners) {
      listeners = new Set();
      this.eventTarget.set(event, listeners);
    }

    listeners.add(listener as Listener);
  }

  /**
   * Unsubscribe from an event
   *
   * @param event the event to unsubscribe from
   * @param listener the listener to unsubscribe
   */
  off<Event extends keyof Events, Data = Events[Event]>(
    event: Event,
    listener: Listener<Data>
  ): void {
    let listeners = this.eventTarget.get(event);
    if (!listeners) {
      listeners = new Set();
      this.eventTarget.set(event, listeners);
    }

    listeners.delete(listener as Listener);
  }

  /**
   * Emit an event with data
   *
   * @param event the event to emit
   * @param data the data to emit
   */
  emit<Event extends keyof Events, Data = Events[Event]>(
    event: Event,
    data: Data
  ): void {
    const listeners = this.eventTarget.get(event);
    if (!listeners) return;

    listeners.forEach((listener) => listener(data));
  }
}
