import { LatLngTuple } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useMemo } from "react";
import { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Polygon,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import { useUser } from "../../utils/auth/UserContext";
import {
  MapComponentProps,
  MapItem,
  MapLayer,
  MapSection,
} from "../../utils/map/Map.types";
import {
  filterMapLayers,
  generateMapIcon,
  isItemInsideSection,
} from "../../utils/map/Map.utils";
import { updateVehicleWithSection } from "../../utils/vehicle/Vehicle.axios";
import { LassoControl } from "./LassoControl";
import "./MapComponentStyles.scss";
import { useHistory } from "../../utils/history/History.context";

/**
 * Component to display Map with markers and manipulate them
 *
 * @param center starting coords of map, will be in center of it
 * @param mapItems mapItems that will be displayed as markers on Map
 * @param defaultZoom starting zoom level of Map
 * @param disableLasso to disables lasso
 * @param visibleMapLayers MapLayer values, decide which mapItems are displayed
 * @param setLassoHandler will set LassoHandler to enable/disable lasso outside of component, if disableLasso is true, will return undefined
 * @param mapSections MapSections to display as Polygon areas on map
 * @param setLeafletMap sets map once available from useMap hook in lasso toolss
 * @returns MapComponent considering given props
 */
const MapComponent: React.FC<MapComponentProps> = ({
  center,
  mapItems = [],
  defaultZoom = 18,
  disableLasso,
  visibleMapLayers = [],
  setLassoHandler = () => {},
  mapSections = [],
  setLeafletMap,
}) => {
  const [curPosition] = useState<LatLngTuple>(center);
  const [localMapItems, setLocalMapItems] = useState<MapItem[]>(mapItems);
  const { axios } = useUser();
  const { isShifted } = useHistory();

  /**
   * Sets localMapItems to mapItems once mapItems are available
   */
  useEffect(() => {
    setLocalMapItems(mapItems);
  }, [mapItems]);

  /**
   * Handles the dragged Gateway, if inside a section, updates the vehicle with the section uid
   * @param newPosition - position of gateway after drag
   * @param gatewayUid - uid of gateway dragged inside section
   * @param index - index of gateway in localMapItems
   */
  const handleDraggedGateway = (
    newPosition: LatLngTuple,
    gatewayUid: string,
    index: number
  ) => {
    const clonedMapItem: MapItem = { ...localMapItems[index] };
    setLocalMapItems([
      ...localMapItems.slice(0, index),
      clonedMapItem,
      ...localMapItems.slice(index + 1),
    ]);
    const switchedCoordinates: LatLngTuple = [newPosition[1], newPosition[0]];

    const foundSection: MapSection | undefined = mapSections.find((section) =>
      isItemInsideSection(switchedCoordinates, section)
    );
    if (!foundSection) return;
    updateVehicleWithSection(axios, gatewayUid, foundSection.uid);
  };

  const filteredMapItems: MapItem[] = useMemo(
    () => filterMapLayers(localMapItems, visibleMapLayers),
    [localMapItems, visibleMapLayers]
  );

  return (
    <>
      <MapContainer
        doubleClickZoom={false}
        className="map-wrapper"
        center={curPosition ? curPosition : center}
        zoom={defaultZoom}
        zoomControl={false}
      >
        <LassoControl
          setLassoHandler={setLassoHandler}
          setLeafletMap={setLeafletMap}
          disabled={disableLasso}
        />
        <ZoomControl position="bottomright" />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {mapSections.map((section, index) => (
          <Polygon
            key={`section-key-${index}`}
            pathOptions={{ color: section.color, fillColor: section.color }}
            positions={section.coords}
          />
        ))}
        {filteredMapItems.map((element, index) => (
          <Marker
            position={[element.location[0], element.location[1]]}
            key={`item-key-${element.uid}`}
            icon={generateMapIcon(element)}
            title={element.name}
            draggable={
              (element.isGateway || element.layer === MapLayer.HINT) &&
              !isShifted
            }
            eventHandlers={{
              click: () => element.onClick?.(),
              dragend: (dragEvent) => {
                if (element.isGateway)
                  handleDraggedGateway(
                    dragEvent.target.toGeoJSON().geometry.coordinates,
                    element.name,
                    index
                  );
                else if (element.layer === MapLayer.HINT)
                  element.onDragEnd?.(
                    dragEvent.target.toGeoJSON().geometry.coordinates
                  );
              },
            }}
          />
        ))}
      </MapContainer>
    </>
  );
};

export default MapComponent;
