/**
 * Shortcut for promise creation
 *
 * @param func The function which should be executed (async)
 * @returns The promise from the given function
 */
export const promised = <R>(func: () => Promise<R>): Promise<R> =>
  new Promise<R>(async (resolve, reject) => {
    try {
      const result: R = await func();
      resolve(result);
    } catch (err) {
      reject(err);
    }
  });

export const flattenMapValues = <T>(map: Map<string, T[]>): T[] =>
  ([] as T[]).concat(...Array.from(map.values()));
