import { useMemo } from "react";
import { Mission } from "../../../../utils/mission/Mission.types";
import useSWR from "swr";
import { useUser } from "../../../../utils/auth/UserContext";
import {
  getAllVehiclesByCustomer,
  getAllVehiclesByCustomerAndMission,
} from "../../../../utils/vehicle/Vehicle.axios";
import { ReactComponent as GroupIcon } from "../../../../assets/group.svg";
import {
  DisclosureComponent,
  FulfillmentComponent,
} from "articon-component-library";
import { loadManyUsers } from "../../../../utils/user/User.axios";
import {
  createBesatzungsString,
  sortUserAmountByRank,
} from "../../../../utils/user/User.utils";
import { User, UserRank } from "../../../../utils/user/User.types";
import { useTranslation } from "react-i18next";
import { Vehicle } from "../../../../utils/vehicle/Vehicle.types";
import { getAllMissionVehiclesOnApproach } from "../../../../utils/vehicle/assignment/Assignment.utils";
import { getAllGatewaysByCustomer } from "../../../../utils/gateway/Gateway.axios";
import { useHistory } from "../../../../utils/history/History.context";

export enum WidgetType {
  ON_SITE = "ON_SITE",
  ON_APPROACH = "ON_APPROACH",
  ON_STAGING_ROOM = "ON_STAGING_ROOM",
}

const ForcesWidget: React.FC<{
  mission: Mission;
  type: WidgetType;
}> = ({ mission, type }) => {
  const { user, axios } = useUser();
  const { t } = useTranslation();

  /**
   * SWR hook to load all vehicles within the mission
   */
  const vehicles = useSWR(
    ["mission/vehicle/mission/customer", user.customerUid, mission.uid],
    ([, customerUid, missionUid]) =>
      getAllVehiclesByCustomerAndMission(axios, customerUid, missionUid),
    {
      fallbackData: [],
    }
  );

  /**
   * SWR hook to load all vehicles for the logged in  customer
   */
  const allVehicles = useSWR(
    ["mission/vehicle/customer", user.customerUid],
    ([, customerUid]) => getAllVehiclesByCustomer(axios, customerUid),
    {
      fallbackData: [],
    }
  );

  /**
   * Generate and memorize the users within the mission
   */
  const usersWithinMission = useMemo<string[]>(() => {
    return Array.from(mission.fahrzeugeWithUsers.values()).flat();
  }, [mission]);

  /**
   * SWR hook to load all users within the mission
   */
  const missionUsers = useSWR(
    ["mission/user/mission", usersWithinMission],
    ([, userIds]) => loadManyUsers(axios, userIds),
    {
      fallbackData: [],
    }
  );

  const { gateways } = useHistory();

  /**
   * Generate and memorize the besatzung for each section
   */
  const besatzung = useMemo<Map<string, Map<UserRank, number>>>(() => {
    if (!vehicles.data || !allVehicles.data || !missionUsers.data)
      return new Map();
    let localVehicles: Vehicle[] = vehicles.data;
    const vehiclesMapping = getAllMissionVehiclesOnApproach(
      gateways || [],
      vehicles.data,
      mission
    );
    switch (type) {
      case WidgetType.ON_SITE:
        localVehicles = vehiclesMapping.insideMission;
        break;
      case WidgetType.ON_APPROACH:
        localVehicles = vehiclesMapping.outsideMission;
        break;
      case WidgetType.ON_STAGING_ROOM:
        localVehicles = allVehicles.data.filter(
          (vehicle) => !vehicle.missionUid && !!vehicle.stagingRoomUid
        );
    }
    console.log(vehiclesMapping);
    const result: Map<string, Map<UserRank, number>> = new Map<
      string,
      Map<UserRank, number>
    >();

    mission.sections.forEach((section) =>
      result.set(
        section.uid,
        new Map([
          [UserRank.VF, 0],
          [UserRank.ZF, 0],
          [UserRank.GF, 0],
          [UserRank.MANNSCHAFT, 0],
        ])
      )
    );
    result.set(
      "none",
      new Map([
        [UserRank.VF, 0],
        [UserRank.ZF, 0],
        [UserRank.GF, 0],
        [UserRank.MANNSCHAFT, 0],
      ])
    );

    // iterate over all vehicles and set the amount of users per rank
    for (const vehicle of localVehicles) {
      const vehicleUserIds: string[] =
        mission.fahrzeugeWithUsers.get(vehicle.uid) ?? [];
      const vehicleUsers: User[] = missionUsers.data.filter((entry) =>
        vehicleUserIds.includes(entry.uid)
      );
      const usersByRankAmount: Map<UserRank, number> =
        sortUserAmountByRank(vehicleUsers);

      const sectionBestzung: Map<UserRank, number> | undefined =
        vehicle.sectionId ? result.get(vehicle.sectionId) : result.get("none");

      if (!sectionBestzung) continue;

      sectionBestzung.set(
        UserRank.VF,
        (sectionBestzung.get(UserRank.VF) ?? 0) +
          (usersByRankAmount.get(UserRank.VF) ?? 0)
      );
      sectionBestzung.set(
        UserRank.ZF,
        (sectionBestzung.get(UserRank.ZF) ?? 0) +
          (usersByRankAmount.get(UserRank.ZF) ?? 0)
      );
      sectionBestzung.set(
        UserRank.GF,
        (sectionBestzung.get(UserRank.GF) ?? 0) +
          (usersByRankAmount.get(UserRank.GF) ?? 0)
      );
      sectionBestzung.set(
        UserRank.MANNSCHAFT,
        (sectionBestzung.get(UserRank.MANNSCHAFT) ?? 0) +
          (usersByRankAmount.get(UserRank.MANNSCHAFT) ?? 0)
      );
    }

    // generate summary
    const summary: Map<UserRank, number> = new Map();

    result.forEach((entry) => {
      summary.set(
        UserRank.VF,
        (summary.get(UserRank.VF) ?? 0) + (entry.get(UserRank.VF) ?? 0)
      );
      summary.set(
        UserRank.ZF,
        (summary.get(UserRank.ZF) ?? 0) + (entry.get(UserRank.ZF) ?? 0)
      );
      summary.set(
        UserRank.GF,
        (summary.get(UserRank.GF) ?? 0) + (entry.get(UserRank.GF) ?? 0)
      );
      summary.set(
        UserRank.MANNSCHAFT,
        (summary.get(UserRank.MANNSCHAFT) ?? 0) +
          (entry.get(UserRank.MANNSCHAFT) ?? 0)
      );
    });

    result.set("summary", summary);

    return result;
  }, [vehicles, allVehicles, missionUsers, mission]);

  /**
   * Generate and memorize the table body
   */
  const tableContent = useMemo<JSX.Element>(() => {
    const result: JSX.Element[] = [];

    [...mission.sections, { uid: "none", name: undefined }].forEach(
      (section) => {
        const besatzungMap: Map<UserRank, number> | undefined = besatzung.get(
          section.uid
        );
        if (!besatzungMap) return;
        const besatzungsString: string = createBesatzungsString(besatzungMap);

        result.push(
          <tr>
            <td>{section.name ?? "Ohne"}</td>
            <td>
              <FulfillmentComponent customText={besatzungsString} />
            </td>
          </tr>
        );
      }
    );

    return (
      <table className="map-left-menu__forcesOverview__table">
        <colgroup>
          <col className="map-left-menu__forcesOverview__table__column__section-name" />
        </colgroup>
        <thead>
          <tr>
            <th className="map-left-menu__forcesOverview__table__header__section-name">
              {t("components.mapLeftMenu.forcesWidget.sectionName")}
            </th>
            <th>
              <GroupIcon />
            </th>
          </tr>
        </thead>
        <tbody>{result}</tbody>
      </table>
    );
  }, [besatzung, mission, t]);

  /**
   * Generate and memorize the disclosure footer
   */
  const disclosureFooter = useMemo<JSX.Element>(() => {
    const summaryBesatzung: Map<UserRank, number> | undefined =
      besatzung.get("summary");

    if (!summaryBesatzung) return <></>;

    return (
      <div className="map-left-menu__forcesOverview__footer">
        <span>
          <b>{t("components.mapLeftMenu.forcesWidget.summary")}</b>
        </span>
        <span>
          <FulfillmentComponent
            customText={createBesatzungsString(summaryBesatzung)}
            customColor="transparent"
          />
        </span>
      </div>
    );
  }, [besatzung, t]);

  return (
    <DisclosureComponent
      title={t(`components.mapLeftMenu.forcesWidget.title_${type}`)}
      footer={disclosureFooter}
    >
      {tableContent}
    </DisclosureComponent>
  );
};

export default ForcesWidget;
