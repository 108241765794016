import { Address, generateEmptyAddress } from "../customer/Customer.types";

export enum StagingRoomStatus {
  ACTIVE = "ACTIVE",
}

/**
 * Describes a staging room
 */
export interface StagingRoom {
  uid: string;
  createDate: string;
  lastUpdate: string;
  name: string;
  address: Address;
  rangeAutoerfassung: number;
  customerUid: string;
  status: StagingRoomStatus;
  lon: number;
  lat: number;

  /**
   * The assignment radius of the staging room in meters.
   * Used for vehicle assignment.
   */
  assignmentRadius?: number;
}

/**
 * Generates an empty staging room object
 * @returns {StagingRoom} empty staging room object
 */
export const generateEmptyStagingRoom = (): StagingRoom => ({
  uid: "",
  createDate: "",
  lastUpdate: "",
  name: "",
  address: generateEmptyAddress(),
  rangeAutoerfassung: 0,
  status: StagingRoomStatus.ACTIVE,
  customerUid: "",
  lon: 0,
  lat: 0,
});
