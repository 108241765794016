import { Option, TableRow } from "articon-component-library";
import i18n from "../../i18n";
import {
  Hydrant,
  HydrantenDurchmesser,
  HydrantenTyp,
  Verteilerleitung,
} from "./Hydrant.types";

/**
 * Util to create {@link TableRow}s for a list of {@link Hydrant}en
 * @param hydranten the hydranten to create the rows for ;
 * @param onClickRow callback to call when row is clicked
 * @param onDeleteRow callback to call when delete button is clicked
 * @param onUpdateRow callback to call when customer is changed
 * @returns {@TableRow[]}s for the given hydranten
 */
export const createTableRowsForHydranten = (
  hydranten: Hydrant[],
  onClickRow: (hydrant: Hydrant) => void,
  onDeleteRow: (hydrantUid: string) => void,
): TableRow[] => {
  const hydrantEntries: TableRow[] = [];
  hydranten.forEach((hydrant) => {
    const hydrantRow: TableRow = createTableRowForHydrant(
      hydrant,
      onClickRow,
      onDeleteRow,
    );
    hydrantEntries.push(hydrantRow);
  });
  return hydrantEntries;
};

/**
 * Util to create a single {@link TableRow} for a {@link hydrant}
 * @param hydrant the hydrant to create the row for ;
 * @param onClick  callback to call when row is clicked
 * @param onDelete  callback to call when delete button is clicked
 * @param onUpdate  callback to call when customer is changed
 * @returns  {@TableRow} for the hydrant
 */
export const createTableRowForHydrant = (
  hydrant: Hydrant,
  onClick: (hydrant: Hydrant) => void,
  onDelete: (hydrantUid: string) => void,
): TableRow => {
  const hydrantRow: TableRow = {
    value: [
      { value: hydrant.name },
      {
        value: i18n.t(
          `pages.hydrantAdministration.durchmesser.${hydrant.durchmesser}`
        ),
      },
      {
        value: i18n.t(`pages.hydrantAdministration.typ.${hydrant.type}`),
      },
      {
        value: i18n.t(
          `pages.hydrantAdministration.verteilerLeitung.${hydrant.verteilerLeitung}`
        ),
      },

      {
        value: (
          <div
            onClick={(evt) => {
              evt.stopPropagation();
              onDelete(hydrant.uid);
            }}
          >
            X
          </div>
        ),
      },
    ],
    onClick: () => onClick(hydrant),
    onClickMobile: () => onClick(hydrant),
  };
  return hydrantRow;
};

/**
 * Creates the options for the {@link HydrantenDurchmesser} dropdown
 * @returns  {@Option[]} for the dropdown
 */
export const createDropDownOptionsForHydrantDurchmesser = (): Option[] => {
  return Object.values(HydrantenDurchmesser).map(
    (value): Option => ({
      value: value,
      label: i18n.t(`pages.hydrantAdministration.durchmesser.${value}`),
    })
  );
};

/**
 * Creates the options for the {@link HydrantenTyp} dropdown
 * @returns {@Option[]} for the dropdown
 */
export const createDropDownOptionsForHydrantenTyp = (): Option[] => {
  return Object.values(HydrantenTyp).map(
    (value): Option => ({
      value: value,
      label: i18n.t(`pages.hydrantAdministration.typ.${value}`),
    })
  );
};

/**
 * Creates the options for the {@link Verteilerleitung} dropdown
 * @returns {@Option[]} for the dropdown
 */
export const createDropDownOptionsForHydrantVerteilerLeitung = (): Option[] => {
  return Object.values(Verteilerleitung).map(
    (value): Option => ({
      value: value,
      label: i18n.t(`pages.hydrantAdministration.verteilerLeitung.${value}`),
    })
  );
};
