import { LoaderComponent } from "articon-component-library";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAsync } from "react-use";
import { AsyncState } from "react-use/lib/useAsyncFn";
import useSWR from "swr";
import { useUser } from "../utils/auth/UserContext";
import { fetchEinheitenWithIds } from "../utils/einheit/Einheit.axios";
import { getActiveMissions } from "../utils/mission/Mission.axios";
import { loadAllUsers } from "../utils/user/AdminUser.utils";
import { User, UserRank } from "../utils/user/User.types";
import { sortUserByRank } from "../utils/user/User.utils";
import { countVehiclesOnMission } from "../utils/vehicle/Vehicle.axios";
import { ReactComponent as ClosedExtendIcon } from "../assets/closedExtend.svg";
import { ReactComponent as OpenedExtendIcon } from "../assets/openedExtend.svg";
import { MissionWithAlarmedUnits } from "../utils/mission/Mission.types";
import dayjs from "dayjs";
import type { Duration } from "dayjs/plugin/duration";
import { formatDurationToHoursAndMinutes } from "../utils/general/String.utils";

const DashboardBox: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ children, className }) => {
  return (
    <div className={["dashboard-box__wrapper", className].join(" ")}>
      {children}
    </div>
  );
};

export default DashboardBox;

/**
 * A widget to display the total amount of time currently spend on active missions
 */
export const MissionTimeBox: React.FC = () => {
  const { t } = useTranslation();
  const { axios, user } = useUser();
  const [activeMissionsTime, setActiveMissionsTime] = useState(
    formatDurationToHoursAndMinutes(dayjs.duration(0))
  );

  /**
   * Fetch the active missions for the current users customer
   */
  const activeMissions = useSWR(
    ["mission/active", user.customerUid],
    ([, customerUid]) => getActiveMissions(axios, customerUid),
    {
      fallbackData: [],
    }
  );

  /**
   * Effect which updates the total amount of time spend on active missions periodically
   */
  useEffect(() => {
    /**
     * Refresh the total amount of time spend on active missions
     */
    const refreshTime = () => {
      const summaryDuration =
        activeMissions.data?.reduce<Duration>((acc, mission) => {
          const missionDuration: Duration = dayjs.duration(
            dayjs().diff(mission.einsatzStart)
          );

          return acc.add(missionDuration);
        }, dayjs.duration(0)) ?? dayjs.duration(0);

      setActiveMissionsTime(formatDurationToHoursAndMinutes(summaryDuration));
    };

    // start a interval
    const interval = setInterval(() => refreshTime(), 5000);

    // call the refresh instantly
    refreshTime();

    // clear the interval on unmount
    return () => {
      clearInterval(interval);
    };
  }, [activeMissions.data]);

  return (
    <DashboardBox className="mission-timebox-wrapper">
      <div className="dashboard-box__mission-vehicles-widget">
        <p className="dashboard-box__header">
          {t("pages.dashboard.widgets.missionTime.title")}
        </p>
        <div className="dashboard-box__spacer no-border" />
        <p className="dashboard-box__centered">{activeMissionsTime}</p>
      </div>
    </DashboardBox>
  );
};

/**
 * The widget to display the amount of vehicles that are currently on active mission
 */
export const MissionVehiclesBox: React.FC = () => {
  const { t } = useTranslation();
  const { axios, user } = useUser();

  /**
   * The total amount of customer's vehicles that are on an active mission
   */
  const vehiclesOnMission: AsyncState<number> = useAsync(async () => {
    if (!user || !axios) return 0;
    return await countVehiclesOnMission(user.customerUid, axios);
  }, [user, axios]);

  return (
    <DashboardBox className="mission-vehicles-wrapper">
      <div className="dashboard-box__mission-vehicles-widget">
        <p className="dashboard-box__header">
          {t("pages.dashboard.widgets.vehiclesOnMission.title")}
        </p>
        <div className="dashboard-box__spacer no-border" />
        <p className="dashboard-box__centered">
          {vehiclesOnMission.loading ? 0 : vehiclesOnMission.value}
        </p>
      </div>
    </DashboardBox>
  );
};

/**
 * A widget to display the amount of forces that are currently on active mission - Alarmierte Einheiten
 */
export const AlarmedUnitsBox: React.FC = () => {
  const { t } = useTranslation();
  const { axios, user } = useUser();

  const activeMissions = useSWR<MissionWithAlarmedUnits[] | undefined>(
    ["mission/active", user.customerUid],
    ([, customerUid]: [null, string]) =>
      getActiveMissions(axios, customerUid).then((res) =>
        res?.map((mission) => ({ ...mission, alarmedUnitsVisible: false }))
      ),
    {
      fallbackData: [],
    }
  );

  const alarmedUnits = useSWR(
    activeMissions.data && activeMissions.data?.length > 0 && !!axios
      ? "mission/alarmed"
      : null,
    () =>
      fetchEinheitenWithIds(
        axios,
        activeMissions.data?.flatMap(
          (mission) => mission.alarmierteEinheiten
        ) || []
      ),
    {
      fallbackData: [],
    }
  );

  /**
   * Helper to handle the click of a mission to extend the alarmed units
   * @param mission The mission to extend
   */
  const handleMissionExtendClick = (mission: MissionWithAlarmedUnits): void => {
    activeMissions.mutate(
      activeMissions.data?.map((mutateMission) =>
        mutateMission.uid === mission.uid
          ? {
              ...mutateMission,
              alarmedUnitsVisible: !mission.alarmedUnitsVisible,
            }
          : {
              ...mutateMission,
            }
      ),
      {
        revalidate: false,
      }
    );
  };

  return (
    <DashboardBox className="alarmed-units-wrapper">
      <div className="dashboard-box__mission-vehicles-widget">
        <p className="dashboard-box__header">
          {t("pages.dashboard.widgets.alarmedUnits.title")}
        </p>
        <div className="dashboard-box__spacer" />
        <div className="dashboard-box__overflowable-content">
          {activeMissions.data?.map((mission, missionIndex) => (
            <div className="dashboard-box__mission-container">
              <div
                onClick={() => handleMissionExtendClick(mission)}
                className="dashboard-box__mission-container"
              >
                <p className="dashboard-box__mission">{mission.stichwort}</p>
                {mission.alarmedUnitsVisible ? (
                  <OpenedExtendIcon className="alarmed-icon" />
                ) : (
                  <ClosedExtendIcon className="alarmed-icon" />
                )}
              </div>

              <div
                className={[
                  "dashboard-box__alarmed-units-container",
                  mission.alarmedUnitsVisible ? "visible" : "hidden",
                ].join(" ")}
              >
                {mission.alarmierteEinheiten.length > 0 ? (
                  mission.alarmierteEinheiten.map((unit, index) => (
                    <div className="dashboard-box__units-container">
                      <p className="dashboard-box__alarmed-number">{`${
                        index + 1
                      }.`}</p>
                      <p className="dashboard-box__alarmed-name">
                        {
                          alarmedUnits.data?.find(
                            (unitToMatch) => unitToMatch.uid === unit
                          )?.displayName
                        }
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="dashboard-box__alarmed-name">
                    {t("pages.dashboard.widgets.alarmedUnits.noUnits")}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </DashboardBox>
  );
};

/**
 * The widget to display an overview of all available forces based on the company users
 * and their rank
 */
export const ForcesBox: React.FC = () => {
  const { t } = useTranslation();
  const { axios, user } = useUser();

  /**
   * all users that are assigned to the company of the logged in user
   */
  const allCompanyUsers = useAsync(async () => {
    if (!axios || !user) return;
    return await loadAllUsers(axios, user.customerUid);
  }, [axios, user]);

  /**
   * all company users sorted by their rank
   */
  const sortedUser: Map<UserRank, User[]> = useMemo(() => {
    if (
      allCompanyUsers.loading ||
      allCompanyUsers.error ||
      !allCompanyUsers.value
    ) {
      return new Map();
    }
    return sortUserByRank(allCompanyUsers.value!);
  }, [allCompanyUsers]);

  /**
   * the render representation of the sorted users
   */
  const usersToDisplay: React.ReactElement[] = useMemo(() => {
    if (sortedUser.size === 0) return [<LoaderComponent />];
    const targetArray: React.ReactElement[] = [];
    sortedUser.forEach((users, rank) =>
      targetArray.push(
        <div key={rank} className="dashboard-box__horizontal-entry">
          <p>{t(`enums.userRank.${rank}`)}</p>
          <b className="forces-amount">{users.length}</b>
        </div>
      )
    );
    return targetArray;
  }, [sortedUser, t]);

  /**
   * the calculated total sum of all forces
   */
  const totalSumOfForces: number = useMemo(() => {
    if (!sortedUser) return 0;
    return Array.from(sortedUser.values()).reduce(
      (sum, users) => sum + users.length,
      0
    );
  }, [sortedUser]);

  return (
    <DashboardBox className="forces-box-wrapper">
      <div className="dashboard-box__forces-widget">
        <p className="dashboard-box__header">
          {t("pages.dashboard.widgets.forcesBox.title")}
        </p>
        <div className="dashboard-box__spacer" />
        {allCompanyUsers.loading ? <LoaderComponent /> : usersToDisplay}
        <div className="dashboard-box__spacer" />
        <div className="dashboard-box__horizontal-entry">
          <p>{t("pages.dashboard.widgets.forcesBox.totalForces")}</p>
          <b className="forces-amount-total">{totalSumOfForces}</b>
        </div>
      </div>
    </DashboardBox>
  );
};
