import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { ReactComponent as WindMarker } from "../../../../assets/wind_marker.svg";
import { Mission } from "../../../../utils/mission/Mission.types";
import {
  calculateKmphFromMps,
  getIconUrlForWeather,
  loadWeatherData,
} from "../../../../utils/weather/Weather.utils";

const WeatherWidget: React.FC<{ selectedMission: Mission }> = ({
  selectedMission,
}) => {
  const { t } = useTranslation();

  /**
   * Holds the current weather information.
   * 🚨 during develop this value is not fetched on mount to save requests. Set
   * the value revalidateOnMount once to true to fetch the weather once 🚨
   */
  const weather = useSWR(selectedMission?.latLon, loadWeatherData, {
    refreshInterval: 300_000, // set the refresh interval to 5 minutes,
    revalidateOnFocus: false,
    revalidateOnMount: process.env.REACT_APP_FETCH_WEATHER_ON_MOUNT
      ? JSON.parse(process.env.REACT_APP_FETCH_WEATHER_ON_MOUNT)
      : true,
  });

  /**
   * holds the current winddirection in which the wind is blowing
   */
  const windDirection: number = useMemo(() => {
    const svgRotation: number = 45; // as long as the SVG is rotated by itself we need this helper
    return svgRotation + (weather.data?.wind.deg || 0);
  }, [weather]);

  return (
    <div className="map-left-menu__weather__wrapper">
      <div className="map-left-menu__weather__entry">
        <p className="map-left-menu__weather__title">
          {t("components.mapOverlay.weatherWidget.weather")}
        </p>
        <img
          alt="Wetter Icon"
          src={getIconUrlForWeather(weather.data)}
          className="map-left-menu__weather__icon"
        />
      </div>
      <div className="map-left-menu__weather__entry temp">
        <p className="map-left-menu__weather__title">
          {t("components.mapOverlay.weatherWidget.temp")}
        </p>
        <div className="map-left-menu__weather__content temp">
          {weather.data?.main.temp?.toFixed(0)}°
        </div>
      </div>
      <div className="map-left-menu__weather__entry">
        <p className="map-left-menu__weather__title">
          {t("components.mapOverlay.weatherWidget.wind")}
        </p>
        <div className="map-left-menu__weather__content wind">
          <WindMarker
            className="map-left-menu__weather__wind-marker"
            style={{ transform: `rotate(${windDirection}deg)` }}
          />
          <div className="map-left-menu__weather__content wind__data">
            <p>{weather.data?.wind.deg}°</p>
            <p>
              {calculateKmphFromMps(weather.data?.wind.speed)}
              {t("components.mapOverlay.weatherWidget.kmph")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeatherWidget;
