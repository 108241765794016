import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { userServiceBaseUrl } from "./User.axios";
import { User } from "./User.types";

/**
 * creates auth user for given User with default password and saves user object in firestore when possible
 *
 * @param axios network instance
 * @param newUser new user to save without uid
 * @returns uid when creation is successful, empty string when not
 */
export const createNewUser = (
  axios: AxiosInstance,
  newUser: User
): Promise<string> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve("");
  return axios
    .post(userServiceBaseUrl, { ...newUser, password: "articon1" })
    .then((res) => res.data)
    .catch((err) => {
      console.error("New User couldn't be created", err.message);
      return "";
    });
};

/**
 * API method to delete a user identified by their uid
 *
 * @param axios network instance
 * @param uid The uid of the user to delete
 * @returns true if deletion was successful, false otherwise
 */
export const deleteUser = async (
  axios: AxiosInstance,
  uid: string
): Promise<boolean> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve(false);
  return axios
    .get(`${userServiceBaseUrl}/delete/`, { params: { userUid: uid } })
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("User couldn't be deleted", err.message);
      return false;
    });
};

/**
 * API method to load many users identified by their uid
 *
 * @param axios network instance
 * @param uids The uids of the users to load
 * @returns The list of found users
 */
export const loadManyUsersByUids = async (
  axios: AxiosInstance,
  uids: string[]
): Promise<User[]> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${userServiceBaseUrl}/many/`, { params: { userUids: uids } })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Many users couldn't be loaded", err.message);
      return [];
    });
};

/**
 * API method to load all users for given customerUid
 *
 * @param axios network instance
 * @param customerUid to look for in users to load
 * @returns The list of all users with given customerUid
 */
export const loadAllUsers = async (
  axios: AxiosInstance,
  customerUid: string
): Promise<User[]> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${userServiceBaseUrl}/all/customer/`, {
      params: { customerUid: customerUid },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error("All Users for customer couldn't be loaded", err.message);
      return [];
    });
};
