import React, { useCallback, useState } from "react";
import useSWR from "swr";
import { generateNewMission } from "../utils/mission/Mission.utils";
import { Mission, missionStichwortRegex } from "../utils/mission/Mission.types";
import {
  ButtonComponent,
  DropdownComponent,
  InputComponent,
} from "articon-component-library";
import { useTranslation } from "react-i18next";
import { useUser } from "../utils/auth/UserContext";
import { convertAddressToConcatenatedString } from "../utils/geocoding/Geocoding.utils";
import { getAllVehiclesByCustomer } from "../utils/vehicle/Vehicle.axios";
import { fetchEinheitenWithIds } from "../utils/einheit/Einheit.axios";
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import {
  createNewMission,
  getActiveMissions,
} from "../utils/mission/Mission.axios";
import { getGeocoding } from "../utils/geocoding/Geocoding.axios";
import { useMission } from "../utils/mission/Mission.context";
import { useNavigate } from "react-router-dom";

interface GenerateMissionProps {
  onClose?: () => void;
}

const GenerateMission: React.FC<GenerateMissionProps> = ({ onClose }) => {
  const [newMission, setNewMission] = useState<Mission>(generateNewMission());
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { axios, user } = useUser();
  const { setSelectedMission } = useMission();
  const navigate = useNavigate();

  const vehicles = useSWR(
    ["mission/vehicle/customer", user.customerUid],
    ([, customerUid]) => getAllVehiclesByCustomer(axios, customerUid),
    {
      fallbackData: [],
    }
  );

  const einheiten = useSWR(
    vehicles.data ? "mission/units" : null,
    () =>
      fetchEinheitenWithIds(
        axios,
        vehicles.data.flatMap((vehicle) => vehicle.unit)
      ),
    {
      fallbackData: [],
    }
  );

  const activeMissions = useSWR(
    ["mission/active", user.customerUid],
    ([, customerUid]) => getActiveMissions(axios, customerUid),
    {
      fallbackData: [],
    }
  );

  /**
   * Callback which handles the geocoding button
   * This automatically sets the lat and lon values when geocoding is successful
   */
  const handleGeocode = useCallback(async () => {
    if (!newMission) return;
    toggleLoading(true);
    const geocoding = await getGeocoding(
      axios,
      convertAddressToConcatenatedString(newMission.address)
    );
    if (!geocoding) {
      toggleLoading(false);
      return;
    }
    setNewMission((current) => ({
      ...current,
      latLon: [geocoding.lat, geocoding.lon],
    }));
    toggleLoading(false);
  }, [axios, newMission]);

  /**
   * Checks the mission stichwort for invalid characters and replaces them with empty strings
   * @param stichwort the stichwort to check
   */
  const handleNewMissionStichwort = (stichwort: string): void => {
    let newStichwort: string = "";
    stichwort.split("").forEach((char, index) => {
      missionStichwortRegex[index].test(char)
        ? (newStichwort += char)
        : (newStichwort += "");
    });
    setNewMission((current) => ({ ...current, stichwort: newStichwort }));
  };

  /**
   * Helper to submit mission after creation/change, will update when mission has uid and create
   * a new one otherwise
   */
  const handleSubmit = async (): Promise<void> => {
    const missionUid: string = await createNewMission(axios, {
      ...newMission,
      customerUid: user.customerUid,
    });
    const newMissions: Mission[] | null | undefined =
      await activeMissions.mutate();
    if (!newMissions) return;
    const newlyCreatedMission: Mission | undefined = newMissions.find(
      (mission) => mission.uid === missionUid
    );
    setSelectedMission(newlyCreatedMission);
    if (onClose) onClose();
    else navigate("/");
  };

  return (
    <form
      className="generate-mission"
      onSubmit={(evt) => {
        evt.preventDefault();
        handleSubmit();
      }}
    >
      <div className="generate-mission-header-container">
        <h3>{t("components.createMission.header")}</h3>
        {onClose && <CloseIcon onClick={onClose} />}
      </div>
      <InputComponent
        required
        label={t("components.createMission.name")}
        value={newMission.name}
        onChange={(name) =>
          setNewMission((oldMission) => ({ ...oldMission, name }))
        }
      />
      <InputComponent
        required
        label={t("components.createMission.einsatzNummer")}
        value={newMission.einsatzNummer}
        onChange={(einsatzNummer) =>
          setNewMission((oldMission) => ({ ...oldMission, einsatzNummer }))
        }
      />
      <div>
        <InputComponent
          required
          label={t("components.createMission.street")}
          value={newMission.address.street}
          onChange={(street) =>
            setNewMission((current) => ({
              ...current,
              address: { ...current.address, street },
            }))
          }
        />
        <InputComponent
          required
          label={t("components.createMission.number")}
          value={newMission.address.number}
          onChange={(number) =>
            setNewMission((current) => ({
              ...current,
              address: { ...current.address, number },
            }))
          }
        />
        <InputComponent
          label={t("components.createMission.additionalInfo")}
          value={newMission.address.additionalInfo}
          onChange={(additionalInfo) =>
            setNewMission((current) => ({
              ...current,
              address: { ...current.address, additionalInfo },
            }))
          }
        />
        <InputComponent
          required
          label={t("components.createMission.city")}
          value={newMission.address.city}
          onChange={(city) =>
            setNewMission((current) => ({
              ...current,
              address: { ...current.address, city },
            }))
          }
        />
        <InputComponent
          required
          label={t("components.createMission.zip")}
          value={newMission.address.zip}
          onChange={(zip) =>
            setNewMission((current) => ({
              ...current,
              address: { ...current.address, zip },
            }))
          }
        />
        <ButtonComponent
          value={t("components.createMission.geocode")}
          onClick={handleGeocode}
          isLoading={isLoading}
        />
        <InputComponent
          type="number"
          label={t("components.createMission.lat")}
          value={newMission.latLon[0]}
          onChangeNumber={(lat) =>
            setNewMission((current) => ({
              ...current,
              latLon: [lat, current.latLon[1]],
            }))
          }
        />
        <InputComponent
          type="number"
          label={t("components.createMission.lon")}
          value={newMission.latLon[1]}
          onChangeNumber={(lon) =>
            setNewMission((current) => ({
              ...current,
              latLon: [current.latLon[0], lon],
            }))
          }
        />
      </div>
      <div className="create-mission__stichwort-container">
        <InputComponent
          required
          label={t("components.createMission.stichwort")}
          value={newMission.stichwort}
          onChange={(value) => handleNewMissionStichwort(value)}
          placeholder="T1.0"
        />
      </div>
      <DropdownComponent
        selectedOptions={newMission.fahrzeuge}
        label={t("components.createMission.vehicles")}
        options={vehicles.data.map((vehicle) => ({
          label: vehicle.names.join(""),
          value: vehicle.uid,
        }))}
        allowMultiSelect
        onChangeMultiple={(fahrzeuge) =>
          setNewMission((oldMission) => ({ ...oldMission, fahrzeuge }))
        }
      />
      <DropdownComponent
        selectedOptions={vehicles.data
          .filter((vehicle) => newMission.fahrzeuge.includes(vehicle.uid))
          .flatMap((vehicle) => vehicle.unit)
          // filter duplicates
          .filter((unit, index, self) => self.indexOf(unit) === index)}
        label={t("components.createMission.alarmedUnits")}
        options={
          einheiten.data?.map((einheit) => ({
            label: einheit.displayName,
            value: einheit.uid,
          })) || []
        }
        allowMultiSelect
        disabled
      />
      <ButtonComponent
        type="submit"
        value={t("components.createMission.createMission")}
      />
    </form>
  );
};

export default GenerateMission;
