import { ButtonComponent, InputComponent } from "articon-component-library";
import { LassoHandler, LassoHandlerFinishedEvent } from "leaflet-lasso";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../utils/auth/UserContext";
import { MapSection } from "../../../utils/map/Map.types";
import {
  generateNewMapSection,
  generateRandomColor,
  updateMapSectionInArray,
} from "../../../utils/map/Map.utils";
import { updateMission } from "../../../utils/mission/Mission.axios";
import { useHistory } from "../../../utils/history/History.context";
import { useEvent } from "../../../utils/event/Event.context";
import { Mission } from "../../../utils/mission/Mission.types";

interface MapSectionToolProps {
  lassoHandler?: LassoHandler;
}

/**
 * Toolbox to use in MapOverlay to create, name and delete sections on Map
 *
 * @param lassoHandler handler of lasso event, needed to create a section
 * @returns toolbox for map to create and delete MapSections
 */
const MapSectionTool: React.FC<MapSectionToolProps> = ({ lassoHandler }) => {
  const { axios } = useUser();
  const { t } = useTranslation();
  const [newSection, setNewSection] = useState<MapSection>(
    generateNewMapSection(generateRandomColor())
  );
  const { mission, isShifted, appendHistoryEntries } = useHistory();

  /**
   * Listen on mapSelectionFinished event to create a section
   */
  useEvent("mapSelectionFinished", (event) => {
    handleSectionCreate(event);
  });

  /**
   * Handler for section creation
   *
   * @param event the lasso handler finish event
   */
  const handleSectionCreate = useCallback(
    async (event: LassoHandlerFinishedEvent) => {
      if (!mission || isShifted) return;

      const sectionToAdd: MapSection = {
        ...newSection,
        coords: event.latLngs.map((latLng) => [latLng.lat, latLng.lng]),
      };

      const newSections = updateMapSectionInArray(
        mission.sections,
        sectionToAdd
      );

      // reset section form
      setNewSection(generateNewMapSection(generateRandomColor()));

      const newMission: Mission = {
        ...mission,
        sections: newSections,
      };

      const historyEntry = await updateMission(axios, newMission);

      appendHistoryEntries(historyEntry);
    },
    [appendHistoryEntries, axios, isShifted, newSection, mission]
  );

  /**
   * Handler for section deletion
   *
   * @param section section to delete
   */
  const handleSectionDelete = useCallback(
    async (section: MapSection) => {
      if (!mission || isShifted) return;
      const newSections = updateMapSectionInArray(
        mission.sections,
        section,
        true
      );

      const historyEntry = await updateMission(axios, {
        ...mission,
        sections: newSections,
      });

      appendHistoryEntries(historyEntry);
    },
    [appendHistoryEntries, axios, isShifted, mission]
  );

  /**
   * Memorized sections which can be shifted
   */
  const sections = useMemo<MapSection[]>(
    () => mission?.sections ?? [],
    [mission]
  );

  // hide if no mission is selected
  if (!mission) return null;

  return (
    <div className="section-tool">
      <ul>
        {sections.map((section) => (
          <li key={`section-${section.uid}`}>
            <div>{section.name}</div>
            <ButtonComponent
              disabled={isShifted}
              value={t("buttons.delete")}
              onClick={() => handleSectionDelete(section)}
            />
          </li>
        ))}
      </ul>
      {lassoHandler && (
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            const alreadyExists: boolean = sections.some(
              (section) => section.name === newSection.name
            );
            if (alreadyExists) console.log("Section already exists.");
            else lassoHandler.toggle();
          }}
        >
          <InputComponent
            disabled
            value={
              mission?.stichwort || t("components.mapSectionTool.noMission")
            }
            label={t("components.mapSectionTool.selectedMission")}
          />
          <InputComponent
            required
            label={t("components.mapSectionTool.sectionName")}
            value={newSection.name}
            onChange={(name) =>
              setNewSection((section) => ({ ...section, name }))
            }
          />
          <InputComponent
            required
            label={t("components.mapSectionTool.sectionColor")}
            value={newSection.color}
            type="color"
            onChange={(color) =>
              setNewSection((section) => ({ ...section, color }))
            }
          />
          <div className="button-wrapper">
            <ButtonComponent
              value={t("components.mapSectionTool.randomColor")}
              onClick={() =>
                setNewSection((section) => ({
                  ...section,
                  color: generateRandomColor(),
                }))
              }
            />
            <ButtonComponent
              disabled={isShifted}
              value={t("components.mapSectionTool.createSection")}
              type="submit"
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default MapSectionTool;
