import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { missionServiceBaseUrl } from "../user/User.axios";
import { Gateway, GatewaySnapshot } from "./Gateway.types";

/**
 * API method to update a single {@link Gateway}
 * @param axios axios instance
 * @param gateway gateway to update
 * @returns true if update was successful, false otherwise
 */
export const updateGateway = async (
  axios: AxiosInstance,
  gateway: Gateway
): Promise<boolean> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve(false);
  return axios
    .post(`${missionServiceBaseUrl}/gateway/update/`, gateway)
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Gateways update couldn't be performed", err);
      return false;
    });
};

/**
 * API method to update many {@link Gateway}s
 * @param axios axios instance
 * @param gateways gateways to update
 * @returns true if update was successful, false otherwise
 */
export const updateManyGateway = async (
  axios: AxiosInstance,
  gateways: Gateway[]
): Promise<boolean> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve(false);
  return axios
    .post(`${missionServiceBaseUrl}/gateway/update/many/`, gateways)
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Gateways update couldn't be performed", err);
      return false;
    });
};

/**
 * API method to load all {@link Gateway}s
 * @param axios axios instance
 * @returns The list of all gateways, can be empty
 */
export const getAllGateways = async (
  axios: AxiosInstance
): Promise<Gateway[]> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${missionServiceBaseUrl}/gateway/all/`)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Gateways couldn't be loaded", err);
      return [];
    });
};

/**
 *  API method to load all {@link Gateway}s by customer
 * @param axios axios instance
 * @param customerUid customer uid
 * @returns The list of all gateways, can be empty
 */
export const getAllGatewaysByCustomer = async (
  axios: AxiosInstance,
  customerUid: string
): Promise<Gateway[]> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${missionServiceBaseUrl}/gateway/customer/all/`, {
      params: { customerUid },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Gateways couldn't be loaded", err);
      return [];
    });
};

/**
 * API method to load a single {@link Gateway} by uid
 * @param axios axios instance
 * @param gatewayUid gateway uid
 * @returns {Gateway} The gateway, can be null
 */
export const getGatewayByUid = async (
  axios: AxiosInstance,
  gatewayUid: string
): Promise<Gateway | undefined> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return;
  return axios
    .get(`${missionServiceBaseUrl}/gateway/uid/`, {
      params: { gatewayUid },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Gateway couldn't be loaded", err);
    });
};

/**
 * API method to sync all {@link Gateway}s manually
 * @param axios axios instance
 * @returns true if sync was successful, false otherwise
 */
export const manuallySyncGateways = async (
  axios: AxiosInstance
): Promise<Gateway[] | null> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve(null);
  return axios
    .post(`${missionServiceBaseUrl}/gateway/sync/`)
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error();
    })
    .catch((err) => {
      console.error("Gateways couldn't be synced", err);
      return null;
    });
};

/**
 * Get the gateway snapshots for a specific time for a customer
 *
 * @param axios The axios instance
 * @param customerUid The customer uid which holds the gateways
 * @param fromDate The date from which the snapshots should be loaded (always until now)
 * @returns promised list of gateway snapshots
 */
export const getGatewaySnapshots = async (
  axios: AxiosInstance,
  customerUid: string,
  fromDate: Date
): Promise<GatewaySnapshot[]> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.reject();
  return axios
    .get(`${missionServiceBaseUrl}/gateway/history/`, {
      params: {
        customerUid,
        fromDate: fromDate.toISOString(),
      },
    })
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Gateway snapshots could not be loaded", err);
      return [];
    });
};
