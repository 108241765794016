import { Hint } from "./Hint.types";

/**
 * Create an empty hint object
 *
 * @param overwrite Object to overwrite the default values
 * @returns Hint object
 */
export const createEmptyHint = (overwrite?: Partial<Hint>): Hint => ({
  uid: "",
  content: "",
  coords: [0, 0],
  missionUid: "",
  createDate: new Date(),
  lastUpdate: new Date(),
  ...overwrite,
});
