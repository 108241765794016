import {
  DependencyList,
  ReactNode,
  createContext,
  useContext,
  useEffect,
} from "react";
import { Events } from "./Event.types";
import { EventManager } from "./EventManager";

export const eventManager: EventManager<Events> = new EventManager();

/**
 * Context type for the event system
 */
interface EventContextData {
  on: typeof eventManager.on;
  off: typeof eventManager.off;
  emit: typeof eventManager.emit;
}

/**
 * React context for the event system
 */
const EventContext = createContext<EventContextData>({
  emit: () => {},
  off: () => {},
  on: () => {},
});

/**
 * Declare a listener
 */
export type Listener<Data = unknown> = (data: Data) => void;

/**
 * Context provider for the event system
 */
export const EventProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <EventContext.Provider
      value={{
        on: eventManager.on.bind(eventManager),
        off: eventManager.off.bind(eventManager),
        emit: eventManager.emit.bind(eventManager),
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

/**
 * Hook to register an event listener
 *
 * @param event the event to listen to
 * @param callback the callback to call when the event is emitted
 */
export const useEvent = <Event extends keyof Events, Data = Events[Event]>(
  event: Event,
  callback: Listener<Data>,
  deps?: DependencyList
) => {
  const { on, off } = useContext(EventContext);

  /**
   * Register the event
   */
  useEffect(() => {
    const listener = (data: Data) => callback(data);
    on(event, listener);
    return () => {
      off(event, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, callback, on, off, ...(deps || [])]);
};

/**
 * Hook to get the emit function from the event context
 */
export const useEmitter = () => {
  const { emit } = useContext(EventContext);
  return emit;
};
