import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { Mission } from "./Mission.types";
import { updateMissionDates } from "./Mission.utils";
import { HistoryEntry, HistoryType } from "../history/History.types";

export const missionServiceBaseUrl: string =
  process.env.REACT_APP_MISSION_SERVICE_URL || "";

/**
 * fetches mission for given uid
 * @param axios network instance
 * @param missionUid uid of mission that should be fetched
 * @returns mission when successful or null on error
 */
export const getMission = (
  axios: AxiosInstance,
  missionUid: string
): Promise<Mission | null> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve(null);
  return axios
    .get(missionServiceBaseUrl, {
      params: { missionUid: missionUid },
    })
    .then(
      (res) =>
        ({
          ...res.data,
          fahrzeugeWithUsers: new Map<string, string[]>(
            Object.entries(res.data.fahrzeugeWithUsers ?? [])
          ),
        } as Mission)
    )
    .catch((err) => {
      console.error("Error while loading missions", err.message);
      return null;
    });
};

/**
 * Fetches all active missions for given customer
 * @param axios  network instance
 * @param customerUid  uid of customer
 * @returns  list of active missions, can be empty
 */
export const getActiveMissions = (
  axios: AxiosInstance,
  customerUid: string
): Promise<Mission[] | null> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve(null);
  return axios
    .get(`${missionServiceBaseUrl}/active/`, {
      params: { customerUid: customerUid },
    })
    .then((res) =>
      res.data.map((mission: Mission) =>
        mission.fahrzeugeWithUsers
          ? {
              ...mission,
              fahrzeugeWithUsers: new Map<string, string[]>(
                Object.entries(mission.fahrzeugeWithUsers)
              ),
            }
          : mission
      )
    )
    .catch((err) => {
      console.error("Error while loading active missions", err.message);
      return null;
    });
};

/**
 * API method to load all missions
 *
 * @param axios network instance
 * @returns The list of all missions, can be empty
 */
export const loadAllMissions = (axios: AxiosInstance): Promise<Mission[]> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${missionServiceBaseUrl}/all/`)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Missions couldn't be loaded", err.message);
      return [];
    });
};

/**
 * creates given Mission in firebase
 *
 * @param axios network instance
 * @param newMission new mission object without uid
 * @returns missionUid when successful, else empty string
 */
export const createNewMission = (
  axios: AxiosInstance,
  newMission: Mission
): Promise<string> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve("");
  updateMissionDates(newMission, true);
  return axios
    .post(missionServiceBaseUrl, newMission)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error while creating mission", err.message);
      return "";
    });
};

/**
 * updates given Mission in firebase
 *
 * @param axios network instance
 * @param mission updated mission object
 * @returns true when update was successful, false otherwise
 */
export const updateMission = (
  axios: AxiosInstance,
  mission: Mission,
  historyType?: HistoryType
): Promise<HistoryEntry> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.reject();
  updateMissionDates(mission);
  return axios
    .post(`${missionServiceBaseUrl}/update/`, mission, {
      params: { historyType },
    })
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Error while updating missions", err.message);
      return Promise.reject(err);
    });
};

/**
 * End the mission with the given uid
 *
 * @param axios The axios instance
 * @param missionUid The uid of the mission to end
 * @returns Promise void
 */
export const endMission = (
  axios: AxiosInstance,
  missionUid: string
): Promise<HistoryEntry> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.reject();
  return axios
    .post(`${missionServiceBaseUrl}/end/`, undefined, {
      params: { missionUid },
    })
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Error while deleting mission", err.message);
      return Promise.reject(err);
    });
};

/**
 * API method to load the total runtime in minutes for active
 * missions of the given customer
 *
 * @param customerUid The uid of the customer
 * @param axios axios instsance
 * @returns Promise of total active mission minutes
 */
export const loadActiveMissionRuntime = async (
  customerUid: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .get(`${missionServiceBaseUrl}/time/active/`, { params: { customerUid } })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during active mission time loading", exc);
      return 0;
    });
};

/**
 * API method to load all mission that belong to a given customer
 * @param customerUid - the id of the customer
 * @param axios - axios instance
 * @returns list of missions
 */
export const loadMissionsForCustomer = async (
  axios: AxiosInstance,
  customerUid: string
): Promise<Mission[]> => {
  return axios
    .get(`${missionServiceBaseUrl}/customer/`, { params: { customerUid } })
    .then((resp) =>
      resp.data.map((mission: Mission) =>
        mission.fahrzeugeWithUsers
          ? {
              ...mission,
              fahrzeugeWithUsers: new Map<string, string[]>(
                Object.entries(mission.fahrzeugeWithUsers)
              ),
            }
          : mission
      )
    )
    .catch((exc) => {
      console.error("Error during active mission time loading", exc);
      return [];
    });
};

/**
 * API method to trigger a manual update of an active mission
 * @param axios  - axios instance
 * @param missionUid  - the uid of the mission to update
 * @returns  true if the update was successful, false otherwise
 */
export const updateMissionTactical = async (
  axios: AxiosInstance,
  missionUid: string
): Promise<boolean> => {
  return axios
    .get(`${missionServiceBaseUrl}/update/tactical/`, {
      params: { missionUid },
    })
    .then((resp) => resp.status === 200)
    .catch((exc) => {
      console.error("Error during active mission time loading", exc);
      return false;
    });
};

/**
 * Get the history of a mission with the given uid
 *
 * @param axios The axios instance
 * @param missionUid The uid of the mission to get the history for
 * @returns Promise of the history entries
 */
export const getMissionHistory = (
  axios: AxiosInstance,
  missionUid: string
): Promise<HistoryEntry[]> =>
  axios
    .get(`${missionServiceBaseUrl}/history/`, { params: { missionUid } })
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Error while loading mission history", err.message);
      return Promise.reject(err);
    });
