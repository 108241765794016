/* eslint-disable @typescript-eslint/no-redeclare */
import { z } from "zod";

/**
 * COPYIED FROM REALTIME-SERVICE BORNEMANN TYPES
 * KEEP IN SYNC
 */

/**
 * The type of the live data.
 */
export enum BornemannLiveDataType {
  BEACON = "BEACON",
  GATEWAY = "GATEWAY",
}

/**
 * Bornemann Live Data Base Schema
 * All asset live datas should match these schema.
 */
const BornemannBaseLiveData = z.object({
  asset: z.object({
    _id: z.string(),
  }),
  logLast: z.object({
    date: z.coerce.date(),
    lonlat: z.tuple([z.number(), z.number()]).optional(),
    alt: z.number().optional(),
    speed: z.number().optional(),
    model: z.string().optional(),
    battery: z.number().optional(),
    temperature: z.number().optional(),
    rssi: z.number().optional(),
    idOriginAsset: z.string().optional(),
    idOriginHardware: z.string().optional(),
  }),
});

/**
 * The schema of a beacon extended from the base schema.
 * Beacon live data has no childType.
 * The childType is used to differentiate between a gateway and a beacon.
 *
 * After validation a propertey named "type" is added with the value of "BEACON".
 */
export const BornemannBeaconLiveData = BornemannBaseLiveData.extend({
  logLast: BornemannBaseLiveData.shape.logLast.extend({
    idOriginAsset: z.string(),
  }),
}).transform(
  (data) => ({ ...data, type: BornemannLiveDataType.BEACON } as const)
);

/**
 * The schema of a gateway extended from the base schema.
 * Gateway live data has a childType of "vehicle".
 * The childType is used to differentiate between a gateway and a beacon.
 *
 * After validation a propertey named "type" is added with the value of "GATEWAY".
 */
export const BornemannGatewayLiveData = BornemannBaseLiveData.extend({
  logLast: BornemannBaseLiveData.shape.logLast.extend({
    idOriginAsset: z.void(),
  }),
}).transform(
  (data) => ({ ...data, type: BornemannLiveDataType.GATEWAY } as const)
);

/**
 * The combined schema of a beacon and a gateway.
 */
export const BornemannLiveData = z.union([
  BornemannBeaconLiveData,
  BornemannGatewayLiveData,
]);

export type BornemannBeaconLiveData = z.infer<typeof BornemannBeaconLiveData>;
export type BornemannGatewayLiveData = z.infer<typeof BornemannGatewayLiveData>;
export type BornemannLiveData = z.infer<typeof BornemannLiveData>;
