import {
  ButtonComponent,
  DropdownComponent,
  InputComponent,
  Option,
} from "articon-component-library";
import {
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  StandardLoad,
  StrippedVehicleBlueprint,
  Vehicle,
} from "../../utils/vehicle/Vehicle.types";
import useSWR from "swr";
import {
  getAllGatewaysByCustomer,
  updateManyGateway,
} from "../../utils/gateway/Gateway.axios";
import { useUser } from "../../utils/auth/UserContext";
import { Gateway } from "../../utils/gateway/Gateway.types";
import { useTranslation } from "react-i18next";
import {
  createNewVehicle,
  deleteVehicle,
  getAllVehicleBlueprint,
  updateVehicle,
} from "../../utils/vehicle/Vehicle.axios";
import { getAllMaterials } from "../../utils/material/Material.axios";
import { createEmptyMaterial } from "../../utils/material/Material.utils";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { getVehicleIconUrl } from "../../utils/vehicle/Vehicle.firebase";
import { getEinheitenByCustomer } from "../../utils/einheit/Einheit.axios";

const VehicleEdit: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation<{ vehicle: Vehicle }>();
  const { user, axios } = useUser();
  const [localVehicle, setLocalVehicle] = useState<Vehicle | undefined>(
    location.state?.vehicle
  );
  const gateways = useSWR(
    ["mission/vehicle/customer/all", user.customerUid],
    ([, customerUid]) => getAllGatewaysByCustomer(axios, customerUid),
    { fallbackData: [] }
  );

  const vehicleBlueprints = useSWR(
    !!axios ? "/mission/vehicle/blueprint" : null,
    () => (!!axios ? getAllVehicleBlueprint(axios) : []),
    { fallbackData: [] }
  );

  const materials = useSWR(!!axios ? "mission/material/all" : null, () =>
    axios ? getAllMaterials(axios) : []
  );

  const [iconUrl, setIconUrl] = useState<string>("");

  const einheiten = useSWR(
    ["mission/einheit/customer", user.customerUid],
    ([, customerUid]) => getEinheitenByCustomer(axios, customerUid),
    {
      fallbackData: [],
    }
  );
  const [isLoading, toggleLoading] = useState<boolean>(false);

  /**
   * Update local vehicle state when location state changes
   */
  useEffect(() => {
    if (!location.state?.vehicle) navigate(-1);
    setLocalVehicle(location.state?.vehicle);
  }, [location.state?.vehicle, navigate]);

  /**
   * Get the icon url of the vehicle
   */
  useEffect(() => {
    if (!localVehicle?.blueprint) return;
    getVehicleIconUrl(localVehicle.blueprint.uid).then(setIconUrl);
  }, [localVehicle?.blueprint]);

  /**
   * Memoize gateway options for dropdown
   */
  const gatewayOptions = useMemo<Option[]>(
    () =>
      gateways.data
        .filter(
          (gateway) =>
            !gateway.vehicleUid || gateway.vehicleUid === localVehicle?.uid
        )
        .map((gateway) => ({
          value: gateway.uid,
          label: `${gateway.name} (${gateway.uid})`,
        })),
    [gateways, localVehicle]
  );

  /**
   * Callback to update the gateways to the current configuration
   */
  const updateGateways = useCallback(async () => {
    if (!localVehicle) return;

    const allGateways: Gateway[] = gateways.data;
    const gatewaysToUpdate: Gateway[] = [];
    const gatewaysToRemoveVehicle: Gateway[] = allGateways.filter(
      (gateway) =>
        gateway.vehicleUid === localVehicle.uid &&
        gateway.uid !== localVehicle.gatewayUid
    );

    if (localVehicle.gatewayUid) {
      const gatewayToAddVehicle: Gateway | undefined = allGateways.find(
        (gateway) => gateway.uid === localVehicle.gatewayUid
      );
      if (gatewayToAddVehicle) {
        gatewayToAddVehicle.vehicleUid = localVehicle.uid;
        gatewaysToUpdate.push(gatewayToAddVehicle);
      }
    }

    gatewaysToRemoveVehicle.forEach((gateway) => {
      gateway.vehicleUid = "";
      gatewaysToUpdate.push(gateway);
    });

    const success: boolean = await updateManyGateway(axios, gatewaysToUpdate);
    if (!success) return;

    await gateways.mutate(
      gateways.data.map((gateway) => {
        const updatedGateway = gatewaysToUpdate.find(
          (gatewayUpdated) => gatewayUpdated.uid === gateway.uid
        );
        return updatedGateway ? updatedGateway : gateway;
      })
    );
  }, [axios, gateways, localVehicle]);

  /**
   * Callback which handles to form submit
   */
  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();
      if (!localVehicle) return;
      toggleLoading(true);
      await Promise.all([
        updateGateways(),
        !localVehicle.uid
          ? await createNewVehicle(axios, localVehicle)
          : await updateVehicle(axios, localVehicle),
      ]);
      navigate(-1);
      toggleLoading(false);
    },
    [updateGateways, axios, localVehicle, navigate]
  );

  /**
   * Callback which handles the delete button
   */
  const handleDelete = useCallback(async () => {
    if (!localVehicle) return;
    toggleLoading(true);
    await deleteVehicle(axios, localVehicle.uid);
    navigate(-1);
    toggleLoading(false);
  }, [axios, localVehicle, navigate]);

  if (!localVehicle) return null;

  /**
   * Renders the standard load for the selected vehicle blueprint
   * @returns  {JSX.Element[]}
   */
  const renderStandardLoad = (): JSX.Element[] => {
    if (!localVehicle.blueprint || !localVehicle) return [];
    const standardLoadElements: JSX.Element[] =
      localVehicle.blueprint.standardLoad.map((load, index) => {
        return (
          <div className="vehicle-edit__load-container">
            <DropdownComponent
              label={t("pages.vehicleEdit.vehicleBlueprint.material")}
              required
              options={
                materials.data?.map((material) => ({
                  label: material.name,
                  value: material.uid,
                  disabled:
                    load.material.uid === material.uid ||
                    localVehicle.blueprint?.standardLoad.some(
                      (load) => load.material.uid === material.uid
                    ),
                })) ?? []
              }
              selectedOption={load.material.uid}
              onChange={(materialUid) => {
                const copiedStandardLoad: StandardLoad[] = [
                  ...(localVehicle.blueprint?.standardLoad || []),
                ];
                copiedStandardLoad[index].material =
                  materials.data?.find(
                    (material) => material.uid === materialUid
                  ) || createEmptyMaterial();
                const updatedBlueprint: StrippedVehicleBlueprint | undefined =
                  localVehicle.blueprint
                    ? {
                        ...localVehicle.blueprint,
                        standardLoad: copiedStandardLoad,
                      }
                    : undefined;
                setLocalVehicle((vehicle) =>
                  vehicle
                    ? { ...vehicle, blueprint: updatedBlueprint }
                    : undefined
                );
              }}
            />
            <InputComponent
              label={t("pages.vehicleEdit.vehicleBlueprint.materialAmount")}
              value={load.amount}
              type="number"
              min={0}
              onChangeNumber={(amount) => {
                const copiedStandardLoad: StandardLoad[] = [
                  ...(localVehicle.blueprint?.standardLoad || []),
                ];
                copiedStandardLoad[index].amount = amount;
                const updatedBlueprint: StrippedVehicleBlueprint | undefined =
                  localVehicle.blueprint
                    ? {
                        ...localVehicle.blueprint,
                        standardLoad: copiedStandardLoad,
                      }
                    : undefined;
                setLocalVehicle((vehicle) =>
                  vehicle
                    ? { ...vehicle, blueprint: updatedBlueprint }
                    : undefined
                );
              }}
            />
            <DeleteIcon
              className="vehicle-preset-deleteIcon"
              onClick={() => {
                const copiedStandardLoad: StandardLoad[] = [
                  ...(localVehicle.blueprint?.standardLoad || []),
                ];
                copiedStandardLoad.splice(index, 1);
                const updatedBlueprint: StrippedVehicleBlueprint | undefined =
                  localVehicle.blueprint
                    ? {
                        ...localVehicle.blueprint,
                        standardLoad: copiedStandardLoad,
                      }
                    : undefined;
                setLocalVehicle((vehicle) =>
                  vehicle
                    ? { ...vehicle, blueprint: updatedBlueprint }
                    : undefined
                );
              }}
            />
          </div>
        );
      });
    return [
      ...standardLoadElements,
      standardLoadElements.length >= (materials.data?.length ?? 0) ? (
        <></>
      ) : (
        <ButtonComponent
          value={t("pages.vehicleEdit.vehicleBlueprint.addMaterial")}
          onClick={() => {
            const updatedBlueprint: StrippedVehicleBlueprint | undefined =
              localVehicle.blueprint
                ? {
                    ...localVehicle.blueprint,
                    standardLoad: [
                      ...(localVehicle.blueprint?.standardLoad || []),
                      { material: createEmptyMaterial(), amount: 0 },
                    ],
                  }
                : undefined;
            setLocalVehicle((vehicle) =>
              vehicle ? { ...vehicle, blueprint: updatedBlueprint } : undefined
            );
          }}
        />
      ),
    ];
  };

  return (
    <form id="edit-vehicle" onSubmit={onSubmit}>
      <InputComponent
        label={t("pages.vehicleEdit.name")}
        value={localVehicle.names[0]}
        onChange={(name) =>
          setLocalVehicle((current) =>
            current ? { ...current, names: [name] } : undefined
          )
        }
      />
      <DropdownComponent
        required
        label={t("pages.vehicleEdit.vehicleBlueprint.name")}
        options={vehicleBlueprints.data.map((vehicleBlueprint) => ({
          value: vehicleBlueprint.uid,
          label: vehicleBlueprint.name,
        }))}
        selectedOption={localVehicle.blueprint?.uid}
        onChange={(blueprintUid) => {
          const blueprint = vehicleBlueprints.data.find(
            (vehicleBlueprint) => vehicleBlueprint.uid === blueprintUid
          );
          setLocalVehicle((current) =>
            current
              ? { ...current, blueprint, seats: blueprint?.seats ?? 0 }
              : undefined
          );
        }}
      />

      {localVehicle.blueprint?.uid && (
        <div className="vehicle-preset-container">
          {iconUrl && (
            <div className="vehicle-preset-image-container">
              <p>{t("pages.vehicleBlueprint.icon")}:</p>
              <img alt={t("pages.vehicleBlueprint.icon")} src={iconUrl} />
            </div>
          )}
          <InputComponent
            disabled
            label={t("pages.vehicleBlueprint.type")}
            value={localVehicle.blueprint.type.name}
          />
          {renderStandardLoad()}
        </div>
      )}

      <DropdownComponent
        label={t("pages.vehicleEdit.einheit")}
        selectedOption={localVehicle.unit}
        options={einheiten.data.map((einheit) => ({
          value: einheit.uid,
          label: einheit.displayName,
        }))}
        onChange={(unit) =>
          setLocalVehicle((current) =>
            current ? { ...current, unit } : undefined
          )
        }
      />
      <InputComponent
        label={t("pages.vehicleEdit.seats")}
        type="number"
        value={localVehicle.seats}
        onChangeNumber={(seats) =>
          setLocalVehicle((current) =>
            current ? { ...current, seats } : undefined
          )
        }
      />
      <InputComponent
        label={t("pages.vehicleEdit.watertank")}
        type="number"
        value={localVehicle.watertankInLiters}
        onChangeNumber={(watertankInLiters) =>
          setLocalVehicle((current) =>
            current ? { ...current, watertankInLiters } : undefined
          )
        }
      />
      <InputComponent
        label={t("pages.vehicleEdit.firefightingFoam")}
        type="number"
        value={localVehicle.firefightingFoamInLiters}
        onChangeNumber={(firefightingFoamInLiters) =>
          setLocalVehicle((current) =>
            current ? { ...current, firefightingFoamInLiters } : undefined
          )
        }
      />
      <DropdownComponent
        options={gatewayOptions}
        label={t("pages.vehicleEdit.assignedGateway")}
        selectedOption={localVehicle.gatewayUid}
        onChange={(gatewayUid) =>
          setLocalVehicle((current) =>
            current ? { ...current, gatewayUid } : undefined
          )
        }
      />
      <div>
        <ButtonComponent
          type="submit"
          value={t("buttons.save")}
          isLoading={isLoading}
        />
        {localVehicle.uid && (
          <ButtonComponent
            value={t("buttons.delete")}
            isLoading={isLoading}
            onClick={handleDelete}
          />
        )}
        <ButtonComponent
          value={t("buttons.back")}
          isLoading={isLoading}
          onClick={() => navigate(-1)}
        />
      </div>
    </form>
  );
};

export default VehicleEdit;
