/**
 * Describes the diameter of the Hydrant
 */
export enum HydrantenDurchmesser {
  MM_300 = "MM_300",
  MM_150 = "MM_150",
}

/**
 * Describes the type of Hydrant
 */
export enum HydrantenTyp {
  UEBERFLURHYDRANT = "UEBERFLURHYDRANT",
  UNTERFLURHYDRANT = "UNTERFLURHYDRANT",
  NONE = "NONE",
}

/**
 * Describes the type of Verteilerleitung
 */
export enum Verteilerleitung {
  RINGLEITUNG = "RINGLEITUNG",
  STICHLEITUNG = "STICHLEITUNG",
  NONE = "NONE",
}

/**
 * Describes a hydrant with its properties
 */
export interface Hydrant {
  uid: string;
  createDate: string;
  lastUpdate: string;
  name: string;
  description?: string;
  lon: number;
  lat: number;
  durchmesser: HydrantenDurchmesser;
  type: HydrantenTyp;
  verteilerLeitung: Verteilerleitung;
}

/**
 * Generates an empty hydrant object
 * @param kind  optional {@link HydrantenTyp}};
 * @param verteilung  optional {@link Verteilerleitung}};
 * @returns  {@Hydrant} empty hydrant object
 */
export const generateEmptyHydrant = (
  overwrite?: Partial<Hydrant>
): Hydrant => ({
  uid: "",
  createDate: "",
  lastUpdate: "",
  name: "",
  description: "",
  lon: 0,
  lat: 0,
  durchmesser: HydrantenDurchmesser.MM_300,
  type: HydrantenTyp.NONE,
  verteilerLeitung: Verteilerleitung.NONE,
  ...overwrite,
});
