/**
 * Mailing address
 */
export interface Address {
  street: string;
  number: string;
  zip: string;
  city: string;
  additionalInfo: string;
}

/**
 * Creates initial address object
 * @returns empty address object
 */
export const generateEmptyAddress = (): Address => ({
  street: "",
  number: "",
  zip: "",
  city: "",
  additionalInfo: "",
});

/**
 * The customer is not a natural person, but is a superior entity for allocation
 * of users
 */
export interface Customer {
  uid: string;
  name: string;
  address: Address;
}

/**
 * generates an initial customer object
 * @returns eympty customer object
 */
export const generateEmptyCustomer = (): Customer => ({
  uid: "",
  name: "",
  address: generateEmptyAddress(),
});
