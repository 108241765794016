import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { missionServiceBaseUrl } from "../user/User.axios";
import { Vehicle, VehicleBlueprint } from "./Vehicle.types";
import { HistoryEntry } from "../history/History.types";

/**
 * API method to get the amount of vehicles which are currently on
 * active mission
 *
 * @param customerUid The customer uid of the currently logged in user
 * @param axios The axios instance
 * @returns the amount of vehicles on a mission, 0 on error
 */
export const countVehiclesOnMission = async (
  customerUid: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .get(`${missionServiceBaseUrl}/vehicle/count/mission/`, {
      params: { customerUid },
    })
    .then((response) => response.data)
    .catch((exc) => {
      console.error("Error during vehicle on mission count request", exc);
      return 0;
    });
};

/**
 * API method to update the vehicle with the given sectionUid
 * @param axios the axios instance ;
 * @param gatewayUid  the gatewayUid of the vehicle
 * @param sectionUid  the sectionUid of the vehicle
 * @returns true when successful, false on error
 */
export const updateVehicleWithSection = async (
  axios: AxiosInstance,
  gatewayUid: string,
  sectionUid: string
): Promise<boolean> =>
  axios
    .post(`${missionServiceBaseUrl}/vehicle/assign/section`, {
      gatewayUid,
      sectionUid,
    })
    .then((res) => res.status === 200)
    .catch((exc) => {
      console.error("Error during vehicle on mission count request", exc);
      return false;
    });

/**
 * API method to get all vehicles of a customer
 *
 * @param axios The axios instance
 * @param customerUid The customer uid of the currently logged in user
 * @returns The list of all vehicles, can be empty
 */
export const getAllVehiclesByCustomer = async (
  axios: AxiosInstance,
  customerUid: string
): Promise<Vehicle[]> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${missionServiceBaseUrl}/vehicle/customer/all/`, {
      params: { customerUid },
    })
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Vehicles couldn't be loaded", err);
      return [];
    });
};

/**
 * API method to get all vehicles of a customer of a mission
 *
 * @param axios The axios instance
 * @param customerUid The customer uid of the currently logged in user
 * @param missionUid The mission uid of the currently selected mission
 * @returns The list of all vehicles, can be empty
 */
export const getAllVehiclesByCustomerAndMission = async (
  axios: AxiosInstance,
  customerUid: string,
  missionUid: string
): Promise<Vehicle[]> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${missionServiceBaseUrl}/vehicle/mission/customer/`, {
      params: { missionUid, customerUid },
    })
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Vehicles couldn't be loaded", err);
      return [];
    });
};

/**
 * API method to create a new vehicle in the database
 *
 * @param axios The axios instance
 * @param vehicle The vehicle to be created
 *                (uid will be set to undefined to prevent backend to update an existing vehicle)
 * @returns Promise the vehicle with the uid
 */
export const createNewVehicle = async (
  axios: AxiosInstance,
  vehicle: Vehicle
): Promise<Vehicle> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.reject();
  return axios
    .post(`${missionServiceBaseUrl}/vehicle/`, { ...vehicle, uid: undefined })
    .then((res) => {
      if (res.status === 201) return { ...vehicle, uid: res.data };
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Vehicle couldn't be created", err);
      return Promise.reject();
    });
};

/**
 * API method to update a vehicle in the database
 *
 * @param axios The axios instance
 * @param vehicle The vehicle to be updated
 * @returns Promise void
 */
export const updateVehicle = async (
  axios: AxiosInstance,
  vehicle: Vehicle
): Promise<void> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.reject();
  return axios
    .post(`${missionServiceBaseUrl}/vehicle/update/`, vehicle)
    .then((res) => {
      if (res.status === 200) return;
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Vehicle couldn't be updated", err);
      return Promise.reject();
    });
};

/**
 * API method to delete a vehicle in the database
 *
 * @param axios The axios instance
 * @param vehicleUid The uid of the vehicle to be deleted
 * @returns Promise void
 */
export const deleteVehicle = async (
  axios: AxiosInstance,
  vehicleUid: string
): Promise<void> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.reject();
  return axios
    .post(`${missionServiceBaseUrl}/vehicle/delete/`, undefined, {
      params: { vehicleUid },
    })
    .then((res) => {
      if (res.status === 200) return;
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Vehicle couldn't be deleted", err);
      return Promise.reject();
    });
};

/**
 * Get all vehicle blueprints
 * @param axios  The axios instance
 * @returns  {Promise<VehicleBlueprint[]>}  The vehicle blueprints
 */
export const getAllVehicleBlueprint = async (
  axios: AxiosInstance
): Promise<VehicleBlueprint[]> =>
  axios
    .get(`${missionServiceBaseUrl}/vehicle/blueprint/`)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Vehicle blueprints couldn't be loaded", err);
      return Promise.reject(err);
    });

/**
 * Assign a vehicle to a mission and return the history entries.
 *
 * @param axios The axios instance
 * @param vehicleUid The uid of the vehicle to assign
 * @param missionUid The uid of the mission to assign the vehicle to
 * @returns promised the history entries
 */
export const assignVehicleToMission = async (
  axios: AxiosInstance,
  vehicleUid: string,
  missionUid: string
): Promise<HistoryEntry[]> =>
  axios
    .post(`${missionServiceBaseUrl}/vehicle/assign/mission`, {
      vehicleUid,
      missionUid,
    })
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Vehicle couldn't be assigned to mission", err);
      return Promise.reject(err);
    });

/**
 * Assign a vehicle to a staging room and return the history entries.
 *
 * @param axios The axios instance
 * @param vehicleUid The uid of the vehicle to assign
 * @param stagingRoomUid The uid of the staging room to assign the vehicle to
 * @returns promised the history enntry
 */
export const assignVehicleToStagingRoom = async (
  axios: AxiosInstance,
  vehicleUid: string,
  stagingRoomUid: string
): Promise<HistoryEntry> =>
  axios
    .post(`${missionServiceBaseUrl}/vehicle/assign/staging`, {
      vehicleUid,
      stagingRoomUid,
    })
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Vehicle couldn't be assigned to a staging room", err);
      return Promise.reject(err);
    });
