import dayjs, { Dayjs } from "dayjs";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useMission } from "../utils/mission/Mission.context";
import { useHistory } from "../utils/history/History.context";
import { ReactComponent as TimeIcon } from "../assets/time.svg";
import { ReactComponent as ForwardIcon } from "../assets/forward.svg";
import { useTranslation } from "react-i18next";

interface TimeControlOptions {
  min: number;
  max: number;
}

export const TimeControl: React.FC = () => {
  const { setShiftedTime } = useHistory();
  const { selectedMission } = useMission();
  const [option, setOptions] = useState<TimeControlOptions>({
    max: dayjs().unix(),
    min: dayjs(
      selectedMission?.einsatzStart ?? dayjs().subtract(5, "minutes")
    ).unix(),
  });
  const [selected, setSelected] = useState<number>(option.max);
  const [isLive, toggleLive] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const date = useMemo<Dayjs>(() => dayjs.unix(selected), [selected]);
  const { t } = useTranslation();

  /**
   * Start a interval to update the max date and the input element if live
   */
  useEffect(() => {
    const interval = setInterval(() => {
      const current = dayjs().unix();
      setOptions((prev) => ({ ...prev, max: current }));
      if (isLive) {
        if (!inputRef.current) return;
        inputRef.current.valueAsNumber = current;
        setSelected(current);
      }
    }, 200);

    return () => {
      clearInterval(interval);
    };
  });

  /**
   * Update the min date when it changes
   */
  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      min: dayjs(
        selectedMission?.einsatzStart ?? dayjs().subtract(1, "h")
      ).unix(),
    }));
  }, [selectedMission]);

  /**
   * Update the shifted time in context when the local date changes
   */
  useEffect(() => {
    setShiftedTime(isLive ? undefined : date.toDate());
  }, [date, isLive, setShiftedTime]);

  /**
   * Handler for the input element. Updates the selected date and calls the onChange callback.
   *
   * @param event the change event
   */
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelected(event.target.valueAsNumber);
    toggleLive(event.target.valueAsNumber === option.max);
  };

  /**
   * Method to set the time control to live
   */
  const goLive = (): void => {
    if (!inputRef.current) return;
    inputRef.current.valueAsNumber = option.max;
    setSelected(option.max);
    toggleLive(true);
  };

  /**
   * Memorized left offset for the current selected date under to input component.
   * This is used to stick the selected date under the input thumb.
   */
  const inputInfoLeftOffset = useMemo<string>(() => {
    const percentage: number =
      Math.round(((selected - option.min) / (option.max - option.min)) * 1000) /
      10;
    if (percentage > 80) return "80%";
    if (percentage < 20) return "20%";
    return `${percentage}%`;
  }, [selected, option]);

  // Hide the time control if no mission is selected
  if (!selectedMission) return null;

  return (
    <div className="time-control">
      <div className="time-control__title">
        <div>
          <p>{t("components.timeControl.alarmierungsZeit")}</p>
          <p>
            {dayjs(selectedMission.einsatzStart).format(
              t("components.timeControl.alarmierungsZeitFormat")
            )}
          </p>
        </div>
        <div>
          <p>{t("components.timeControl.currentTime")}</p>
          <p>{dayjs().format(t("components.timeControl.currentTimeFormat"))}</p>
        </div>
      </div>
      <div className="time-control__slider">
        <div className="time-control__slider__time">
          <TimeIcon />
        </div>
        <div className="time-control__slider__input">
          <input
            ref={inputRef}
            type="range"
            step={1}
            min={option.min}
            max={option.max}
            onChange={handleChange}
          />
          <div className="time-control__slider__input__info">
            <span style={{ left: inputInfoLeftOffset }}>
              {date.format(t("components.timeControl.currentInputFormat"))}
            </span>
          </div>
        </div>
        <div className="time-control__slider__forward" onClick={goLive}>
          <ForwardIcon />
        </div>
      </div>
    </div>
  );
};
