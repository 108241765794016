import { Option, TableRow } from "articon-component-library";
import i18n from "../../i18n";
import { StagingRoom, StagingRoomStatus } from "./Staging.types";

/**
 * Util to create {@link TableRow}s for a list of {@link StagingRoom}s
 * @param rooms the rooms to create the rows for ;
 * @param customers list of customers to create dropdown options for
 * @param onClickRow callback to call when row is clicked
 * @param onDeleteRow callback to call when delete button is clicked
 * @param onUpdateRow callback to call when customer is changed
 * @returns {@TableRow[]}s for the given rooms
 */
export const createTableRowsForStagingRooms = (
  rooms: StagingRoom[],
  onClickRow: (room: StagingRoom) => void,
  onDeleteRow: (roomUid: string) => void,
  onUpdateRow: (room: StagingRoom) => void
): TableRow[] => {
  const roomEntries: TableRow[] = [];
  rooms.forEach((room) => {
    const roomRow: TableRow = createTableRowForStagingRoom(
      room,
      onClickRow,
      onDeleteRow,
      onUpdateRow
    );
    roomEntries.push(roomRow);
  });
  return roomEntries;
};

/**
 * Util to create a single {@link TableRow} for a {@link StagingRoom}
 * @param room the room to create the row for ;
 * @param customers  list of customers to create dropdown options for
 * @param onClick  callback to call when row is clicked
 * @param onDelete  callback to call when delete button is clicked
 * @param onUpdate  callback to call when customer is changed
 * @returns  {@TableRow} for the room
 */
export const createTableRowForStagingRoom = (
  room: StagingRoom,
  onClick: (room: StagingRoom) => void,
  onDelete: (roomUid: string) => void,
  onUpdate: (room: StagingRoom) => void
): TableRow => {
  const roomRow: TableRow = {
    value: [
      { value: room.name },
      {
        value: i18n.t(
          `pages.stagingRoomAdministration.roomStatus.${room.status}`
        ),
      },
      {
        value: (
          <div
            onClick={(evt) => {
              evt.stopPropagation();
              onDelete(room.uid);
            }}
          >
            X
          </div>
        ),
      },
    ],
    onClick: () => onClick(room),
    onClickMobile: () => onClick(room),
  };
  return roomRow;
};

/**
 * Util to create dropdown {@link Option[]} for staging room status
 * @returns {@Option[]} list of options
 */
export const createDropDownOptionsForStagingRoomStatus = (): Option[] => {
  return Object.entries(StagingRoomStatus).map(
    ([key, value]): Option => ({
      value: value,
      label: i18n.t(`pages.stagingRoomAdministration.roomStatus.${value}`),
    })
  );
};
