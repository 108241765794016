import { AxiosInstance } from "axios";
import { Address } from "../customer/Customer.types";
import {
  MapItem,
  MapLayer,
  MapSection,
  SingleMapLayerProps,
  VehicleMarker,
} from "./Map.types";
import { DivIcon, divIcon, LatLngTuple } from "leaflet";
import { renderToString } from "react-dom/server";
import { ReactComponent as DefaultMarker } from "../../assets/marker.svg";
import { ReactComponent as StagingOff } from "../../assets/map/STAGING_off.svg";
import { ReactComponent as StagingOn } from "../../assets/map/STAGING_on.svg";
import { ReactComponent as HydrantOn } from "../../assets/map/HYDRANT_on.svg";
import { ReactComponent as HydrantOff } from "../../assets/map/HYDRANT_off.svg";
import { v4 as uuidv4 } from "uuid";
import { StagingRoom } from "../staging/Staging.types";
import { Hydrant } from "../hydranten/Hydrant.types";
import { Hint } from "../hint/Hint.types";
import { Vehicle } from "../vehicle/Vehicle.types";

/**
 * API METHOD - to fetch current coords for address
 * @param axios network instance network instance
 * @param address to fetch the coords for
 * @returns found coords as [lon, lat] or defaultMapCenter
 */
export const getCoordinatesForAddress = (
  axios: AxiosInstance,
  address: Address,
  fallback: LatLngTuple
): Promise<[lat: number, lon: number]> => {
  return axios
    .get(
      `https://nominatim.openstreetmap.org/search?` +
        `q=${encodeURIComponent(address.number)}+${encodeURIComponent(
          address.street
        )}+${encodeURIComponent(address.zip)}+${encodeURIComponent(
          address.city
        )}&format=json`
    )
    .then((data) => [
      parseFloat(data.data[0] !== undefined ? data.data[0].lat : fallback[0]),
      parseFloat(data.data[0] !== undefined ? data.data[0].lon : fallback[1]),
    ]);
};

/**
 * Helper to create a custom marker on the map according
 * to given MapItem
 *
 * @param mapItem containing all relevant data for marker
 * @returns created custom marker for mapItem
 */
export const generateMapIcon = (mapItem: MapItem): DivIcon => {
  return divIcon({
    className: "map-wrapper-marker",
    html: renderToString(
      mapItem.icon ? (
        <div style={{ color: mapItem.color || "#5252f9" }}>{mapItem.icon}</div>
      ) : (
        <DefaultMarker style={{ color: mapItem.color || "#5252f9" }} />
      )
    ),
  });
};

/**
 * Helper to filter MapItems for MapLayers
 *
 * @param mapItems items that are available for Map
 * @param visibleLayers layers that should be visible on Map
 * @returns mapItems that are on visibleLayers
 * @tested
 */
export const filterMapLayers = (
  mapItems: MapItem[],
  visibleLayers: MapLayer[]
): MapItem[] => {
  return mapItems.filter((item) => visibleLayers.includes(item.layer));
};

/**
 * Helper to generate New MapItem on MapLayer GENERAL given location
 *
 * @param overwrite optional overwrite of default values
 * @returns new MapItem
 * @tested
 */
export const generateNewMapItem = (overwrite?: Partial<MapItem>): MapItem => ({
  uid: uuidv4(),
  location: [0, 0],
  name: "",
  layer: MapLayer.GENERAL,
  ...overwrite,
});

/**
 * Helper to generate New MapSection on MapLayer GENERAL with given coords or empty array
 *
 * @param color optional, initially sets color, will be "#5252f9" in default
 * @param coords optional, initially sets coords, will be empty array when left out
 * @returns new MapSection
 * @tested
 */
export const generateNewMapSection = (
  color?: string,
  coords?: LatLngTuple[]
): MapSection => ({
  uid: uuidv4(),
  name: "",
  coords: coords || [],
  color: color || "#5252f9",
  layer: MapLayer.GENERAL,
});

/**
 * Helper to determine if a MapItem is inside a MapSection by using Ray Casting Algorithm
 * @param itemLocation The Location of the MapItem to check for if it's in section
 * @param section MapSection to check if item is inside
 * @returns true when marker is inside section, false otherwise
 * @tested
 */
export const isItemInsideSection = (
  itemLocation: LatLngTuple,
  section: MapSection
): boolean => {
  //get item coords
  const itemLat: number = itemLocation[0];
  const itemLng: number = itemLocation[1];
  //get sections coords to loop over
  const sectionCoords: LatLngTuple[] = section.coords;
  let inside = false;
  //loop over coordinates, detecting intersections between a closed shape of the
  //section and the marker, every intersection will toggle if the marker is inside,
  //since marker can be in a notch or an excluded space in section
  for (let coordIndex = 0; coordIndex < sectionCoords.length; coordIndex++) {
    //get coords of current point to check
    const currentCoordLat: number = sectionCoords[coordIndex][0];
    const currentCoordLng: number = sectionCoords[coordIndex][1];
    //since the first point to check has no "former" entry, we set the last as former point
    const indexToCompare: number =
      coordIndex === 0 ? sectionCoords.length - 1 : coordIndex - 1;
    const coordToCompareLat = sectionCoords[indexToCompare][0];
    const coordToCompareLng = sectionCoords[indexToCompare][1];
    //comparing points and calculating relation to item to determine intersection
    const diffSectionCoordsLat: number = coordToCompareLat - currentCoordLat;
    const diffItemCurrentCoordLng: number = itemLng - currentCoordLng;
    const diffSectionCoordsLng: number = coordToCompareLng - currentCoordLng;
    const positiveRayCanReachItem: boolean =
      itemLat <
      (diffSectionCoordsLat * diffItemCurrentCoordLng) / diffSectionCoordsLng +
        currentCoordLat;
    const itemBiggerCurrentCoordLng: boolean = currentCoordLng > itemLng;
    const itemBiggerCoordToCompareLng: boolean = coordToCompareLng > itemLng;
    const itemInsideLng: boolean =
      itemBiggerCurrentCoordLng !== itemBiggerCoordToCompareLng;
    const intersect = itemInsideLng && positiveRayCanReachItem;
    //toggleing inside for each intersect
    if (intersect) inside = !inside;
  }
  return inside;
};

/**
 * Helper method to update mapSections array after change
 *
 * @param mapSections mapSections array to update
 * @param mapSection mapSection to create/update/delete in mapSections array
 * @param isDeletion wheter or not the performed action is a deletion
 * @returns updated version of given array
 * @tested
 */
export const updateMapSectionInArray = (
  mapSections: MapSection[],
  mapSection: MapSection,
  isDeletion?: boolean
): MapSection[] => {
  const mapSectionIndex: number = mapSections.findIndex(
    (mapSectionToCompare) => mapSection.name === mapSectionToCompare.name
  );
  const updatedArray: MapSection[] = [...mapSections];
  if (isDeletion && mapSectionIndex !== -1)
    updatedArray.splice(mapSectionIndex, 1);
  else {
    if (mapSectionIndex === -1) updatedArray.push(mapSection);
    else updatedArray[mapSectionIndex] = mapSection;
  }
  return updatedArray;
};

/**
 * Helper to generate random hex colors
 *
 * @returns random hex color
 */
export const generateRandomColor = (): string => {
  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, "0")}`;
};

/**
 * Helper to get correct icon for layer
 */
export const addCorrectLayerIcon = (
  mapsLayers: SingleMapLayerProps[]
): SingleMapLayerProps[] => {
  return mapsLayers.map((layer: SingleMapLayerProps) => {
    switch (layer.key) {
      case MapLayer.STAGING:
        return {
          ...layer,
          layerIcon: layer.visible ? <StagingOn /> : <StagingOff />,
        };
      case MapLayer.HYDRANT:
        return {
          ...layer,
          layerIcon: layer.visible ? <HydrantOn /> : <HydrantOff />,
        };
      default:
        return layer;
    }
  });
};

/**
 * Creates {@link MapItem}[] for {@link StagingRoom}[]
 * @param rooms  the rooms to create the map items for
 * @param icon  the icon that should be displayed on the map
 * @returns  {@MapItem[]}
 */
export const createStagingRoomMapItems = (
  rooms: StagingRoom[],
  icon: JSX.Element
): MapItem[] => {
  return rooms
    .map((stagingRoom: StagingRoom) => {
      const [lon, lat] = [stagingRoom.lon, stagingRoom.lat];
      if (lon === 0 && lat === 0) return null;
      const item: MapItem = {
        uid: stagingRoom.uid,
        layer: MapLayer.STAGING,
        location: [lat, lon],
        name: stagingRoom.name,
        icon: icon,
      };

      return item;
    })
    .filter((item) => !!item) as MapItem[];
};

/**
 * Creates {@link MapItem}[] for {@link Hydrant}[]
 * @param hydranten  the hydranten to create the map items for
 * @param icon  the icon that should be displayed on the map
 * @returns  {@MapItem[]}
 */
export const createHydrantenMapItems = (
  hydranten: Hydrant[],
  icon: JSX.Element
): MapItem[] => {
  return hydranten
    .map((hydrant: Hydrant) => {
      const [lon, lat] = [hydrant.lon, hydrant.lat];
      if (lon === 0 && lat === 0) return null;
      const item: MapItem = {
        uid: hydrant.uid,
        layer: MapLayer.HYDRANT,
        location: [lat, lon],
        name: hydrant.name,
        icon: icon,
      };

      return item;
    })
    .filter((item) => !!item) as MapItem[];
};

/**
 * Creates {@link MapItem}[] for {@link Hint}[]
 * @param hints  the hints to create the map items for
 * @param icon  the icon that should be displayed on the map
 * @returns  {@MapItem[]}
 */
export const createHintMapItems = (
  hints: Hint[],
  icon: JSX.Element,
  onClick: (hint: Hint) => void,
  onDragEnd: (hint: Hint, newPosition: LatLngTuple) => void
): MapItem[] => {
  return hints
    .map((hint: Hint) => {
      const [lon, lat] = hint.coords;
      if (lon === 0 && lat === 0) return null;
      const item: MapItem = {
        uid: hint.uid,
        layer: MapLayer.HINT,
        location: [lat, lon],
        name: hint.content,
        icon: icon,
        onClick: () => onClick(hint),
        onDragEnd: (latLng: LatLngTuple) => onDragEnd(hint, latLng),
      };

      return item;
    })
    .filter((item) => !!item) as MapItem[];
};

/**
 * Creates map items for vehicle markers
 * @param assets {@link VehicleMarker}[]} ;
 * @param fallbackIcon the icon that should be displayed on the map if no icon is available
 * @returns  {@MapItem[]}
 */
export const createAssetLiveMapItems = (
  vehicleMarkers: VehicleMarker[],
  vehicles: Vehicle[],
  fallbackIcon: JSX.Element
): MapItem[] => {
  return vehicleMarkers
    .map((vehicleMarker) => {
      const vehicle: Vehicle | undefined = vehicles.find(
        (vehcileToCheck) => vehcileToCheck.gatewayUid === vehicleMarker.uid
      );

      const [lon, lat] = vehicleMarker.location ?? [0, 0];
      if (lon === 0 && lat === 0) return null;
      const item: MapItem = {
        uid: vehicleMarker.uid,
        isGateway: true,
        layer: MapLayer.GENERAL,
        location: [lat, lon],
        name: vehicleMarker.name,
        icon: vehicle?.iconUrl ? (
          <img
            src={vehicle?.iconUrl}
            alt="Fahrzeug Icon"
            width={60}
            height={60}
          />
        ) : (
          fallbackIcon
        ),
      };

      return item;
    })
    .filter((item) => !!item) as MapItem[];
};
