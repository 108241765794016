import {
  ref,
  deleteObject,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { storage } from "../../firebase";

/**
 * Firebase function to save a vehicle blueprint icon
 * @param image  The image to save
 * @param blueprintUid  The uid of the blueprint
 * @returns {Promise<string>}  The path to the image
 */
export const saveVehicleBlueprintIcon = (
  image: File,
  blueprintUid: string
): Promise<string> => {
  const iconRef = ref(storage, `vehicleBlueprintIcons/${blueprintUid}`);
  deleteObject(iconRef).catch(() => {
    console.log("No old icon found");
  });

  return uploadBytes(iconRef, image)
    .then((result) => result.ref.fullPath)
    .catch((err) => {
      console.error("Icon couldn't be uploaded", err);
      return Promise.reject(err);
    });
};

/**
 * Firebase function to get a vehicle blueprint icon
 * @param blueprintUid  The uid of the blueprint
 * @returns  {Promise<string>}  The path to the image
 */
export const getVehicleIconUrl = (blueprintUid: string): Promise<string> => {
  const iconRef = ref(storage, `vehicleBlueprintIcons/${blueprintUid}`);
  return getDownloadURL(iconRef).catch(() => {
    console.log("No icon found");
    return "";
  });
};
