import { LeafletEvent, Map } from "leaflet";
import { LassoHandler } from "leaflet-lasso";
import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { useEmitter } from "../../utils/event/Event.context";

export interface LassoControlProps {
  setLassoHandler(lasso: LassoHandler | undefined): void;
  setLeafletMap?(map: Map): void;
  disabled?: boolean;
}
/**
 * sets Lasso Handler and allows to handle Lasso Finished event,
 * when placed INSIDE a MapContainer to use useMap hook
 *
 * @param setLassoHandler sets the lasso handler, so lasso can be enabled/disabled
 * @param setLeafletMap sets map once available from useMap hook
 * @param disabled disables lasso when true, enabled otherwise
 */
export const LassoControl: React.FC<LassoControlProps> = ({
  setLassoHandler,
  setLeafletMap = () => {},
  disabled,
}) => {
  const leafletMap: Map = useMap();
  const emit = useEmitter();

  /**
   * Performs handleLassoFinished with LassoHandlerFinishedEvent instead of LeafletEvent
   * @param event incoming LeafletEvent from "lasso.finished"
   */
  const onLassoFinished = (event: LeafletEvent): void => {
    emit("mapSelectionFinished", event);
  };

  //prepares lasso handler and hooks lasso finished event
  useEffect(() => {
    if (!leafletMap) return;
    setLeafletMap(leafletMap);

    if (disabled) setLassoHandler(undefined);
    else {
      const lasso: LassoHandler = new LassoHandler(leafletMap, {
        //elements are only returned by event, when they are IN the area, intersection is not enough
        intersect: false,
        //smoothFactor determines how much to simplify the polygon on each zoom level
        //the higher, the better performance
        polygon: { smoothFactor: 5 },
      });
      setLassoHandler(lasso);
      leafletMap.on("lasso.finished", onLassoFinished);
    }
    return () => {
      leafletMap.off("lasso.finished", onLassoFinished);
    };
    // eslint-disable-next-line
  }, [leafletMap, disabled]);

  return null;
};
