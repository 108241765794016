import { Option } from "articon-component-library";
import { Beacon } from "./Beacon.types";

/**
 * Util method to create dropdown options for beacons
 * @param beacons  - beacons to create options for
 * @returns  - options for dropdown
 */
export const createBeaconsDropdownOptions = (beacons: Beacon[]): Option[] => {
  return beacons.map((beacon) => ({
    value: beacon.uid,
    label: `${beacon.name} (${beacon.uid})`,
  }));
};
