import { Vehicle } from "./Vehicle.types";

/**
 * Creates a new empty vehicle object with optional overwrites
 *
 * @param overwrite The values to overwrite
 * @returns The new vehicle object
 */
export const createNewEmptyVehicle = (
  overwrite: Partial<Vehicle> = {}
): Vehicle => ({
  uid: undefined!,
  seats: 0,
  amountChainsaws: 0,
  amountChemicalProtectiveSuits: 0,
  amountRespirators: 0,
  customerUid: "",
  createDate: new Date(),
  equipmentStorageId: "",
  firefightingFoamInLiters: 0,
  gatewayUid: "",
  lastUpdate: new Date(),
  names: [""],
  unit: "",
  vehicleType: "",
  watertankInLiters: 0,
  ...overwrite,
});
