import {
  TableRow,
  TableComponent,
  ButtonComponent,
} from "articon-component-library";
import useSWR from "swr";
import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  updateStagingRoom,
  deleteStagingRoom,
  getAllStagingRoomsForCustomer,
} from "../../utils/staging/Staging.axios";
import { StagingRoom } from "../../utils/staging/Staging.types";
import { createTableRowsForStagingRooms } from "../../utils/staging/Staging.utils";
import { useUser } from "../../utils/auth/UserContext";
import { useNavigate } from "react-router-dom";

const StagingRoomAdministration: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { axios, user } = useUser();

  const stagingRooms = useSWR(
    !!axios && user ? ["staging/customer", user.customerUid] : null,
    ([, customerUid]) =>
      axios ? getAllStagingRoomsForCustomer(axios, customerUid) : [],
    { fallbackData: [] }
  );

  /**
   * Updates the staging room on the backend and updates the local staging room array
   * @param updatedRoom  updated staging room
   */
  const updateSingleStagingRoom = useCallback(
    (updatedRoom: StagingRoom) => {
      if (!axios) return;
      updateStagingRoom(axios, updatedRoom).then((success) => {
        if (!success) return;
        stagingRooms.mutate(
          stagingRooms.data.map((room) =>
            room.uid === updatedRoom.uid ? updatedRoom : room
          )
        );
      });
    },
    [axios, stagingRooms]
  );

  /**
   * Deletes the staging room on the backend and updates the local staging room array
   * @param roomUid  uid of the staging room to be deleted
   */
  const deleteSingleStagingRoom = useCallback(
    async (roomUid: string) => {
      if (!axios) return;
      const success = await deleteStagingRoom(axios, roomUid);
      if (!success) return;
      stagingRooms.mutate(
        stagingRooms.data.filter((room) => room.uid !== roomUid)
      );
    },
    [axios, stagingRooms]
  );

  /**
   * Navigates to the edit page and passes the selected staging room as state
   */
  const handleOnClick = useCallback(
    (selectedStagingRoom: StagingRoom) => {
      navigate("edit", { state: { selectedStagingRoom } });
    },
    [navigate]
  );

  const stagingRoomEntries: TableRow[] = useMemo(
    () =>
      createTableRowsForStagingRooms(
        stagingRooms.data,
        handleOnClick,
        deleteSingleStagingRoom,
        updateSingleStagingRoom
      ),
    [
      stagingRooms,
      deleteSingleStagingRoom,
      handleOnClick,
      updateSingleStagingRoom,
    ]
  );

  return (
    <>
      <TableComponent
        tableHeader={t("pages.stagingRoomAdministration.tableHeader", {
          returnObjects: true,
        })}
        tableEntries={stagingRoomEntries}
      />

      <ButtonComponent
        value={t("buttons.create")}
        onClick={() => navigate("create")}
      />
    </>
  );
};

export default StagingRoomAdministration;
