import { Address } from "../customer/Customer.types";
import { MapSection } from "../map/Map.types";

/**
 * This is the representation of a mission, containing relevant
 * information for performing and documenting it
 */
export interface Mission {
  uid: string;
  name?: string;
  createDate: Date;
  lastUpdate: Date;
  einsatzNummer: string;
  alarmStufe: number;
  stichwort: string;
  address: Address;
  latLon: [number, number];
  alarmierungsZeit: number;
  einsatzStart: Date;
  einsatzEnde?: Date;
  alarmierteEinheiten: string[];
  fahrzeuge: string[];
  fahrzeugeWithUsers: Map<string, string[]>;
  customerUid: string;
  sections: MapSection[];

  /**
   * The assignment radius of the mission in meters.
   * Used for vehicle assignment.
   */
  assignmentRadius?: number;
}

export const missionStichwortRegex = [/[A-Za-z]/i, /[0-9]+/i, /\./i, /[0-9]+/i];

/**
 * Needed in Dashboard to check if alarmed units are visible
 */
export type MissionWithAlarmedUnits = Mission & {
  alarmedUnitsVisible: boolean;
};
