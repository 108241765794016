import { AxiosInstance } from "axios";
import { getToken, isSupported, MessagePayload } from "firebase/messaging";
import { messaging } from "../firebase";
import { updateUser } from "./user/User.axios";
import { User } from "./user/User.types";

/**
 * Helper to request permission from user to display notifications
 *
 * @returns true, when permission is granted, false otherwise
 */
export const requestPermission = async (): Promise<boolean> => {
  return Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      return true;
    }
    console.log(
      "If you want to get notifications, please turn on your notifications and refresh the page, please."
    );
    return false;
  });
};

/**
 * Helper to get token, once permission is given to send notifications
 *
 * @returns the token, when successfully loaded, else an empty string
 */
const getTokenWhenNotificationPermitted = async (): Promise<string> => {
  const vapidKey: string =
    process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY || "";
  if (!vapidKey) console.error("No public vapid key defined! 😤");
  const permissionGiven: boolean = await requestPermission();
  if (!permissionGiven) return "";
  return getToken(messaging, {
    vapidKey: vapidKey,
  })
    .then((currentToken) => {
      if (currentToken) return currentToken;
      else console.log("Token could not be loaded.");
      return "";
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      return "";
    });
};

/**
 * Handles incoming messages
 *
 * @param payload
 * @TODO implement notification component and use it here
 */
export const messageHandler = (
  payload: MessagePayload | MessagePayload
): void => {
  console.log("Message received. " + JSON.stringify(payload.notification));
};

/**
 * When current device is supported and token can be succesfully loaded, it will be added to
 * the tokens of the user
 *
 * @param axios
 * @param user
 */
export const setCurrentToken = async (
  axios: AxiosInstance,
  user: User
): Promise<void> => {
  let token: string = "";
  const isDeviceSupported: boolean = await isSupported();
  if (isDeviceSupported) token = await getTokenWhenNotificationPermitted();
  if (token) {
    //to prevent duplicates we check if token may already be saved
    const index: number = user.tokens.findIndex(
      (tokenOfUser) => tokenOfUser === token
    );
    if (index === -1)
      updateUser(axios, { ...user, tokens: [...user.tokens, token] });
  }
};
