import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../firebase";

/**
 * Helper method to login an user with the help of the given mail and password
 *
 * @param email The user entered mail
 * @param password The user entered password
 */
export const logInWithEmailAndPassword = async (
  email: string,
  password: string
): Promise<boolean> => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return true;
  } catch (err: any) {
    console.error("Error during login!", err);
    return false;
  }
};

/**
 * Helper to send a password reset mail to the user in case the given
 * mail exists on the backend
 *
 * @param email The user entered mail
 */
export const sendPasswordReset = async (email: string): Promise<void> => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err: any) {
    console.error("Error during reset mail sending!", err);
  }
};

/**
 * Helper method to logout the currently logged in user
 */
export const logout = (): void => {
  auth.signOut();
};

/**
 * Helper method to determine if an user is signed or not
 *
 * @returns true if an user is signed in, false otherwise
 */
export const userIsSignedIn = (): boolean => {
  return !!auth.currentUser;
};

export const getUserJwt = async (): Promise<string> => {
  return (await auth.currentUser?.getIdToken()) || "";
};
