import {
  ButtonComponent,
  DropdownComponent,
  InputComponent,
  Option,
} from "articon-component-library";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Einheit } from "../../utils/einheit/Einheit.types";
import useSWR, { mutate } from "swr";
import { useUser } from "../../utils/auth/UserContext";
import { useTranslation } from "react-i18next";
import { loadAllUsers } from "../../utils/user/AdminUser.utils";
import {
  createEinheit,
  deleteEinheit,
  updateEinheit,
} from "../../utils/einheit/Einheit.axios";
import { AddressForm } from "../../components/AddressForm";

const EinheitEdit: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation<{ einheit: Einheit }>();
  const { user, axios } = useUser();
  const [localEinheit, setLocalEinheit] = useState<Einheit | undefined>(
    location.state?.einheit
  );
  const [isLoading, toggleLoading] = useState<boolean>(false);

  const users = useSWR(
    ["user/all/customer", user.customerUid],
    ([, customerUid]) => loadAllUsers(axios, customerUid),
    {
      fallbackData: [],
    }
  );

  /**
   * Update local einheit state when location state changes
   */
  useEffect(() => {
    if (!location.state?.einheit) navigate(-1);
    setLocalEinheit(location.state?.einheit);
  }, [location.state?.einheit, navigate]);

  /**
   * Callback which handles the save button
   * This either creates or updates the einheit, updates swr
   * and navigates back to the previous page
   */
  const handleSave = useCallback(async () => {
    if (!localEinheit) return;
    toggleLoading(true);
    const updatedEinheit = await (localEinheit.uid
      ? updateEinheit(axios, localEinheit)
      : createEinheit(axios, localEinheit));
    if (!updatedEinheit) return;
    await mutate<Einheit[]>(
      ["mission/einheit/customer", user.customerUid],
      (einheiten) =>
        einheiten
          ? einheiten.map((einheit) =>
              einheit.uid === updatedEinheit.uid ? updatedEinheit : einheit
            )
          : undefined
    );
    navigate(-1);
    toggleLoading(false);
  }, [axios, localEinheit, navigate, user.customerUid]);

  /**
   * Callback which handles the delete button
   */
  const handleDelete = useCallback(async () => {
    if (!localEinheit) return;
    toggleLoading(true);
    await deleteEinheit(axios, localEinheit.uid);
    navigate(-1);
    toggleLoading(false);
  }, [axios, localEinheit, navigate]);

  /**
   * Memoize user dropdown options
   */
  const userDropdownOptions = useMemo<Option[]>(
    () =>
      users.data.map((user) => ({
        label: `${user.firstname} ${user.lastname}`,
        value: user.uid,
      })),
    [users.data]
  );

  if (!localEinheit) return null;

  return (
    <div>
      <InputComponent
        label={t("pages.einheitEdit.name")}
        value={localEinheit.name}
        onChange={(name) =>
          setLocalEinheit((current) =>
            current ? { ...current, name } : undefined
          )
        }
      />
      <InputComponent
        label={t("pages.einheitEdit.displayName")}
        value={localEinheit.displayName}
        onChange={(displayName) =>
          setLocalEinheit((current) =>
            current ? { ...current, displayName } : undefined
          )
        }
      />
      <InputComponent
        label={t("pages.einheitEdit.description")}
        value={localEinheit.description}
        onChange={(description) =>
          setLocalEinheit((current) =>
            current ? { ...current, description } : undefined
          )
        }
      />
      <DropdownComponent
        label={t("pages.einheitEdit.users")}
        allowMultiSelect
        options={userDropdownOptions}
        selectedOptions={localEinheit.users}
        onChangeMultiple={(users) =>
          setLocalEinheit((current) =>
            current ? { ...current, users } : undefined
          )
        }
      />
      <div>
        <AddressForm
          initAddress={localEinheit.address}
          initCoordinates={[localEinheit.lat, localEinheit.lon]}
          onChange={(address, coordinates) => {
            setLocalEinheit((current) =>
              current
                ? {
                    ...current,
                    address,
                    lat: coordinates[0],
                    lon: coordinates[1],
                  }
                : undefined
            );
          }}
        />
      </div>
      <div>
        <ButtonComponent
          value={t("buttons.save")}
          isLoading={isLoading}
          onClick={handleSave}
        />
        <ButtonComponent
          value={t("buttons.delete")}
          isLoading={isLoading}
          onClick={handleDelete}
        />
      </div>
    </div>
  );
};

export default EinheitEdit;
