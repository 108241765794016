/**
 * Descrjbes a qualification a user can have
 */
export type Qualification = {
  uid: string;
  name: string;
  groupId: string;
  displayType?: QualificationDisplayType;
};

/**
 * Determines how the qualification is displayed in tactical overview
 */
export enum QualificationDisplayType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

/**
 * Describes a qualification group, a qualification can be part of
 */
export interface QualificationGroup {
  uid: string;
  name: string;
  allowedAmount: number;
}

export type QualificationGroupWithQualifications = QualificationGroup & {
  qualifications: Qualification[];
};

/**
 * Describes a qualification a user has
 */
export interface UserQualification extends Qualification {
  expired?: boolean;
  expirationDate?: Date;
}
