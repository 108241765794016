import { AxiosInstance } from "axios";
import { Material } from "./Material.types";
import { missionServiceBaseUrl } from "../user/User.axios";

/**
 * Get all materials
 *
 * @param axios The axios instance
 * @returns The list of all materials, can be empty
 */
export const getAllMaterials = async (
  axios: AxiosInstance
): Promise<Material[]> =>
  axios
    .get(`${missionServiceBaseUrl}/material/all/`)
    .then((res) => {
      if (res.status === 200) return res.data;
    })
    .catch((err) => {
      console.error("Materials couldn't be loaded", err);
      return [];
    });
