import React from "react";
import { useTranslation } from "react-i18next";
import {
  AlarmedUnitsBox,
  ForcesBox,
  MissionTimeBox,
  MissionVehiclesBox,
} from "../components/DashboardBox";

const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="dashboard-page__wrapper">
      <h2>{t("pages.dashboard.header")}</h2>
      <div className="dashboard-page__content">
        <MissionTimeBox />
        <MissionVehiclesBox />
        <ForcesBox />
        <AlarmedUnitsBox />
      </div>
    </div>
  );
};

export default Dashboard;
