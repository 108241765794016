// db.ts
import Dexie, { Table } from "dexie";
import { GatewaySnapshot } from "../gateway/Gateway.types";
import { HistoryEntry } from "./History.types";

export class HistoryDexie extends Dexie {
  gateways!: Table<GatewaySnapshot>;
  mission!: Table<HistoryEntry>;
  hints!: Table<HistoryEntry>;

  constructor() {
    super("articonnect_history");
    this.version(1).stores({
      gateways: "[timestamp+gatewayUid], gatewayUid, timestamp",
      mission: "[timestamp+modelUid], modelUid, timestamp",
      hints: "[timestamp+modelUid], modelUid, timestamp",
    });
  }
}

export const historyDb = new HistoryDexie();
