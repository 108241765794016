import {
  ButtonComponent,
  TableComponent,
  TableRow,
} from "articon-component-library";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../utils/auth/UserContext";
import { deleteUser, loadAllUsers } from "../../utils/user/AdminUser.utils";
import { User } from "../../utils/user/User.types";
import {
  createTableRowsForUsers,
  updateUserInArray,
} from "../../utils/user/User.utils";

const UserAdministration: React.FC<{}> = () => {
  const [userEntries, setUserEntries] = useState<TableRow[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const { t } = useTranslation();
  const { axios, user } = useUser();
  const navigate = useNavigate();

  //loads users and beacons for customers once axios is available
  useEffect(() => {
    if (axios && user) loadAllUsers(axios, user.customerUid).then(setUsers);
  }, [axios, user]);

  //sets users as table entries
  useEffect(() => {
    setUserEntries(createTableRowsForUsers(users, handleOnClick, onDeleteUser));
    // eslint-disable-next-line
  }, [users]);

  /**
   * Handles the onclick event in the user table
   *
   * @param selectedUser the user object on which was clicked
   */
  const handleOnClick = (selectedUser: User): void => {
    navigate("edit", { state: { selectedUser } });
  };

  /**
   * deletes user on backend and updates local user array
   * @param user
   */
  const onDeleteUser = (user: User): void => {
    if (axios)
      deleteUser(axios, user.uid).then(() =>
        setUsers(updateUserInArray(users, user, true))
      );
  };

  return (
    <>
      <TableComponent
        tableHeader={t("pages.userAdministration.tableHeader", {
          returnObjects: true,
        })}
        tableEntries={userEntries}
      />
      <div className="user-administration__button-container">
        <ButtonComponent
          value={t(`buttons.create`)}
          onClick={() => navigate("create")}
        />
      </div>
    </>
  );
};

export default UserAdministration;
