import { z } from "zod";

/**
 * The OpenWeather API model object. For updates see here:
 * https://openweathermap.org/current#current_JSON
 */
export const OpenWeatherModel = z.object({
  coord: z.object({
    lon: z.number().optional(),
    lat: z.number().optional(),
  }),
  weather: z
    .object({
      id: z.number().optional(),
      main: z.string().optional(),
      description: z.string().optional(),
      icon: z.string().optional(),
    })
    .array(),
  base: z.string().optional(),
  main: z.object({
    temp: z.number().optional(),
    feels_like: z.number().optional(),
    temp_min: z.number().optional(),
    temp_max: z.number().optional(),
    pressure: z.number().optional(),
    humidity: z.number().optional(),
    sea_level: z.number().optional(),
    grnd_level: z.number().optional(),
  }),
  visibility: z.number().optional(),
  wind: z.object({
    speed: z.number().optional(),
    deg: z.number().optional(),
    gust: z.number().optional(),
  }),
  clouds: z.object({ all: z.number().optional() }),
  rain: z
    .object({
      "1h": z.number().optional(),
      "3h": z.number().optional(),
    })
    .optional(),
  snow: z
    .object({
      "1h": z.number().optional(),
      "3h": z.number().optional(),
    })
    .optional(),
  dt: z.number().optional(),
  sys: z.object({
    type: z.number().optional(),
    id: z.number().optional(),
    country: z.string().optional(),
    sunrise: z.number().optional(),
    sunset: z.number().optional(),
    message: z.string().optional(),
  }),
  timezone: z.number().optional(),
  id: z.number().optional(),
  name: z.string().optional(),
  cod: z.number().optional(),
});

/**
 * The typed OpenWeather model to use
 */
export type OpenWeatherResponse = z.infer<typeof OpenWeatherModel>;
