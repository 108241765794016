import { Mission } from "../../mission/Mission.types";
import { GatewaySnapshot } from "../../gateway/Gateway.types";
import { Vehicle } from "../Vehicle.types";
import { isPointWithinRadius, getDistance } from "geolib";
import type { GeolibInputCoordinates } from "geolib/es/types";
import type { LatLngTuple } from "leaflet";
import { StagingRoom } from "../../staging/Staging.types";
import { AxiosInstance } from "axios";
import {
  assignVehicleToMission,
  assignVehicleToStagingRoom,
} from "../Vehicle.axios";
import { HistoryEntry } from "../../history/History.types";

const DEFAULT_MISSION_ASSIGNMENT_RADIUS: number = Number.parseInt(
  process.env.REACT_APP_MISSION_ASSIGMENT_RADIUS ?? "200"
);

const DEFAULT_STAGING_ROOM_ASSIGNMENT_RADIUS: number = Number.parseInt(
  process.env.DEFAULT_STAGING_ROOM_ASSIGNMENT_RADIUS ?? "200"
);

/**
 * Vehicle but with location
 */
type VehicleWithLocation = Vehicle & { location: LatLngTuple };

interface VehicleAssignmentCheckData {
  axios: AxiosInstance;
  mission: Mission;
  stagingRooms: StagingRoom[];
  gateways: GatewaySnapshot[];
  vehicles: Vehicle[];
}

/**
 * Check all vehicles for their assignment to the mission and staging rooms
 * and update the assignment if necessary.
 *
 * @param vehicleAssignmentCheckData The data to check the vehicle assignment
 * @returns The history entries for the vehicle assignment
 */
export const checkVehicleAssignment = async ({
  axios,
  mission,
  gateways,
  vehicles,
  stagingRooms,
}: VehicleAssignmentCheckData): Promise<HistoryEntry[]> => {
  // ----- Mission Assignment -----

  // all vehicles with their location
  const vehiclesWithLocation: VehicleWithLocation[] =
    convertVehiclesAndGatewaySnapshotsToVehicleWithLocation(vehicles, gateways);

  // all vehicles within the mission radius
  const vehiclesWithinMissionRadius: VehicleWithLocation[] =
    vehiclesWithLocation.filter((vehicle) =>
      isPointWithinRadius(
        convertLatLngTupleToGeolibInputCoordinates(vehicle.location),
        convertLatLngTupleToGeolibInputCoordinates(mission.latLon),
        mission.assignmentRadius ?? DEFAULT_MISSION_ASSIGNMENT_RADIUS
      )
    );

  // all vehicles outside the mission radius
  const vehiclesOutsideMissionRadius: VehicleWithLocation[] =
    vehiclesWithLocation.filter(
      (vehicle) =>
        !vehiclesWithinMissionRadius.find(
          (vehicleWithinMission) => vehicleWithinMission.uid === vehicle.uid
        )
    );

  const historyEntriesFromMissionAssignment: (HistoryEntry[] | undefined)[] =
    await Promise.all(
      vehiclesWithinMissionRadius.map((vehicleWithinMission) => {
        if (vehicleWithinMission.missionUid) {
          console.log(
            "Vehicle already assigned to mission",
            vehicleWithinMission.uid
          );
          return Promise.resolve(undefined);
        }
        console.log(
          "Assigning vehicle to mission",
          vehicleWithinMission.uid,
          mission.uid
        );
        return assignVehicleToMission(
          axios,
          vehicleWithinMission.uid,
          mission.uid
        );
      })
    );

  // ----- Staging Room Assignment -----

  const historyEntriesFromStagingAssignment: (HistoryEntry | undefined)[] =
    await Promise.all(
      vehiclesOutsideMissionRadius.map((vehicleOutsideMission) => {
        // skip if vehicle is on a mission
        if (vehicleOutsideMission.missionUid) return Promise.resolve(undefined);

        // vehicle location for geolib
        const vehicleLocation: GeolibInputCoordinates =
          convertLatLngTupleToGeolibInputCoordinates(
            vehicleOutsideMission.location
          );

        // all staging rooms the vehicle is within
        const matchedStagingRooms: StagingRoom[] = stagingRooms.filter(
          (stagingRoom) =>
            isPointWithinRadius(
              vehicleLocation,
              convertLatLngTupleToGeolibInputCoordinates([
                stagingRoom.lat,
                stagingRoom.lon,
              ]),
              stagingRoom.assignmentRadius ??
                DEFAULT_STAGING_ROOM_ASSIGNMENT_RADIUS
            )
        );

        // find the nearest staging room to the vehicle
        const nearestStagingRoom: StagingRoom | undefined =
          matchedStagingRooms.sort(
            (a, b) =>
              getDistance(
                vehicleLocation,
                convertLatLngTupleToGeolibInputCoordinates([b.lat, b.lon])
              ) -
              getDistance(
                vehicleLocation,
                convertLatLngTupleToGeolibInputCoordinates([a.lat, a.lon])
              )
          )[0];

        // skip if not within any staging room
        if (!nearestStagingRoom) return Promise.resolve(undefined);

        // assign vehicle to nearest staging room
        return assignVehicleToStagingRoom(
          axios,
          vehicleOutsideMission.uid,
          nearestStagingRoom.uid
        );
      })
    );

  return [
    ...historyEntriesFromMissionAssignment.flat(),
    ...historyEntriesFromStagingAssignment,
  ].filter(Boolean) as HistoryEntry[];
};

export const getAllMissionVehiclesOnApproach = (
  gateways: GatewaySnapshot[],
  vehicles: Vehicle[],
  mission: Mission
): { outsideMission: Vehicle[]; insideMission: Vehicle[] } => {
  // all vehicles with their location
  const vehiclesWithLocation: VehicleWithLocation[] =
    convertVehiclesAndGatewaySnapshotsToVehicleWithLocation(vehicles, gateways);

  // all vehicles within the mission radius
  const vehiclesWithinMissionRadius: VehicleWithLocation[] =
    vehiclesWithLocation.filter((vehicle) =>
      isPointWithinRadius(
        convertLatLngTupleToGeolibInputCoordinates(vehicle.location),
        convertLatLngTupleToGeolibInputCoordinates(mission.latLon),
        mission.assignmentRadius ?? DEFAULT_MISSION_ASSIGNMENT_RADIUS
      )
    );

  // all vehicles outside the mission radius
  const vehiclesOutsideMissionRadius: VehicleWithLocation[] =
    vehiclesWithLocation.filter(
      (vehicle) =>
        !vehiclesWithinMissionRadius.find(
          (vehicleWithinMission) => vehicleWithinMission.uid === vehicle.uid
        )
    );

  return {
    insideMission: vehiclesWithinMissionRadius,
    outsideMission: vehiclesOutsideMissionRadius,
  };
};

/**
 * Convert a list of {@link Vehicle} and {@link GatewaySnapshot} into a list of {@link VehicleWithLocation}
 *
 * @param vehicles The list of {@link Vehicle} to convert
 * @param gateways The list of {@link GatewaySnapshot} to convert
 * @returns {VehicleWithLocation[]} The converted list of {@link VehicleWithLocation}
 */
export const convertVehiclesAndGatewaySnapshotsToVehicleWithLocation = (
  vehicles: Vehicle[],
  gateways: GatewaySnapshot[]
): VehicleWithLocation[] =>
  vehicles
    .map((vehicle) => {
      const foundSnapshot: GatewaySnapshot | undefined = gateways.find(
        (gatewaySnapshot) => gatewaySnapshot.gatewayUid === vehicle.gatewayUid
      );
      if (!foundSnapshot || !foundSnapshot.lat || !foundSnapshot.lon)
        return undefined;

      const vehicleWithLocation: VehicleWithLocation = {
        ...vehicle,
        location: [foundSnapshot.lat, foundSnapshot.lon],
      };

      return vehicleWithLocation;
    })
    .filter(Boolean) as VehicleWithLocation[];

/**
 * Converts a `Leaflet` {@link LatLngTuple} into a `geolib` {@link GeolibInputCoordinates}
 *
 * @param {[number, number]} latLngTuple The `Leaflet` {@link LatLngTuple} to convert
 * @returns {GeolibInputCoordinates} The converted `geolib` {@link GeolibInputCoordinates}
 */
export const convertLatLngTupleToGeolibInputCoordinates = ([
  lat,
  lng,
]: LatLngTuple): GeolibInputCoordinates => ({ lat, lng });
