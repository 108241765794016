import { LatLngTuple } from "leaflet";
import { LassoHandler, LassoHandlerFinishedEvent } from "leaflet-lasso";
import { Map } from "leaflet";

/**
 * Default center of the map, when no other position could be loaded
 */
export const defaultMapCenter: LatLngTuple = [49.795178, 8.34557];

/**
 * A semantic group of MapItems belong on one MapLayer
 */
export enum MapLayer {
  GENERAL = "GENERAL",
  STAGING = "STAGING",
  HYDRANT = "HYDRANT",
  HINT = "HINT",
}

/**
 * Props for the Map Layer Component to be displayed on the map overlay
 */
export interface SingleMapLayerProps {
  layerName: string;
  layerIcon?: JSX.Element;
  visible: boolean;
  onClick?: (visible: boolean) => void;
  key: MapLayer;
}

/**
 * Item to display on Map
 */
export interface MapItem {
  uid: string;
  location: LatLngTuple;
  name: string;
  icon?: JSX.Element;
  color?: string;
  layer: MapLayer;
  onClick?: () => void;
  onDragEnd?: (location: LatLngTuple) => void;
  isGateway?: boolean;
}

/**
 * Section to display as an area on Map
 */
export interface MapSection {
  uid: string;
  name: string;
  coords: LatLngTuple[];
  color?: string;
  layer: MapLayer;
}

/**
 * props for map component to pass for display and manipulation of mapItems
 */
export interface MapComponentProps {
  center: LatLngTuple;
  mapItems?: MapItem[];
  mapSections?: MapSection[];
  defaultZoom?: number;
  visibleMapLayers?: MapLayer[];
  disableLasso?: boolean;
  setLassoHandler?(lassoHandler: LassoHandler): void;
  onSelectionFinished?(event: LassoHandlerFinishedEvent): void;
  setLeafletMap?(map: Map): void;
  closeContextOnDrag: () => void;
}

/**
 * Interface for a vehicle marker on the leaflet map
 */
export interface VehicleMarker {
  uid: string;
  location: LatLngTuple;
  name: string;
}
