import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useSyncExternalStore,
} from "react";
import { HistoryHandler } from "./History.handler";
import { useUser } from "../auth/UserContext";
import { useMission } from "../mission/Mission.context";
import { Mission } from "../mission/Mission.types";
import { GatewaySnapshot } from "../gateway/Gateway.types";
import { Hint } from "../hint/Hint.types";
import { HistoryEntry } from "./History.types";
import { useSocket } from "../socket/Socket.utils";
import { BornemannLiveData } from "../socket/Asset.types";

interface HistoryContextData {
  isShifted: boolean;
  mission?: Mission;
  gateways?: GatewaySnapshot[];
  hints?: Hint[];
  setShiftedTime: (shiftedTime?: Date) => void;
  appendHistoryEntries: (...entries: HistoryEntry[]) => void;
}

const HistoryContext = createContext<HistoryContextData>({
  isShifted: false,
  setShiftedTime: () => {},
  appendHistoryEntries: () => {},
});

/**
 * Shortcut for accessing the history context.
 *
 * @returns The history context.
 */
export const useHistory = () => useContext(HistoryContext);

export const HistoryProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { axios, user } = useUser();
  const { selectedMission } = useMission();
  const socket = useSocket();

  const onMissionChange = useRef<() => void>();
  const onGatewaysChange = useRef<() => void>();
  const onHintsChange = useRef<() => void>();

  const [isShifted, toggleShifted] = useState<boolean>(false);

  /**
   * Create and memorize a history handler
   */
  const historyHandler = useMemo<HistoryHandler>(
    () =>
      new HistoryHandler(
        selectedMission?.uid,
        selectedMission?.einsatzStart,
        axios,
        user
      ),
    [selectedMission?.uid, selectedMission?.einsatzStart, axios, user]
  );

  /**
   * Register History handler events
   */
  useEffect(() => {
    const handleMissionChange = () => onMissionChange.current?.();
    const handleGatewaysChange = () => onGatewaysChange.current?.();
    const handleHintsChange = () => onHintsChange.current?.();

    historyHandler.addEventListener("missionChanged", handleMissionChange);
    historyHandler.addEventListener("gatewaysChanged", handleGatewaysChange);
    historyHandler.addEventListener("hintsChanged", handleHintsChange);

    return () => {
      historyHandler.removeEventListener("missionChanged", handleMissionChange);
      historyHandler.removeEventListener(
        "gatewaysChanged",
        handleGatewaysChange
      );
      historyHandler.removeEventListener("hintsChanged", handleHintsChange);
    };
  }, [historyHandler]);

  /**
   * Register Socket handler events
   */
  useEffect(() => {
    let buffer: Promise<void> | undefined = undefined;
    const latestData: Map<string, BornemannLiveData> = new Map();

    /**
     * Handler for incoming asset data
     *
     * @param asset The incoming asset data
     */
    const onData = (asset: BornemannLiveData): void => {
      const current = latestData.get(asset.asset._id);
      if (!current) {
        latestData.set(asset.asset._id, asset);
        return;
      }

      if (new Date(asset.logLast.date) > new Date(current.logLast.date)) {
        latestData.set(asset.asset._id, asset);
      }

      if (!buffer) {
        buffer = new Promise((resolve) => setTimeout(resolve, 1000)).then(
          () => {
            historyHandler.appendGatewayData(
              ...Array.from(latestData.values())
            );
            latestData.clear();
            buffer = undefined;
          }
        );
      }
    };

    /**
     * Handler for emitting the subscription to the asset data
     */
    const emitSubscription = () => {
      socket.emit("asset:subscribe", "*");
    };

    socket.on("asset:data", onData);
    socket.on("connect", emitSubscription);

    emitSubscription();

    return () => {
      setTimeout(() => {
        socket.emit("asset:unsubscribe", "*");
        socket.off("asset:data", onData);
        socket.off("connect", emitSubscription);
      }, 3000);
    };
  }, [socket, historyHandler]);

  /**
   * State store for the mission
   */
  const mission = useSyncExternalStore(
    (onChange) => (onMissionChange.current = onChange),
    () => historyHandler.getMission()
  );

  /**
   * State store for the gateway snapshots
   */
  const gateways = useSyncExternalStore(
    (onChange) => (onGatewaysChange.current = onChange),
    () => historyHandler.getGateways()
  );

  /**
   * State store for the hints
   */
  const hints = useSyncExternalStore(
    (onChange) => (onHintsChange.current = onChange),
    () => historyHandler.getHints()
  );

  /**
   * Memorized function to set the shifted time
   */
  const setShiftedTime = useCallback(
    (shiftedTime?: Date) => {
      historyHandler.setShiftedTime(shiftedTime);
      toggleShifted(!!shiftedTime);
    },
    [historyHandler]
  );

  /**
   * Memorized function to append history entries
   */
  const appendHistoryEntries = useCallback(
    (...entries: HistoryEntry[]) => {
      historyHandler.appendHistoryEntries(...entries);
    },
    [historyHandler]
  );

  return (
    <HistoryContext.Provider
      value={{
        isShifted,
        mission,
        gateways,
        hints,
        setShiftedTime,
        appendHistoryEntries,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};
