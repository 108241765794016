import React, { useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LayoutWrapper from "./components/LayoutWrapper";
import PrivateLayoutWrapper from "./components/PrivateLayoutWrapper";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import TacticalOverview from "./pages/TacticalOverview";
import Map from "./pages/Map";
import UserAdministration from "./pages/user/UserAdministration";
import UserCreate from "./pages/user/UserCreate";
import UserEdit from "./pages/user/UserEdit";
import "./styles/index.scss";
import { UserProvider } from "./utils/auth/UserContext";
import { MissionProvider } from "./utils/mission/Mission.context";
import VehicleAdministration from "./pages/vehicle/VehicleAdministration";
import VehicleEdit from "./pages/vehicle/VehicleEdit";
import MissionOverview from "./pages/MissionOverview";
import EinheitAdministration from "./pages/einheit/EinheitAdministration";
import EinheitEdit from "./pages/einheit/EinheitEdit";
import StagingRoomAdministration from "./pages/staging/StagingRoomAdministration";
import StagingRoomEdit from "./pages/staging/StagingRoomEdit";
import StagingRoomCreate from "./pages/staging/StagingRoomCreate";
import HydrantAdministration from "./pages/hydrant/HydrantAdministration";
import HydrantCreate from "./pages/hydrant/HydrantCreate";
import HydrantEdit from "./pages/hydrant/HydrantEdit";
import { HistoryProvider } from "./utils/history/History.context";
import { EventProvider } from "./utils/event/Event.context";
import GenerateMission from "./components/GenerateMissionForm";

const App: React.FC = () => {
  /**
   * Routes that are only accessible when the user is not logged in
   */
  const unauthorizedRoutes = useMemo(
    () => (
      <Routes>
        <Route element={<LayoutWrapper />}>
          <Route path="*" element={<Login />} />
        </Route>
      </Routes>
    ),
    []
  );

  /**
   * Routes that are only accessible when the user is logged in
   */
  const authorizedRoutes = useMemo(
    () => (
      <Routes>
        <Route path="/" element={<PrivateLayoutWrapper />}>
          <Route index element={<Dashboard />} />
          <Route path="/user">
            <Route index element={<UserAdministration />} />
            <Route path="edit" element={<UserEdit />} />
            <Route path="create" element={<UserCreate />} />
          </Route>
          <Route path="/staging">
            <Route index element={<StagingRoomAdministration />} />
            <Route path="edit" element={<StagingRoomEdit />} />
            <Route path="create" element={<StagingRoomCreate />} />
          </Route>
          <Route path="/hydrant">
            <Route index element={<HydrantAdministration />} />
            <Route path="edit" element={<HydrantEdit />} />
            <Route path="create" element={<HydrantCreate />} />
          </Route>
          <Route path="/map" element={<Map />} />
          <Route path="/mission" element={<GenerateMission />} />
          <Route path="/overview" element={<MissionOverview />} />
          <Route path="/tactical" element={<TacticalOverview />} />
          <Route path="/vehicles">
            <Route index element={<VehicleAdministration />} />
            <Route path="edit" element={<VehicleEdit />} />
          </Route>
          <Route path="/einheiten">
            <Route index element={<EinheitAdministration />} />
            <Route path="edit" element={<EinheitEdit />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    ),
    []
  );

  return (
    <Router>
      <EventProvider>
        <UserProvider fallback={unauthorizedRoutes} loading={<>Loading...</>}>
          <MissionProvider>
            <HistoryProvider>{authorizedRoutes}</HistoryProvider>
          </MissionProvider>
        </UserProvider>
      </EventProvider>
    </Router>
  );
};

export default App;
