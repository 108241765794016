import dayjs from "dayjs";
import "dayjs/locale/de";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.locale("de");

export default dayjs;
