import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { Qualification, QualificationGroup } from "./Qualification.types";

const userServiceBaseUrl: string = process.env.REACT_APP_USER_SERVICE_URL || "";

/**
 * Fetches all qualifications
 * @param {AxiosInstance} axios - The axios instance to use for the request.
 * @returns {Promise<QualificationGroup[]>} - A Promise that resolves with the fetched Qualifications
 * @throws {Error} - Throws an error if the base URL is invalid or if the request fails.
 */
export const getQualifications = (
  axios: AxiosInstance
): Promise<Qualification[]> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${userServiceBaseUrl}/qualification/`)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error while fetching qualifications", err.message);
      return [];
    });
};

/**
 * Fetches all qualification groups
 * @param {AxiosInstance} axios - The axios instance to use for the request.
 * @returns {Promise<QualificationGroup[]>} - A Promise that resolves with the fetched QualificationGroups.
 * @throws {Error} - Throws an error if the base URL is invalid or if the request fails.
 */
export const getQualificationGroups = (
  axios: AxiosInstance
): Promise<QualificationGroup[]> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${userServiceBaseUrl}/qualification/group/`)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error while fetching qualification groups", err.message);
      return [];
    });
};

