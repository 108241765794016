import { Einheit } from "./Einheit.types";

/**
 * Create an empty Einheit object
 *
 * @param overwrite The values to overwrite
 * @returns The empty Einheit object
 */
export const createEmptyEinheit = (overwrite: Partial<Einheit>): Einheit => ({
  uid: "",
  name: "",
  displayName: "",
  description: "",
  users: [],
  customerUid: "",
  address: {
    street: "",
    number: "",
    additionalInfo: "",
    zip: "",
    city: "",
  },
  lat: 0,
  lon: 0,
  ...overwrite,
});
