import { ButtonComponent, PopupComponent } from "articon-component-library";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../utils/auth/UserContext";
import { endMission } from "../utils/mission/Mission.axios";
import { useMission } from "../utils/mission/Mission.context";
import { mutate } from "swr";
import { useNavigate } from "react-router-dom";

const MissionOverview: React.FC = () => {
  const { t } = useTranslation();
  const { axios } = useUser();
  const navigate = useNavigate();
  const { selectedMission } = useMission();
  const [isEndPopup, toggleEndPopup] = useState<boolean>(false);

  /**
   * Ends the selected mission
   */
  const handleMissionEnd = useCallback(async () => {
    if (!selectedMission) return;

    await endMission(axios, selectedMission.uid);
    await mutate("mission/customer/all");
    navigate("/");
  }, [axios, selectedMission, navigate]);

  return (
    <div>
      <ButtonComponent
        value={t("pages.missionOverview.endMission")}
        onClick={() => toggleEndPopup(true)}
      />
      <PopupComponent
        isOpen={isEndPopup}
        onClose={() => toggleEndPopup(false)}
        title={t("pages.missionOverview.endPopup.title")}
      >
        <ButtonComponent
          value={t("pages.missionOverview.endPopup.end")}
          onClick={() => handleMissionEnd()}
        />
        <ButtonComponent
          value={t("pages.missionOverview.endPopup.abort")}
          onClick={() => toggleEndPopup(false)}
        />
      </PopupComponent>
    </div>
  );
};

export default MissionOverview;
