import {
  ButtonComponent,
  DropdownComponent,
  InputComponent,
  PopupComponent,
} from "articon-component-library";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Vehicle } from "../../utils/vehicle/Vehicle.types";

export interface VehicleEditorProps {
  vehicle?: Vehicle;
  onClose?: () => void;
  onSave?: (vehicle: Vehicle) => void;
}

const VehicleEditor: React.FC<VehicleEditorProps> = ({
  vehicle: initVehicle,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation();
  const [vehicle] = useState<Vehicle | undefined>(initVehicle);

  // TODO add missing data

  if (!vehicle) return null;

  return (
    <PopupComponent isOpen onClose={() => onClose?.()}>
      <div className="vehicle-editor">
        <div className="vehicle-editor__header">
          <h2>{t("components.vehicleEditor.title")}</h2>
          <ButtonComponent
            value={t("components.vehicleEditor.cancel")}
            onClick={onClose}
          />
        </div>
        <hr />
        <div className="vehicle-editor_sub-header">
          <ButtonComponent value={vehicle.names[0]} />
        </div>
        <hr />
        <div className="vehicle-editor__content">
          <div>
            <div className="vehicle-editor__content__section">
              <h4>{t("components.vehicleEditor.section")}</h4>
              <DropdownComponent options={[]} />
            </div>
            <div>
              <h4>{t("components.vehicleEditor.crew")}</h4>
              <div className="vehicle-editor__content__crew">
                <InputComponent type="number" />
                <span>/</span>
                <InputComponent type="number" />
                <span>/</span>
                <InputComponent type="number" />
                <span>/</span>
                <InputComponent type="number" />
              </div>
            </div>
          </div>
          <div>
            <div>
              <h4>{t("components.vehicleEditor.fullfillments.agt")}</h4>
              <InputComponent type="number" />
            </div>
            <div>
              <h4>{t("components.vehicleEditor.fullfillments.csa")}</h4>
              <InputComponent type="number" />
            </div>
            <div>
              <h4>{t("components.vehicleEditor.fullfillments.ce")}</h4>
              <InputComponent type="number" />
            </div>
            <div>
              <h4>{t("components.vehicleEditor.fullfillments.bofue")}</h4>
              <InputComponent type="number" />
            </div>
            <div>
              <h4>{t("components.vehicleEditor.fullfillments.msf")}</h4>
              <InputComponent type="number" />
            </div>
            <div>
              <h4>{t("components.vehicleEditor.fullfillments.san")}</h4>
              <InputComponent type="number" />
            </div>
          </div>
        </div>
        <div className="vehicle-editor__footer">
          <ButtonComponent
            value={t("components.vehicleEditor.save")}
            onClick={() => onSave?.(vehicle)}
          />
        </div>
      </div>
    </PopupComponent>
  );
};

export default VehicleEditor;
