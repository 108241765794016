import i18n from "../../i18n";
import {
  Qualification,
  QualificationGroup,
  QualificationGroupWithQualifications,
} from "./Qualification.types";

/**
 * Util to create grouped checkbox qualifications
 * @param groups the qualification groups ;
 * @param qualifications  the qualifications
 * @returns  {QualificationGroupWithQualifications[]} - The grouped qualifications
 */
export const createGroupedQualifications = (
  groups: QualificationGroup[],
  qualifications: Qualification[]
): QualificationGroupWithQualifications[] => {
  const withoutGroup: QualificationGroupWithQualifications = {
    allowedAmount: 0,
    name: i18n.t("components.createUser.qualification.noGroup"),
    uid: "NO_GROUP",
    qualifications: qualifications.filter(
      (qualification) => !qualification.groupId
    ),
  };

  const groupedQualifications: QualificationGroupWithQualifications[] =
    groups.map((group) => {
      const groupQualifications = qualifications.filter(
        (qualification) => qualification.groupId === group.uid
      );
      return {
        ...group,
        qualifications: groupQualifications,
      };
    });

  if (withoutGroup.qualifications.length > 0)
    groupedQualifications.push(withoutGroup);

  return groupedQualifications;
};
