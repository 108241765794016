import { createContext, FC, ReactNode, useContext, useState } from "react";
import { Mission } from "./Mission.types";

/**
 * Data representing the selected mission context.
 */
interface MissionContextData {
  selectedMission: Mission | undefined;
  setSelectedMission: (mission: Mission | undefined) => void;
}

/**
 * The selected mission context.
 */
const MissionContext = createContext<MissionContextData>({
  selectedMission: undefined,
  setSelectedMission: () => {},
});

/**
 * The selected mission context provider.
 *
 * @param children The children to render.
 * @param defaultMission The default mission to use. (can be undefined)
 */
export const MissionProvider: FC<{
  children: ReactNode;
  defaultMission?: Mission;
}> = ({ children, defaultMission }) => {
  const [selectedMission, setSelectedMission] = useState<Mission | undefined>(
    defaultMission
  );

  return (
    <MissionContext.Provider
      value={{
        selectedMission,
        setSelectedMission,
      }}
    >
      {children}
    </MissionContext.Provider>
  );
};

export const useMission = () => useContext(MissionContext);
