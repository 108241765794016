import {
  LayoutComponent,
  MenuGroup,
  MenuItem,
  Option,
} from "articon-component-library";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../utils/FirebaseUtils";
import { buildMenu } from "../utils/Menu.utils";
import { useMission } from "../utils/mission/Mission.context";
import useSWR from "swr";
import { useUser } from "../utils/auth/UserContext";
import { getActiveMissions } from "../utils/mission/Mission.axios";
import { useTranslation } from "react-i18next";
import GenerateMission from "./GenerateMissionForm";

const PrivateLayoutWrapper: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const menuGroups = useMemo<MenuGroup[]>(buildMenu, []);
  const { selectedMission, setSelectedMission } = useMission();
  const [rightMenuOpened, toggleRightMenuOpened] = useState<boolean>(false);
  const { axios } = useUser();
  const { user } = useUser();
  const location = useLocation();

  /**
   * Find the active menu based on the current location
   */
  const activeItem = useMemo<MenuItem | undefined>(
    () =>
      menuGroups
        .flatMap((group) => group.items)
        .filter((item) => location?.pathname.startsWith(item.link ?? ""))
        .sort(
          (itemA, itemB) =>
            (itemB.link?.length ?? 0) - (itemA.link?.length ?? 0)
        )[0],
    [menuGroups, location]
  );

  /**
   * Fetch the active missions for the current users customer
   */
  const activeMissions = useSWR(
    ["mission/active", user.customerUid],
    ([, customerUid]) => getActiveMissions(axios, customerUid),
    {
      fallbackData: [],
    }
  );

  /**
   * As soon as the missions are loaded, select the first one as active
   */
  useEffect(() => {
    if (!activeMissions.data) return;

    if (activeMissions.data.length === 0) {
      setSelectedMission(undefined);
      return;
    }

    if (
      !selectedMission ||
      !activeMissions.data.find(
        (mission) => mission.uid === selectedMission.uid
      )
    ) {
      setSelectedMission(activeMissions.data[0]);
      return;
    }
  }, [activeMissions, setSelectedMission, selectedMission]);

  /**
   * When the selected mission changes, update the context
   *
   * @param missionUid the uid of the mission to select
   */
  const handleMissionChange = useCallback(
    (missionUid: string) => {
      const mission = activeMissions.data?.find(
        (mission) => mission.uid === missionUid
      );
      if (mission) setSelectedMission(mission);
    },
    [activeMissions, setSelectedMission]
  );

  /**
   * Build and memoize the mission options
   */
  const missionOptions = useMemo<Option[]>(
    () =>
      (activeMissions.data ?? []).map((mission) => ({
        label: `${mission.stichwort} (${mission.uid})`,
        value: mission.uid,
      })),
    [activeMissions]
  );

  return (
    <LayoutComponent
      menu={menuGroups}
      menuItem={activeItem}
      onMenuItemClick={(item) => (item.link ? navigate(item.link) : undefined)}
      onLogout={() => {
        logout();
        navigate("/login");
      }}
      rightContent={
        <GenerateMission onClose={() => toggleRightMenuOpened(false)} />
      }
      rightContentOpened={rightMenuOpened}
      onRightContentToggle={() => toggleRightMenuOpened(!rightMenuOpened)}
      onGenerateMission={() => toggleRightMenuOpened(true)}
      missionSwitcher={{
        activeMissions: missionOptions,
        selectedMission: selectedMission?.uid,
        onChange: handleMissionChange,
        label: t("components.layout.missionSwitcher.label"),
      }}
      translations={{
        close: t("buttons.close"),
        createMission: t("components.layout.createMission"),
      }}
    >
      <Outlet />
    </LayoutComponent>
  );
};

export default PrivateLayoutWrapper;
