import { Duration } from "dayjs/plugin/duration";

/**
 * Format a number to a string with commas (depending on locale)
 * with an optional prefix and suffix
 *
 * @param num number to format
 * @param options.suffix suffix to add to the end of the string
 * @param options.prefix prefix to add to the start of the string
 * @param options.falsyFallback value to return if num is falsy
 * @param options.numberFormat Intl.NumberFormat instance to use for formatting (default is `new Intl.NumberFormat()`)
 * @returns formatted string with optional prefix and suffix or falsyFallback if num is falsy
 * @tested
 */
export const formatNumberToString = <F>(
  num: number,
  options?: {
    suffix?: string;
    prefix?: string;
    falsyFallback?: F;
    numberFormat?: Intl.NumberFormat;
  }
): string | F => {
  if (!num && options?.falsyFallback !== undefined)
    return options.falsyFallback;

  const numberFormatter = options?.numberFormat ?? new Intl.NumberFormat();

  return `${options?.prefix ?? ""}${numberFormatter.format(num)}${
    options?.suffix ?? ""
  }`;
};

/**
 * Helper to format a duration to a string with hours and minutes.
 *
 * Example:
 *  - `01:30` for 1 hour and 30 minutes
 *  - `00:15` for 15 minutes
 *  - `00:00` for 0 minutes
 *  - `100:30` for 100 hours and 30 minutes
 *
 * @param duration The duration to format
 * @returns the given duration as a string with hours and minutes
 * @tested
 */
export const formatDurationToHoursAndMinutes = (duration: Duration): string => {
  const hours: number = Math.floor(duration.asHours());
  const minutes: number = duration.minutes();

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};
