import { Menu, Item, Separator, Submenu } from "react-contexify";

import "react-contexify/dist/ReactContexify.css";

export type ItemWithLabel = React.ComponentProps<typeof Item> & {
  label: string;
};

export interface ContextMenuProps {
  title: string;
  menuId: string;
  menuItems: ItemWithLabel[];
  subMenuItems?: ItemWithLabel[];
  subMenuTitle?: string;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  menuItems,
  subMenuItems,
  menuId,
  title,
  subMenuTitle = "",
}) => {
  return (
    <div>
      <Menu theme="light" id={menuId}>
        <p className="context-menu__header">{title}</p>

        {menuItems.map((item, index) => (
          <Item key={index} id={index.toString()} {...item}>
            {item.label}
          </Item>
        ))}

        {subMenuItems && subMenuItems.length > 0 && (
          <>
            <Separator />
            <Submenu label={subMenuTitle}>
              {menuItems.map((item, index) => (
                <Item id={index.toString()} {...item}>
                  {item.label}
                  {item.children}
                </Item>
              ))}

              {subMenuItems?.map((item, index) => (
                <Item key={index} id={index.toString()} {...item}>
                  {item.label}
                </Item>
              ))}
            </Submenu>
          </>
        )}
      </Menu>
    </div>
  );
};

export default ContextMenu;
