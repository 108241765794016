import { TableRow } from "articon-component-library";
import i18n from "../../i18n";
import { User, UserRank } from "./User.types";

/**
 * Helper to create TableRows for TableComponent from User objects
 *
 * @param users users to be displayed in table
 * @param onClickRow onClick function for row
 * @param onDeleteRow onDelete function for row
 * @returns TableRows for Users
 * @tested
 */
export const createTableRowsForUsers = (
  users: User[],
  onClickRow: (user: User) => void,
  onDeleteRow: (user: User) => void
): TableRow[] => {
  const userEntries: TableRow[] = [];
  users.forEach((user) => {
    const userRow: TableRow = createTableRowForUser(
      user,
      onClickRow,
      onDeleteRow
    );
    userEntries.push(userRow);
  });
  return userEntries;
};

/**
 * Helper to create TableRow from User object
 *
 * @param user user to get TableRow for
 * @param onClick onClick for row
 * @param onDelete delete function for row
 * @returns TableRow for User
 * @tested
 * @TODO properly test onClick and onDelete to be differentiated
 */
export const createTableRowForUser = (
  user: User,
  onClick: (user: User) => void,
  onDelete: (user: User) => void
): TableRow => {
  const userRow: TableRow = {
    value: [
      { value: user.firstname },
      { value: user.lastname },
      { value: i18n.t(`enums.userRole.${user.role}`) },
      { value: user.mail },
      {
        value: (
          <div
            onClick={(evt) => {
              evt.stopPropagation();
              onDelete(user);
            }}
          >
            X
          </div>
        ),
      },
    ],
    onClick: () => onClick(user),
    onClickMobile: () => onClick(user),
  };

  return userRow;
};

/**
 * Helper method to update users array after change
 *
 * @param users users array to update
 * @param user user to create/update/delete in users array
 * @param isDeletion wheter or not the performed action is a deletion
 * @returns updated version of given array
 * @tested
 */
export const updateUserInArray = (
  users: User[],
  user: User,
  isDeletion?: boolean
): User[] => {
  const userIndex: number = users.findIndex(
    (userToCompare) => user.uid === userToCompare.uid
  );
  const updatedArray: User[] = [...users];
  if (isDeletion && userIndex !== -1) updatedArray.splice(userIndex, 1);
  else {
    if (userIndex === -1) updatedArray.push(user);
    else updatedArray[userIndex] = user;
  }
  return updatedArray;
};

/**
 * Helper to sort the users into a map according to their rank
 *
 * @param users the users to sort
 * @returns a mapping of a rank to the user
 * @tested
 */
export const sortUserByRank = (users: User[]): Map<UserRank, User[]> => {
  const resultMap: Map<UserRank, User[]> = new Map();
  Object.values(UserRank).forEach((rank) => resultMap.set(rank, []));
  users.forEach((user) => resultMap.get(user.userRank)?.push(user));
  return resultMap;
};

/**
 * Helper to sort the users into a map according to their rank as amount
 *
 * @param users the users to sort
 * @returns a mapping of a rank to the amount of users
 * @tested
 */
export const sortUserAmountByRank = (users: User[]): Map<UserRank, number> => {
  const resultMap: Map<UserRank, number> = new Map();
  sortUserByRank(users).forEach((users, rank) =>
    resultMap.set(rank, users.length)
  );
  return resultMap;
};

/**
 * Create a string for the besatzung from the given user amounts
 *
 * @param userAmounts the user amounts to create the string from
 * @returns the besatzung string
 * @tested
 */
export const createBesatzungsString = (
  userAmounts: Map<UserRank, number>
): string => {
  const vfAmount: number = userAmounts.get(UserRank.VF) ?? 0;
  const zfAmount: number = userAmounts.get(UserRank.ZF) ?? 0;
  const gfAmount: number = userAmounts.get(UserRank.GF) ?? 0;
  const mannschaftAmount: number = userAmounts.get(UserRank.MANNSCHAFT) ?? 0;
  return `${vfAmount}/${zfAmount}/${gfAmount}/${mannschaftAmount} (${
    vfAmount + zfAmount + gfAmount + mannschaftAmount
  })`;
};
