import { FirebaseOptions, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, onMessage } from "firebase/messaging";
import { getStorage } from "firebase/storage";
import { messageHandler } from "./utils/Messaging.utils";
import {
  getFirebaseAuthMock,
  getFirebaseStorageMock,
  getFirestoreMock,
  getMessagingMock,
} from "./utils/tests/__mocks__/FirebaseMocks";

/**
 * This is the central configuration for the firebase init
 */
const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const isTestRun: boolean = process.env.NODE_ENV === "test";
// Initialize Firebase and params
const app = initializeApp(firebaseConfig);
const auth: Auth = isTestRun ? getFirebaseAuthMock() : getAuth(app);
const db = isTestRun ? getFirestoreMock(app) : getFirestore(app);
const storage = isTestRun ? getFirebaseStorageMock() : getStorage(app);
const messaging = isTestRun ? getMessagingMock(app) : getMessaging(app);
//register event for handling of message
onMessage(messaging, messageHandler);

export { auth, db, storage, messaging };
