import { generateEmptyAddress } from "../customer/Customer.types";
import { Mission } from "./Mission.types";

/**
 * creates new Mission with inital values
 *
 * @returns new Mission
 * @tested
 */
export const generateNewMission = (): Mission => ({
  uid: "",
  createDate: new Date(),
  lastUpdate: new Date(),
  einsatzNummer: "",
  alarmStufe: 0,
  stichwort: "",
  address: generateEmptyAddress(),
  latLon: [0, 0],
  alarmierungsZeit: 0,
  einsatzStart: new Date(),
  alarmierteEinheiten: [],
  fahrzeuge: [],
  fahrzeugeWithUsers: new Map<string, string[]>(),
  customerUid: "",
  sections: [],
});

/**
 * Updates Dates with current one for lastUpdate of mission, optionally createDate too
 *
 * @param mission mission to update
 * @param withCreateDate if true, createDate will also be set, else remains unchanged
 * @tested
 */
export const updateMissionDates = (
  mission: Mission,
  withCreateDate?: boolean
): void => {
  if (withCreateDate) mission.createDate = new Date();
  mission.lastUpdate = new Date();
};
