import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { userServiceBaseUrl } from "../user/User.axios";
import { Einheit } from "./Einheit.types";

/**
 * Fetches all Einheiten with given ids
 * @param axios  network instance
 * @param einheitenIds  ids of einheiten
 * @returns  list of einheiten, can be empty
 */
export const fetchEinheitenWithIds = (
  axios: AxiosInstance,
  einheitenIds: string[]
): Promise<Einheit[] | null> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve(null);
  if (einheitenIds.length === 0) return Promise.resolve([]);
  return axios
    .get(`${userServiceBaseUrl}/einheit/ids`, {
      params: { ids: einheitenIds },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error while loading einheiten", err.message);
      return null;
    });
};

/**
 * Get all Einheiten for a given customer
 *
 * @param axios The axios instance
 * @param customerUid The customer uid to get the einheiten for
 * @returns The list of einheiten or an empty list if no einheiten were found
 */
export const getEinheitenByCustomer = async (
  axios: AxiosInstance,
  customerUid: string
): Promise<Einheit[]> =>
  axios
    .get(`${userServiceBaseUrl}/einheit/customer`, {
      params: { customerUid },
    })
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error(`Unexpected status code: ${res.status}`);
    })
    .catch((err) => {
      console.error("Error while getting einheiten by customer: ", err);
      return Promise.reject(err);
    });

/**
 * Create a new Einheit in the database
 *
 * @param axios The axios instance
 * @param einheit The einheit to create
 * @returns The created einheit
 */
export const createEinheit = async (
  axios: AxiosInstance,
  einheit: Einheit
): Promise<Einheit> =>
  axios
    .post(`${userServiceBaseUrl}/einheit/`, einheit)
    .then((res) => {
      if (res.status === 201) return res.data;
      throw new Error(`Unexpected status code: ${res.status}`);
    })
    .catch((err) => {
      console.error("Error while deleting einheit: ", err);
      return Promise.reject(err);
    });

/**
 * Update an existing Einheit in the database
 *
 * @param axios The axios instance
 * @param einheit The einheit to update
 * @returns The updated einheit or null if the einheit was not found
 */
export const updateEinheit = async (
  axios: AxiosInstance,
  einheit: Einheit
): Promise<Einheit | null> =>
  axios
    .post(`${userServiceBaseUrl}/einheit/update/`, einheit)
    .then((res) => {
      if (res.status === 200) return res.data;
      if (res.status === 412) return null;
      throw new Error(`Unexpected status code: ${res.status}`);
    })
    .catch((err) => {
      console.error("Error while updating einheit: ", err);
      return Promise.reject(err);
    });

/**
 * Update many existing Einheiten in the database
 *
 * @param axios The axios instance
 * @param einheiten The einheiten to update
 * @returns true if the update was successful or null if none of the einheiten were found
 */
export const updateManyEinheiten = async (
  axios: AxiosInstance,
  einheiten: Einheit[]
): Promise<boolean | null> =>
  axios
    .post(`${userServiceBaseUrl}/einheit/update/many/`, einheiten)
    .then((res) => {
      if (res.status === 200) return true;
      if (res.status === 412) return null;
      throw new Error(`Unexpected status code: ${res.status}`);
    })
    .catch((err) => {
      console.error("Error while updating many einheiten: ", err);
      return Promise.reject(err);
    });

/**
 * Delete an existing Einheit in the database
 *
 * @param axios The axios instance
 * @param uid The uid of the einheit to delete
 */
export const deleteEinheit = async (
  axios: AxiosInstance,
  uid: string
): Promise<void> =>
  axios
    .post(`${userServiceBaseUrl}/einheit/delete/`, undefined, {
      params: { uid },
    })
    .then((res) => {
      if (res.status === 200) return;
      throw new Error(`Unexpected status code: ${res.status}`);
    })
    .catch((err) => {
      console.error("Error while deleting einheit: ", err);
      return Promise.reject(err);
    });
