import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { StagingRoom } from "./Staging.types";

const missionServiceBaseUrl: string =
  process.env.REACT_APP_MISSION_SERVICE_URL || "";

/**
 * API method to create new staging room on mission service
 * @param axios axios instance ;
 * @param stagingRoom  staging room to create
 * @returns
 */
export const createNewStagingRoom = async (
  axios: AxiosInstance,
  stagingRoom: StagingRoom
): Promise<string> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve("");
  return axios
    .post(`${missionServiceBaseUrl}/staging/`, stagingRoom)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Staging room couldn't be created", err);
      return "";
    });
};  

/**
 * API method to update staging room on mission service
 * @param axios  axios instance
 * @param updatedStagingRoom staging room to update
 * @returns  true if update was successful, false otherwise
 */
export const updateStagingRoom = async (
  axios: AxiosInstance,
  updatedStagingRoom: StagingRoom
): Promise<boolean> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve(false);
  return axios
    .post(`${missionServiceBaseUrl}/staging/update/`, updatedStagingRoom)
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Staging room couldn't be updated", err);
      return false;
    });
};

/**
 * API to delete staging room on mission service
 * @param axios  axios instance
 * @param roomUidToDelete  uid of staging room to delete
 * @returns  true if delete was successful, false otherwise
 */
export const deleteStagingRoom = async (
  axios: AxiosInstance,
  stagingRoomUid: string
): Promise<boolean> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve(false);
  return axios
    .get(`${missionServiceBaseUrl}/staging/delete/`, {
      params: { stagingRoomUid },
    })
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Staging room couldn't be deleted", err);
      return false;
    });
};

/**
 * API method to get all staging rooms from mission service
 * @param axios axios instance ;
 * @returns  The list of all staging rooms, can be empty
 */
export const getAllStagingRoomsForCustomer = async (
  axios: AxiosInstance,
  customerUid: string
): Promise<StagingRoom[]> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${missionServiceBaseUrl}/staging/customer/`, {
      params: { customerUid },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Staging rooms couldn't be loaded", err);
      return [];
    });
};
