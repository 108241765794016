import { AxiosInstance } from "axios";
import { useEvent } from "../../event/Event.context";
import { Mission } from "../../mission/Mission.types";
import { Vehicle } from "../Vehicle.types";
import { checkVehicleAssignment } from "./Assignment.utils";
import { StagingRoom } from "../../staging/Staging.types";
import { HistoryEntry } from "../../history/History.types";

interface UseVehicleAssignmentData {
  axios: AxiosInstance;
  mission?: Mission;
  vehicles?: Vehicle[];
  stagingRooms?: StagingRoom[];
  onVehicleAssigned: (historyEntries: HistoryEntry[]) => void;
}

/**
 * Hook to automatically check the vehicle assignment
 *
 * @param vehicleAssignmentData The data to check the vehicle assignment
 */
export const useVehicleAssignment = ({
  axios,
  mission,
  vehicles,
  stagingRooms,
  onVehicleAssigned,
}: UseVehicleAssignmentData) => {
  useEvent(
    "checkVehicleAssignments",
    async (gateways) => {
      if (!mission || !vehicles || !stagingRooms) return;

      const historyEntries = await checkVehicleAssignment({
        axios,
        mission,
        gateways,
        vehicles,
        stagingRooms,
      });

      onVehicleAssigned(historyEntries);
    },
    [axios, mission, vehicles, stagingRooms]
  );
};
