import { AxiosInstance } from "axios";
import { GatewaySnapshot } from "../gateway/Gateway.types";
import { Mission } from "../mission/Mission.types";
import { HistoryEntry } from "./History.types";
import { User } from "../user/User.types";
import { getGatewaySnapshots } from "../gateway/Gateway.axios";
import { historyDb } from "./History.dexie";
import { getMissionHistory } from "../mission/Mission.axios";
import { getHintHistory } from "../hint/Hint.axios";
import { applyPatch, deepClone } from "fast-json-patch";
import { Hint } from "../hint/Hint.types";
import { promised } from "../General.util";
import { isEqual } from "lodash";
import { BornemannLiveData } from "../socket/Asset.types";
import { BornemannGatewayLiveData } from "../socket/Asset.types";
import { eventManager } from "../event/Event.context";

/**
 * The HistoryHandler is responsible for handling the data at a shifted time of the given mission
 */
export class HistoryHandler extends EventTarget {
  private shiftedTime: Date | undefined;
  private startDate: Date | undefined;

  // local state of indexedDB to avoid async calls
  private gatewayHistory: GatewaySnapshot[] = [];
  private missionHistory: HistoryEntry[] = [];
  private hintsHistory: HistoryEntry[] = [];

  // current data for given shifted time
  private currentGateways: GatewaySnapshot[] = [];
  private currentMission: Mission | undefined;
  private currentHints: Hint[] = [];

  constructor(
    private missionUid: string | undefined,
    private missionStart: Date | undefined,
    private axios: AxiosInstance,
    private user: User
  ) {
    super();
    if (missionUid) {
      this.startDate = missionStart;
    }
    this.sync();
  }

  // ------------------------------

  /**
   * Set the time which the history handler should use to calculate the current state
   *
   * @param shiftedTime time to use
   */
  setShiftedTime(shiftedTime?: Date) {
    this.shiftedTime = shiftedTime;
    this.refresh();
  }

  /**
   * Get the current defined shifted time
   *
   * @returns shifted time or undefined if not set
   */
  getShiftedTime(): Date | undefined {
    return this.shiftedTime;
  }

  // ------------------------------

  /**
   * Append histroy entries to the history (indexedDB and local state)
   * History entries are created when the user edits a hint for example
   *
   * @param entries list of history entries to append
   */
  async appendHistoryEntries(...entries: HistoryEntry[]) {
    await Promise.all(
      entries.map((entry) =>
        promised(async () => {
          switch (entry.modelName) {
            case "mission": {
              await historyDb.mission.add(entry);
              return;
            }
            case "hint": {
              await historyDb.hints.add(entry);
              return;
            }
          }
        })
      )
    );
    await this.syncLocalState();
  }

  /**
   * Append bornemann live data to the history (indexedDB and local state)
   *
   * @param bornemannData list of bornemann live data to append
   */
  async appendGatewayData(...bornemannData: BornemannLiveData[]) {
    const newGatewaySnapshots: (GatewaySnapshot | undefined)[] =
      await Promise.all(
        bornemannData.map((rawLiveData) =>
          promised(async () => {
            const parsedLiveData =
              BornemannGatewayLiveData.safeParse(rawLiveData);
            if (!parsedLiveData.success) return;
            const liveData = parsedLiveData.data;
            if (!liveData.logLast.lonlat) return;

            const gatewaySnapshot: GatewaySnapshot = {
              gatewayUid: liveData.asset._id,
              timestamp: liveData.logLast.date,
              lat: liveData.logLast.lonlat[1],
              lon: liveData.logLast.lonlat[0],
            };

            await historyDb.gateways.add(gatewaySnapshot);

            return gatewaySnapshot;
          })
        )
      );
    await this.syncLocalState();

    // notify assignment handler to calculate possible new assignments
    eventManager.emit(
      "checkVehicleAssignments",
      newGatewaySnapshots.filter(Boolean) as GatewaySnapshot[]
    );
  }

  // ------------------------------

  /**
   * Refresh all internal states at once
   */
  refresh() {
    this.refreshGateways();
    this.refreshMission();
    this.refreshHints();
  }

  /**
   * Regenerate the gateway snapshots for the current shifted time
   */
  refreshGateways() {
    const relevantGatewaySnapshots: GatewaySnapshot[] =
      this.gatewayHistory.filter(
        (snapshot) => snapshot.timestamp <= (this.shiftedTime ?? new Date())
      );

    const lastestSnapshots: Map<string, GatewaySnapshot> = new Map<
      string,
      GatewaySnapshot
    >();

    for (const snapshot of relevantGatewaySnapshots) {
      const currentSnapshot: GatewaySnapshot | undefined = lastestSnapshots.get(
        snapshot.gatewayUid
      );
      if (currentSnapshot) {
        if (currentSnapshot.timestamp < snapshot.timestamp) {
          if (!snapshot.lat || !snapshot.lon) continue;
          lastestSnapshots.set(snapshot.gatewayUid, snapshot);
        }
      } else lastestSnapshots.set(snapshot.gatewayUid, snapshot);
    }

    const newGateways = Array.from(lastestSnapshots.values());
    if (isEqual(this.currentGateways, newGateways)) return;
    this.currentGateways = newGateways;
    this.dispatchEvent(new Event("gatewaysChanged"));
  }

  /**
   * Regenerate the mission for the current shifted time
   */
  refreshMission() {
    if (!this.missionUid) {
      this.currentMission = undefined;
      this.dispatchEvent(new Event("missionChanged"));
      return;
    }

    let newMission: Partial<Mission> = {};

    for (const entry of this.missionHistory) {
      if (+entry.timestamp > +(this.shiftedTime ?? new Date())) break;

      newMission = applyPatch(deepClone(newMission), entry.patch).newDocument;
    }

    if (isEqual(newMission, this.currentMission)) return;
    this.currentMission = newMission as Mission;
    this.dispatchEvent(new Event("missionChanged"));
  }

  /**
   * Regenerate the hints for the current shifted time
   */
  refreshHints() {
    if (!this.missionUid) {
      this.currentHints = [];
      this.dispatchEvent(new Event("hintsChanged"));
      return;
    }

    const hints = new Map<string, Partial<Hint>>();

    for (const entry of this.hintsHistory) {
      if (+entry.timestamp > +(this.shiftedTime ?? new Date())) break;
      const currentHint = hints.get(entry.modelUid) ?? {};

      const patchedHint = applyPatch(
        deepClone(currentHint),
        entry.patch
      ).newDocument;

      hints.set(entry.modelUid, patchedHint);
    }

    const newHints = Array.from<Hint>(hints.values() as Iterable<Hint>).filter(
      (hint: Hint) => hint.inactive !== true
    );
    if (isEqual(newHints, this.currentHints)) return;
    this.currentHints = newHints;
    this.dispatchEvent(new Event("hintsChanged"));
  }

  // ------------------------------

  /**
   * Get the current state of the gateways
   *
   * @returns List of gateway snapshots
   */
  getGateways(): GatewaySnapshot[] {
    return this.currentGateways;
  }

  /**
   * Get the latest gateway snapshots for each gateway (ignoring the shifted time!)
   *
   * @returns List of gateway snapshots
   */
  getLatestGateways(): GatewaySnapshot[] {
    const latestGateways: Map<string, GatewaySnapshot> = new Map<
      string,
      GatewaySnapshot
    >();

    for (const snapshot of this.gatewayHistory) {
      const currentSnapshot: GatewaySnapshot | undefined = latestGateways.get(
        snapshot.gatewayUid
      );
      if (currentSnapshot) {
        if (currentSnapshot.timestamp < snapshot.timestamp) {
          if (!snapshot.lat || !snapshot.lon) continue;
          latestGateways.set(snapshot.gatewayUid, snapshot);
        }
      } else latestGateways.set(snapshot.gatewayUid, snapshot);
    }

    return Array.from(latestGateways.values());
  }

  /**
   * Get the current state of the mission
   *
   * @returns The mission state or undefined if not set
   */
  getMission(): Mission | undefined {
    return this.currentMission;
  }

  /**
   * Get the current state of the hints
   *
   * @returns list of hints
   */
  getHints(): Hint[] {
    return this.currentHints;
  }

  // ------------------------------

  /**
   * Shortcut to fetch all states from the backend, insert it to the indexedDB and sync the local state
   *
   */
  async sync() {
    await Promise.all([
      this.fetchGatewayHistory(),
      this.fetchMissionHistory(),
      this.fetchHintHistory(),
    ]);
    await this.syncLocalState();

    // notify assignment handler to calculate possible new assignments
    eventManager.emit("checkVehicleAssignments", this.getLatestGateways());
  }

  /**
   * Fetch the gateway history from the server and store it in indexedDB
   *
   * @returns Promise to check if the fetching was successful
   */
  private async fetchGatewayHistory() {
    if (!this.startDate) return;
    const snapshots: GatewaySnapshot[] = await getGatewaySnapshots(
      this.axios,
      this.user.customerUid,
      this.startDate
    );

    // Skip if fetching failed
    if (snapshots.length === 0) return;

    await historyDb.gateways.clear();
    await historyDb.gateways.bulkPut(snapshots);
  }

  /**
   * Fetch the mission history from the server and store it in indexedDB
   *
   * @returns Promise to check if the fetching was successful
   */
  private async fetchMissionHistory() {
    if (!this.missionUid) return;
    const history: HistoryEntry[] = await getMissionHistory(
      this.axios,
      this.missionUid
    );

    await historyDb.mission.clear();
    await historyDb.mission.bulkPut(history, { allKeys: true });
  }

  /**
   * Fetch the hint history from the server and store it in indexedDB
   *
   * @returns Promise to check if the fetching was successful
   */
  private async fetchHintHistory() {
    if (!this.missionUid) return;
    const history: HistoryEntry[] = await getHintHistory(
      this.axios,
      this.missionUid
    );

    await historyDb.hints.clear();
    await historyDb.hints.bulkPut(history);
  }

  /**
   * Sync the local state with the indexedDB (load indexedDB data into local state)
   */
  private async syncLocalState() {
    const [gateways, mission, hints] = await Promise.all([
      historyDb.gateways.toArray(),
      historyDb.mission.toArray(),
      historyDb.hints.toArray(),
    ]);

    this.gatewayHistory = gateways;
    this.missionHistory = mission.sort((a, b) => +a.timestamp - +b.timestamp);
    this.hintsHistory = hints.sort((a, b) => +a.timestamp - +b.timestamp);
    this.refresh();
  }
}
