import {
  ButtonComponent,
  TableComponent,
  TableRow,
} from "articon-component-library";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { useUser } from "../../utils/auth/UserContext";
import { getAllGatewaysByCustomer } from "../../utils/gateway/Gateway.axios";
import { formatNumberToString } from "../../utils/general/String.utils";
import { getAllVehiclesByCustomer } from "../../utils/vehicle/Vehicle.axios";
import { Vehicle } from "../../utils/vehicle/Vehicle.types";
import { createNewEmptyVehicle } from "../../utils/vehicle/Vehicle.utils";

const VehicleAdministration: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, axios } = useUser();
  const vehicles = useSWR(
    ["mission/vehicle/customer", user.customerUid],
    ([, customerUid]) => getAllVehiclesByCustomer(axios, customerUid),
    {
      fallbackData: [],
    }
  );
  const gateways = useSWR(
    ["mission/vehicle/customer/all", user.customerUid],
    ([, customerUid]) => getAllGatewaysByCustomer(axios, customerUid),
    { fallbackData: [] }
  );

  /**
   * Navigate to edit page when table row is clicked
   */
  const handleEditClick = useCallback(
    (vehicle: Vehicle) => {
      navigate("edit", { state: { vehicle } });
    },
    [navigate]
  );

  /**
   * Memoize table header
   */
  const tableHeader = useMemo<TableRow>(
    () => ({
      value: [
        { value: t("pages.vehicleAdministration.tableHeader.name") },
        { value: t("pages.vehicleAdministration.tableHeader.type") },
        { value: t("pages.vehicleAdministration.tableHeader.unit") },
        { value: t("pages.vehicleAdministration.tableHeader.vehicleId") },
        { value: t("pages.vehicleAdministration.tableHeader.watertank") },
        {
          value: t("pages.vehicleAdministration.tableHeader.firefightingFoam"),
        },
        { value: t("pages.vehicleAdministration.tableHeader.seats") },
        { value: t("pages.vehicleAdministration.tableHeader.pa") },
        { value: t("pages.vehicleAdministration.tableHeader.csa") },
        { value: t("pages.vehicleAdministration.tableHeader.chainsaws") },
        { value: t("pages.vehicleAdministration.tableHeader.gateway") },
      ],
    }),
    [t]
  );

  /**
   * Memoize table entries
   */
  const tableEntries = useMemo<TableRow[]>(
    () =>
      vehicles.data.map((vehicle) => ({
        onClick: () => handleEditClick(vehicle),
        value: [
          { value: vehicle.names.join(" ") },
          { value: vehicle.vehicleType },
          { value: vehicle.unit },
          { value: "TODO" },
          {
            value: formatNumberToString(vehicle.watertankInLiters, {
              suffix: " l",
              falsyFallback: "—",
            }),
          },
          {
            value: formatNumberToString(vehicle.firefightingFoamInLiters, {
              suffix: " l",
              falsyFallback: "—",
            }),
          },
          { value: "TODO" },
          {
            value: formatNumberToString(vehicle.amountRespirators, {
              falsyFallback: "—",
            }),
          },
          {
            value: formatNumberToString(vehicle.amountChemicalProtectiveSuits, {
              falsyFallback: "—",
            }),
          },
          {
            value: formatNumberToString(vehicle.amountChainsaws, {
              falsyFallback: "—",
            }),
          },
          {
            value: gateways.data.find(
              (gateway) => gateway.uid === vehicle.gatewayUid
            )?.name,
          },
        ],
      })),
    [vehicles, handleEditClick, gateways]
  );

  return (
    <>
      <TableComponent tableHeader={tableHeader} tableEntries={tableEntries} />
      <ButtonComponent
        value={t("pages.vehicleAdministration.add")}
        onClick={() =>
          navigate("edit", {
            state: {
              vehicle: createNewEmptyVehicle({ customerUid: user.customerUid }),
            },
          })
        }
      />
    </>
  );
};

export default VehicleAdministration;
