import {
  ButtonComponent,
  TableComponent,
  TableRow,
} from "articon-component-library";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { useUser } from "../../utils/auth/UserContext";
import { formatNumberToString } from "../../utils/general/String.utils";
import { getEinheitenByCustomer } from "../../utils/einheit/Einheit.axios";
import { Einheit } from "../../utils/einheit/Einheit.types";
import { createEmptyEinheit } from "../../utils/einheit/Einheit.utils";

const EinheitAdministration: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, axios } = useUser();
  const einheiten = useSWR(
    ["mission/einheit/customer", user.customerUid],
    ([, customerUid]) => getEinheitenByCustomer(axios, customerUid),
    {
      fallbackData: [],
    }
  );

  /**
   * Navigate to edit page when table row is clicked
   */
  const handleEditClick = useCallback(
    (einheit: Einheit) => {
      navigate("edit", { state: { einheit } });
    },
    [navigate]
  );

  /**
   * Memoize table header
   */
  const tableHeader = useMemo<TableRow>(
    () => ({
      value: [
        { value: t("pages.einheitAdministration.tableHeader.name") },
        { value: t("pages.einheitAdministration.tableHeader.displayName") },
        { value: t("pages.einheitAdministration.tableHeader.userCount") },
      ],
    }),
    [t]
  );

  /**
   * Memoize table entries
   */
  const tableEntries = useMemo<TableRow[]>(
    () =>
      einheiten.data.map((einheit) => ({
        onClick: () => handleEditClick(einheit),
        value: [
          { value: einheit.name },
          { value: einheit.displayName },
          {
            value: formatNumberToString(einheit.users.length, {
              suffix: t(
                "pages.einheitAdministration.tableHeader.userCountSuffix"
              ),
            }),
          },
        ],
      })),
    [einheiten, handleEditClick, t]
  );

  return (
    <>
      <TableComponent tableHeader={tableHeader} tableEntries={tableEntries} />
      <ButtonComponent
        value={t("pages.einheitAdministration.add")}
        onClick={() =>
          navigate("edit", {
            state: {
              einheit: createEmptyEinheit({ customerUid: user.customerUid }),
            },
          })
        }
      />
    </>
  );
};

export default EinheitAdministration;
