import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { Hydrant } from "./Hydrant.types";

const missionServiceBaseUrl: string =
  process.env.REACT_APP_MISSION_SERVICE_URL || "";

/**
 * API method to create new hydrant on mission service
 * @param axios  axios instance
 * @param newHydrant  {@link Hydrant} to create
 * @returns  uid of created hydrant
 */
export const createNewHydrant = async (
  axios: AxiosInstance,
  newHydrant: Hydrant
): Promise<string> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve("");
  return axios
    .post(`${missionServiceBaseUrl}/hydrant/`, newHydrant)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Hydrant couldn't be created", err);
      return false;
    });
};

/**
 * API method to update hydrant on mission service
 * @param axios  axios instance
 * @param updatedHydrant hydrant to update
 * @returns  true if update was successful, false otherwise
 */
export const updateHydrant = async (
  axios: AxiosInstance,
  updatedHydrant: Hydrant
): Promise<boolean> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve(false);
  return axios
    .post(`${missionServiceBaseUrl}/hydrant/update/`, updatedHydrant)
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Hydrant couldn't be updated", err);
      return false;
    });
};

/**
 * API to delete hydrant on mission service
 * @param axios  axios instance
 * @param hydrantUid  uid of hydrant to delete
 * @returns  true if delete was successful, false otherwise
 */
export const deleteHydrant = async (
  axios: AxiosInstance,
  hydrantUid: string
): Promise<boolean> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve(false);
  return axios
    .get(`${missionServiceBaseUrl}/hydrant/delete/`, {
      params: { hydrantUid },
    })
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Hydrant couldn't be deleted", err);
      return false;
    });
};

/**
 * API method to get all hydrants from mission service
 * @param axios axios instance ;
 * @returns  The list of all hydrants, can be empty
 */
export const getAllHydranten = async (
  axios: AxiosInstance
): Promise<Hydrant[]> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${missionServiceBaseUrl}/hydrant/all/`)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Hydranten couldn't be loaded", err);
      return [];
    });
};
