import { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { auth } from "../../firebase";

// Initialize socket connection in import
const socket: Socket = io(
  process.env.REACT_APP_REALTIME_SERVICE_URL ?? "http://localhost:8080",
  {
    autoConnect: false,
  }
);

// A Set of active hooks to keep track if socket connection is still needed
const activeSocketHooks = new Set<Symbol>();

// socket info in console on development
if (process.env.NODE_ENV === "development") {
  socket.on("connect", () => {
    console.log(
      "%c 📡 Socket: %c CONNECTED ",
      "background: #406ace; color: white",
      "background: #0bc60f; color: black"
    );
  });
  socket.on("disconnect", () => {
    console.log(
      "%c 📡 Socket: %c DISCONNECTED ",
      "background: #406ace; color: white",
      "background: #ed5050; color: black"
    );
  });
  socket.on("error", console.error);
}

/**
 * Hook to get the socket connection
 *
 * @returns Socket connection
 */
export const useSocket = () => {
  /**
   * Connect socket on mount and disconnect on unmount (only if there are no more active hooks)
   */
  useEffect(() => {
    // this hookId is used to indicate this hook instance
    const hookId = Symbol();

    // add hookId to the set of active hooks
    activeSocketHooks.add(hookId);

    // connect socket if it is not connected yet
    if (!socket.connected) {
      socket.auth = (callback) => {
        auth.currentUser?.getIdToken().then((idToken) => callback({ idToken }));
      };
      socket.connect();
    }

    // this get called on unmount (when the hook is not been used anymore)
    return () => {
      // add a small delay to prevent disconnecting the socket on navigation between pages (other hook by not yet mounted)
      setTimeout(() => {
        // remove hookId from set of active hooks
        activeSocketHooks.delete(hookId);

        // disconnect socket if there are no more active hooks
        if (activeSocketHooks.size === 0) {
          socket.disconnect();
        }
      }, 4000);
    };
  }, []);

  return socket;
};

/**
 * Hook to get status of the socket connection
 *
 * @returns Object with connected status
 */
export const useSocketStatus = () => {
  const [connected, setConnected] = useState(socket.connected);

  /**
   * Subscribe to socket events on mount and unsubscribe on unmount
   */
  useEffect(() => {
    // handling socket events
    const onConnect = () => setConnected(true);
    const onDisconnect = () => setConnected(false);

    // subscribe socket events
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      // unsubscribe socket events
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  return { connected };
};
