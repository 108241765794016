import { LoaderComponent } from "articon-component-library";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateOrUpdateStagingRoom from "../../components/CreateOrUpdateStagingRoom";
import { StagingRoom } from "../../utils/staging/Staging.types";

const StagingRoomEdit: React.FC = () => {
  const navigate = useNavigate();
  const locationState = useLocation<{ selectedStagingRoom: StagingRoom }>();
  const selectedStagingRoom: StagingRoom | undefined = useMemo(
    () => locationState.state?.selectedStagingRoom,
    [locationState]
  );

  return selectedStagingRoom ? (
    <CreateOrUpdateStagingRoom
      stagingRoomToEdit={selectedStagingRoom}
      updateStagingRoomLocal={() => navigate(-1)}
    />
  ) : (
    <LoaderComponent />
  );
};

export default StagingRoomEdit;
