import { MenuGroup } from "articon-component-library";
import { ReactComponent as UserSettingsIcon } from "../assets/menu/user-settings.svg";
import { ReactComponent as MissionsIcon } from "../assets/menu/missions.svg";
import { ReactComponent as HydrantIcon } from "../assets/menu/hydrant.svg";
import { ReactComponent as GroupsIcon } from "../assets/menu/groups.svg";
import { ReactComponent as MapIcon } from "../assets/menu/map.svg";
import { ReactComponent as OverviewIcon } from "../assets/menu/overview.svg";
import { ReactComponent as Overview2Icon } from "../assets/menu/overview2.svg";
import { ReactComponent as WarehouseIcon } from "../assets/menu/warehouse.svg";
import { ReactComponent as VehicleIcon } from "../assets/menu/vehicle.svg";
import i18n from "../i18n";
import { v4 as uuid } from "uuid";

/**
 * Helper to build menu items for layout
 *
 * @returns menuItems for layout
 * @tested
 */
export const buildMenu = (): MenuGroup[] => [
  {
    id: uuid(),
    title: i18n.t("menu.missionAdministration"),
    items: [
      {
        id: uuid(),
        title: i18n.t("menu.dashboard"),
        icon: <Overview2Icon />,
        link: "/",
      },
      {
        id: uuid(),
        title: i18n.t("menu.map"),
        icon: <MapIcon />,
        link: "/map",
      },
      {
        id: uuid(),
        title: i18n.t("menu.tacticalOverview"),
        link: "/tactical",
        icon: <OverviewIcon />,
      },
      {
        id: uuid(),
        title: i18n.t("menu.missionOverview"),
        link: "/overview",
        icon: <Overview2Icon />,
      },
    ],
  },
  {
    id: uuid(),
    title: i18n.t("menu.history"),
    items: [
      {
        id: uuid(),
        title: i18n.t("menu.missions"),
        link: "/mission",
        icon: <MissionsIcon />,
      },
    ],
  },
  {
    id: uuid(),
    title: i18n.t("menu.administration"),
    items: [
      {
        id: uuid(),
        title: i18n.t("menu.userAdmin"),
        link: "/user",
        icon: <UserSettingsIcon />,
      },
      {
        id: uuid(),
        title: i18n.t("menu.einheitAdministration"),
        link: "/einheiten",
        icon: <GroupsIcon />,
      },
      {
        id: uuid(),
        title: i18n.t("menu.vehiclesAdministration"),
        link: "/vehicles",
        icon: <VehicleIcon />,
      },
      {
        id: uuid(),
        title: i18n.t("menu.stagingAdministration"),
        link: "/staging",
        icon: <WarehouseIcon />,
      },
      {
        id: uuid(),
        title: i18n.t("menu.hydrantAdministration"),
        link: "/hydrant",
        icon: <HydrantIcon />,
      },
    ],
  },
];
