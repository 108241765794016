import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  InputComponent,
  Option,
} from "articon-component-library";
import useSWR from "swr";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getAllCustomerBeacons,
  updateManyBeacons,
} from "../utils/beacon/Beacon.axios";
import { Beacon } from "../utils/beacon/Beacon.types";
import { createBeaconsDropdownOptions } from "../utils/beacon/Beacon.utils";
import { useUser } from "../utils/auth/UserContext";
import { createNewUser } from "../utils/user/AdminUser.utils";
import { updateUser } from "../utils/user/User.axios";
import {
  generateEmptyUser,
  NoMailUser,
  User,
  UserRank,
  UserRole,
} from "../utils/user/User.types";
import {
  getEinheitenByCustomer,
  updateManyEinheiten,
} from "../utils/einheit/Einheit.axios";
import {
  getQualifications,
  getQualificationGroups,
} from "../utils/qualifications/Qualification.axios";
import { createGroupedQualifications } from "../utils/qualifications/Qualification.utils";
import {
  Qualification,
  QualificationGroupWithQualifications,
} from "../utils/qualifications/Qualification.types";
import { Einheit } from "../utils/einheit/Einheit.types";

interface CreateOrUpdateUserProps {
  userToEdit?: User;
  updateUserLocal?(): void;
}

const CreateOrUpdateUser: React.FC<CreateOrUpdateUserProps> = ({
  userToEdit,
  updateUserLocal,
}) => {
  const { axios } = useUser();
  const { t } = useTranslation();
  const { user } = useUser();
  const [localUser, setUser] = useState<User | NoMailUser>(
    userToEdit || generateEmptyUser(user.customerUid)
  );
  const [customerBeacons, setCustomerBeacons] = useState<Beacon[]>([]);
  const [selectedBeaconIds, setSelectedBeaconIds] = useState<string[]>([]);
  const [beaconsOnLoadIds, setBeaconsOnLoadIds] = useState<string[]>([]);
  const [notAuthenticated, toggleAuthenticated] = useState<boolean>(
    !!localUser.uid && !localUser.mail
  );

  const einheiten = useSWR(
    ["mission/einheit/customer", user.customerUid],
    ([, customerUid]) => getEinheitenByCustomer(axios, customerUid),
    {
      fallbackData: [],
    }
  );

  const qualifications = useSWR(
    !!axios ? "/qualifications" : null,
    () => (!!axios ? getQualifications(axios) : []),
    { fallbackData: [] }
  );

  const qualificationGroups = useSWR(
    !!axios ? "/qualification/groups" : null,
    () => (!!axios ? getQualificationGroups(axios) : []),
    { fallbackData: [] }
  );

  const groupedQualifications: QualificationGroupWithQualifications[] = useMemo(
    () =>
      createGroupedQualifications(
        qualificationGroups.data,
        qualifications.data
      ),
    [qualifications.data, qualificationGroups.data]
  );

  /**
   * Get all beacons for the customer as soon as axios and user are available
   */
  useEffect(() => {
    if (!axios || !localUser || customerBeacons.length > 0) return;
    getAllCustomerBeacons(axios, localUser.customerUid).then((beacons) => {
      setCustomerBeacons(beacons);
      const assignedBeacons: string[] = beacons
        .filter(
          (beacon) => !!beacon.userUid && beacon.userUid === localUser.uid
        )
        .flatMap((beacon) => beacon.uid);
      setSelectedBeaconIds(assignedBeacons);
      setBeaconsOnLoadIds(assignedBeacons);
    });
  }, [axios, localUser, customerBeacons.length]);

  /**
   * use Memo to create drop down options for beacons
   */
  const beaconDropDownOptions: Option[] = useMemo(() => {
    return createBeaconsDropdownOptions(
      customerBeacons.filter(
        (beacon) =>
          beacon.userUid === localUser.uid ||
          !beacon.userUid ||
          beacon.userUid === ""
      )
    );
  }, [customerBeacons, localUser.uid]);

  const createUserHandler = (
    userPromise: Promise<string>,
    assignedBeacons: Beacon[],
    removedBeacons: Beacon[]
  ): void => {
    userPromise.then(async (userUid) => {
      assignedBeacons.forEach((beacon) => (beacon.userUid = userUid));
      await updateManyBeacons(axios, [...assignedBeacons, ...removedBeacons]);
      updateUserLocal?.();
      const einheitenToAddUidTo: Einheit[] = einheiten.data.filter((einheit) =>
        einheit.users.includes(localUser.uid)
      );
      if (einheitenToAddUidTo.length > 0)
        await updateManyEinheiten(
          axios,
          einheitenToAddUidTo.map((einheit) => ({
            ...einheit,
            users: [...einheit.users, userUid],
          }))
        );
      einheiten.mutate();
    });
  };

  /**
   * Helper to submit user after creation/change, will update when user has uid and create
   * a new one otherwise
   */
  const handleSubmit = (): void => {
    if (!axios) {
      console.error(
        "Axios is undefined, therefore creation can't be performed"
      );
      return;
    }
    const assignedBeacons: Beacon[] = customerBeacons
      .filter((beacon) => selectedBeaconIds.includes(beacon.uid))
      .map((beacon) => ({ ...beacon, userUid: localUser.uid }));

    const removedBeacons: Beacon[] = beaconsOnLoadIds
      .filter((beacon) => !selectedBeaconIds.includes(beacon))
      .map((beaconId) => {
        const beacon = customerBeacons.find(
          (beacon) => beacon.uid === beaconId
        )!;
        return { ...beacon, userUid: "" } as Beacon;
      });
    if (localUser.uid) {
      Promise.all([
        updateUser(axios, { ...localUser, notAuthenticated }),
        updateManyBeacons(axios, [...assignedBeacons, ...removedBeacons]),
        einheiten.data.length > 0 && updateManyEinheiten(axios, einheiten.data),
      ]).then(() => {
        updateUserLocal?.();
        einheiten.mutate();
      });
    } else {
      createUserHandler(
        createNewUser(axios, {
          ...localUser,
          customerUid: localUser!.customerUid,
          serviceUser: localUser.serviceUser || false,
          notAuthenticated,
        }),
        assignedBeacons,
        removedBeacons
      );
    }
  };

  /**
   * Helper to handle the change of Account type
   * @param value the value of the account type selected by the checkbox
   * @param type the type of the account type selected by the checkbox
   */
  const handleAccountTypeChange = (
    value: boolean,
    type: "isService" | "noLogin"
  ): void => {
    if (type === "isService") {
      toggleAuthenticated(false);
      setUser((user) => ({
        ...user,
        birthdate: undefined,
        serviceUser: value,
      }));
      setSelectedBeaconIds([]);
      einheiten.mutate();
    } else {
      toggleAuthenticated(value);
      setUser((user) => ({
        ...user,
        birthdate: undefined,
        serviceUser: false,
      }));
      setSelectedBeaconIds([]);
    }
  };
  /**
   * Handles the selection of qualifications
   * @param checked if the qualification is checked or not
   * @param groupId the group id of the qualification
   * @param qualification the qualification itself
   */
  const handleLimitOfQualifications = (
    checked: boolean,
    groupId: string,
    qualification: Qualification
  ): void => {
    setUser((user) => {
      if (!checked)
        return {
          ...user,
          qualifications: user.qualifications.filter(
            (userQualification) => userQualification.uid !== qualification.uid
          ),
        };
      else if (
        user.qualifications.some(
          (userQualification) =>
            userQualification.uid !== qualification.uid &&
            userQualification.groupId === groupId
        )
      )
        return {
          ...user,
          qualifications: user.qualifications
            .filter(
              (userQualification) =>
                userQualification.uid !== qualification.uid &&
                userQualification.groupId !== groupId
            )
            .concat({
              ...qualification,
              expired: false,
            }),
        };
      return {
        ...user,
        qualifications: user.qualifications.concat({
          ...qualification,
          expired: false,
        }),
      };
    });
  };

  /**
   * Creates the checkboxes for the qualifications
   * @returns {JSX.Element[]}
   */
  const createQualificationCheckboxes = (): JSX.Element[] => {
    return groupedQualifications
      .filter((group) => group.qualifications.length > 0)
      .map((group) => (
        <div key={group.uid}>
          <p>{group.name}</p>
          <div className="single-qualification">
            {group.qualifications.map((qualification) => {
              const checked: boolean = localUser.qualifications.some(
                (userQualification) =>
                  userQualification.uid === qualification.uid
              );
              const expired: boolean = localUser.qualifications.some(
                (userQualification) =>
                  userQualification.uid === qualification.uid &&
                  userQualification.expired
              );
              return (
                <>
                  <CheckboxComponent
                    key={qualification.uid}
                    value={`${qualification.name} ${
                      expired
                        ? t("components.createUser.qualification.expired")
                        : ""
                    }`}
                    checked={checked}
                    onCheck={(checked) =>
                      handleLimitOfQualifications(
                        checked,
                        group.uid,
                        qualification
                      )
                    }
                  />
                  {checked && (
                    <InputComponent
                      type="date"
                      value={
                        localUser.qualifications.find(
                          (userQualification) =>
                            userQualification.uid === qualification.uid
                        )?.expirationDate
                      }
                      minDate={new Date()}
                      onChangeDate={(expirationDate) =>
                        setUser((user) => ({
                          ...user,
                          qualifications: user.qualifications.map(
                            (userQualification) =>
                              userQualification.uid === qualification.uid
                                ? {
                                    ...userQualification,
                                    expirationDate,
                                  }
                                : userQualification
                          ),
                        }))
                      }
                      label={t(
                        "components.createUser.qualification.expirationDate"
                      )}
                    />
                  )}
                </>
              );
            })}
          </div>
        </div>
      ));
  };

  /**
   * Renders the elements for a service account
   * @returns {JSX.Element}
   */
  const renderServiceAccountElements = (): JSX.Element => (
    <>
      <InputComponent
        required
        label={t("components.createUser.email")}
        name="email"
        type="email"
        value={localUser.mail}
        onChange={(value) => setUser((user) => ({ ...user, mail: value }))}
      />
      <InputComponent
        required
        label={t("components.createUser.serviceName")}
        name="fname"
        value={localUser.firstname}
        onChange={(value) => setUser((user) => ({ ...user, firstname: value }))}
      />
      <DropdownComponent
        required
        label={t("components.createUser.role")}
        selectedOption={localUser.role}
        options={Object.values(UserRole).map((role) => ({
          label: t(`enums.userRole.${role}`),
          value: role,
        }))}
        onChange={(value) =>
          setUser((user) => ({ ...user, role: value as UserRole }))
        }
      />
    </>
  );

  /**
   * Renders the default elements for a user account
   * @param hasNoLogin  if the user has no login
   * @returns  {JSX.Element}
   */
  const renderDefaultElements = (hasNoLogin: boolean): JSX.Element => (
    <>
      {!hasNoLogin && (
        <InputComponent
          required
          label={t("components.createUser.email")}
          name="email"
          type="email"
          value={localUser.mail}
          onChange={(value) => setUser((user) => ({ ...user, mail: value }))}
        />
      )}
      <InputComponent
        required
        label={t("components.createUser.firstname")}
        name="fname"
        value={localUser.firstname}
        onChange={(value) => setUser((user) => ({ ...user, firstname: value }))}
      />
      <InputComponent
        required
        label={t("components.createUser.lastname")}
        name="lname"
        value={localUser.lastname}
        onChange={(value) => setUser((user) => ({ ...user, lastname: value }))}
      />
      <DropdownComponent
        required
        label={t("components.createUser.role")}
        selectedOption={localUser.role}
        options={Object.values(UserRole).map((role) => ({
          label: t(`enums.userRole.${role}`),
          value: role,
        }))}
        onChange={(value) =>
          setUser((user) => ({ ...user, role: value as UserRole }))
        }
      />
      <InputComponent
        label={t("components.createUser.birthdate")}
        name="bday"
        type="date"
        value={localUser.birthdate}
        onChangeDate={(birthdate) =>
          setUser((user) => ({ ...user, birthdate }))
        }
      />

      <DropdownComponent
        label={t("components.createUser.einheiten")}
        allowMultiSelect
        selectedOptions={einheiten.data
          .filter((einheit) => einheit.users.includes(localUser.uid))
          .flatMap((einheit) => einheit.uid)}
        options={einheiten.data.map((einheit) => ({
          value: einheit.uid,
          label: einheit.displayName,
        }))}
        onChangeMultiple={(einheitenUids) =>
          einheiten.mutate(
            einheiten.data.map((einheit) =>
              einheitenUids.includes(einheit.uid)
                ? { ...einheit, users: [...einheit.users, localUser.uid] }
                : {
                    ...einheit,
                    users: einheit.users.filter(
                      (user) => user !== localUser.uid
                    ),
                  }
            ),
            {
              revalidate: false,
            }
          )
        }
      />
      <DropdownComponent
        required
        label={t("components.createUser.rank")}
        selectedOption={localUser.userRank}
        options={Object.values(UserRank).map((rank) => ({
          label: t(`enums.userRank.${rank}`),
          value: rank,
        }))}
        onChange={(value) =>
          setUser((user) => ({ ...user, userRank: value as UserRank }))
        }
      />
      <DropdownComponent
        label={t("components.createUser.beacons")}
        options={beaconDropDownOptions}
        allowMultiSelect
        onChangeMultiple={setSelectedBeaconIds}
        selectedOptions={selectedBeaconIds}
      />
      <div>
        <h3>{t("components.createUser.qualificationHeader")}</h3>
        <div className="create-user--qualifications-container">
          {createQualificationCheckboxes()}
        </div>
      </div>
    </>
  );

  return (
    <form
      id="create-user"
      onSubmit={(evt) => {
        evt.preventDefault();
        handleSubmit();
      }}
    >
      {!localUser.uid && (
        <div className="create-user__account-type-container">
          <CheckboxComponent
            value={t("components.createUser.serviceAccount")}
            checked={!!localUser.serviceUser}
            onCheck={(value) => handleAccountTypeChange(value, "isService")}
          />
          <CheckboxComponent
            value={t("components.createUser.noLogin")}
            checked={notAuthenticated}
            onCheck={(value) => handleAccountTypeChange(value, "noLogin")}
          />
        </div>
      )}
      {localUser.serviceUser
        ? renderServiceAccountElements()
        : renderDefaultElements(notAuthenticated)}

      <ButtonComponent
        value={t(`buttons.${localUser.uid ? "save" : "create"}`)}
        form="create-user"
        type="submit"
      />
    </form>
  );
};
export default CreateOrUpdateUser;
