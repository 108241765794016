import { LatLng, LatLngTuple, Map as LeafletMap, Point } from "leaflet";
import { useContextMenu } from "react-contexify";
import useSWR from "swr";
import { LassoHandler } from "leaflet-lasso";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "../../utils/auth/UserContext";
import { getCustomer } from "../../utils/customer/Customer.axios";
import {
  defaultMapCenter,
  MapItem,
  MapLayer,
  MapSection,
  SingleMapLayerProps,
  VehicleMarker,
} from "../../utils/map/Map.types";
import {
  createAssetLiveMapItems,
  createHintMapItems,
  createHydrantenMapItems,
  createStagingRoomMapItems,
  generateNewMapItem,
  getCoordinatesForAddress,
} from "../../utils/map/Map.utils";
import MapComponent from "../custommap/MapComponent";
import "./MapComponentStyles.scss";
import { ReactComponent as DrehleiterIcon } from "../../assets/Drehleiter_mit_Korb.svg";
import { useMission } from "../../utils/mission/Mission.context";
import { ReactComponent as FireMarker } from "../../assets/fire-marker.svg";
import { ReactComponent as BereitstellungsIcon } from "../../assets/map/STAGING_on.svg";
import { ReactComponent as HydrantenIcon } from "../../assets/map/HYDRANT_on.svg";
import { ReactComponent as PlaceholderIcon } from "../../assets/map/placeholder_taktisch.svg";
import { ReactComponent as EyeIcon } from "../../assets/map/eye.svg";
import MapLayerBar from "./mapoverlays/MapLayerBar";
import { useTranslation } from "react-i18next";
import { getAllStagingRoomsForCustomer } from "../../utils/staging/Staging.axios";
import { getAllHydranten } from "../../utils/hydranten/Hydrant.axios";
import MapOverlayControls from "./mapoverlays/MapOverlayControls";
import ContextMenu, { ItemWithLabel } from "../ContextMenu";
import { Hint } from "../../utils/hint/Hint.types";
import { createHint, updateHint } from "../../utils/hint/Hint.axios";
import MapHintForm from "./mapoverlays/MapHintForm";
import { useLocation } from "react-router-dom";
import { createEmptyHint } from "../../utils/hint/Hint.utils";
import { getAllVehiclesByCustomer } from "../../utils/vehicle/Vehicle.axios";
import { getVehicleIconUrl } from "../../utils/vehicle/Vehicle.firebase";
import { Vehicle } from "../../utils/vehicle/Vehicle.types";
import MapLeftMenu from "./mapoverlays/MapLeftMenu";
import { useHistory } from "../../utils/history/History.context";
import { HistoryEntry } from "../../utils/history/History.types";
import { useVehicleAssignment } from "../../utils/vehicle/assignment/Assignment.hook";

const MapOverlay: React.FC = () => {
  const { selectedMission } = useMission();
  const { user, axios } = useUser();
  const { t } = useTranslation();
  const [generalItems, setGeneralItems] = useState<MapItem[]>([]);
  const [missionItems, setMissionItems] = useState<MapItem[]>([]);
  const [sections, setSections] = useState<MapSection[]>([]);
  const [leafletMap, setLeafletMap] = useState<LeafletMap>();
  const [lassoHandler, setLassoHandler] = useState<LassoHandler>();
  const [mapCenter, setMapCenter] = useState<LatLngTuple>(
    selectedMission?.latLon ?? defaultMapCenter
  );
  const [mapLayersVisibility, setMapLayersVisibility] = useState<
    Partial<Record<MapLayer, boolean>>
  >({ [MapLayer.GENERAL]: true, [MapLayer.STAGING]: false });
  const [mapLayerBarVisible, toggleMapLayerBarVisibility] =
    useState<boolean>(false);
  const location = useLocation<{ selectedGatewayUid: string }>();
  const selectedGatewayUid: string = location.state?.selectedGatewayUid || "";
  const [contextPosition, setContextPosition] = useState<LatLngTuple>([0, 0]);
  const [selectedHint, setSelectedHint] = useState<Hint>();
  const { hints, mission, gateways, appendHistoryEntries, isShifted } =
    useHistory();

  /**
   * memoized list of visible map layers
   */
  const visibileMapLayers: MapLayer[] = useMemo(
    () =>
      Array.from(Object.entries(mapLayersVisibility))
        .filter((layer) => layer[1])
        .flatMap((layer) => layer[0]) as MapLayer[],
    [mapLayersVisibility]
  );

  const vehicles = useSWR(
    !!axios && user.customerUid && (gateways?.length ?? 0)
      ? ["mission/vehicle/customer", user.customerUid]
      : null,
    ([, customerUid]) => getAllVehiclesByCustomer(axios, customerUid),
    {
      fallbackData: [],
    }
  );

  const stagingRooms = useSWR(
    !!axios && user ? ["staging/customer", user.customerUid] : null,
    ([, customerUid]) =>
      axios ? getAllStagingRoomsForCustomer(axios, customerUid) : [],

    { fallbackData: [] }
  );

  const hydranten = useSWR(
    visibileMapLayers.includes(MapLayer.HYDRANT) && !!axios
      ? "hydranten/all"
      : null,
    () => (axios ? getAllHydranten(axios) : []),
    { fallbackData: [] }
  );

  const customer = useSWR(
    ["user/customer", user.customerUid],
    ([, customerUid]) => getCustomer(axios, customerUid)
  );

  const customerLocation = useSWR(
    customer.data?.address
      ? ["user/customer/location", customer.data?.address]
      : null,
    ([, address]) => getCoordinatesForAddress(axios, address, defaultMapCenter)
  );

  /**
   * Handler for automatic vehicle assignments on map
   */
  useVehicleAssignment({
    axios,
    mission: selectedMission,
    stagingRooms: stagingRooms.data,
    vehicles: vehicles.data,
    async onVehicleAssigned(historyEntries) {
      await Promise.all([vehicles.mutate(), stagingRooms.mutate()]);
      appendHistoryEntries(...historyEntries);
    },
  });

  /**
   * A effect which setups all necessary map items for the customer
   * Setup things which are related to the customer like the customer location
   */
  useEffect(() => {
    if (!customer.data || !customerLocation.data || !leafletMap) return;
    const mapItem: MapItem = generateNewMapItem({
      location: customerLocation.data,
      name: customer.data.name,
    });
    setMapCenter(customerLocation.data);
    setGeneralItems([mapItem]);
  }, [customer.data, customerLocation.data, leafletMap]);

  /**
   * A effect which setups all necessary map items for the normal mission (not shifted)
   * Setup only things which are time independent like the mission location
   */
  useEffect(() => {
    if (!selectedMission?.latLon || !selectedMission?.stichwort) return;
    const mapItem: MapItem = generateNewMapItem({
      location: selectedMission.latLon,
      name: selectedMission.stichwort,
      icon: <FireMarker />,
      color: "red",
    });
    setMissionItems([mapItem]);
  }, [selectedMission?.latLon, selectedMission?.stichwort]);

  /**
   * A effect which setups all necessary map items for the shifted mission
   * Setup only things which are time dependent like the sections
   */
  useEffect(() => {
    if (!mission?.sections) return;
    setSections(mission.sections);
  }, [mission?.sections]);

  /**
   * When vehicles are loaded, fetch the icons for each vehicle
   */
  useEffect(() => {
    if (!vehicles.data || vehicles.data.length === 0) return;
    const fetchIcons = async () => {
      const vehiclesWithIcons: Vehicle[] = await Promise.all(
        vehicles.data.map(async (vehicle) => {
          return {
            ...vehicle,
            iconUrl: await getVehicleIconUrl(vehicle.blueprint?.uid || ""),
          };
        })
      );
      vehicles.mutate(vehiclesWithIcons, { revalidate: false });
    };
    fetchIcons();
  }, [vehicles]);

  /**
   * A list of all gateway with as map item live or shifted based on shiftedTime
   *
   * @returns {MapItem[]} list of all gateway as map items
   */
  const liveItems = useMemo<MapItem[]>(
    () =>
      createAssetLiveMapItems(
        (gateways
          ?.map((snapshot): VehicleMarker | undefined =>
            snapshot.lat && snapshot.lon
              ? {
                  uid: snapshot.gatewayUid,
                  location: [snapshot.lon, snapshot.lat],
                  name: snapshot.gatewayUid,
                }
              : undefined
          )
          .filter(Boolean) as VehicleMarker[]) ?? [],
        vehicles.data,
        <DrehleiterIcon height={60} width={60} />
      ),
    [gateways, vehicles.data]
  );

  /**
   * Memorize the selected gateway location (can be undefined if no gateway was selected or not found)
   */
  const selectedGatewayLocation = useMemo<LatLngTuple | undefined>(() => {
    return liveItems.find((item) => item.uid === selectedGatewayUid)?.location;
  }, [liveItems, selectedGatewayUid]);

  /**
   * A effect which is triggered when the selected mission location changes or a gateway was selected
   * The map will center to the new location
   */
  useEffect(() => {
    if (!customer.data || !customerLocation.data || !leafletMap) return;

    if (selectedGatewayLocation) {
      leafletMap.flyTo(selectedGatewayLocation, 18, { animate: false });
      return;
    }

    if (selectedMission?.latLon) {
      leafletMap.flyTo(selectedMission.latLon, 14);
    } else {
      leafletMap.flyTo(customerLocation.data, 14);
    }
  }, [
    selectedMission?.latLon,
    leafletMap,
    customer.data,
    customerLocation.data,
    selectedGatewayLocation,
  ]);

  /**
   * Memorized the staging rooms data on the map
   */
  const stagingItems = useMemo<MapItem[]>(
    () =>
      createStagingRoomMapItems(
        stagingRooms.data,
        <BereitstellungsIcon height={24} width={24} />
      ),
    [stagingRooms.data]
  );

  /**
   * Memorized the hydranten data on the map
   */
  const hydrantenItems = useMemo<MapItem[]>(
    () =>
      createHydrantenMapItems(
        hydranten.data,
        <HydrantenIcon height={24} width={24} />
      ),
    [hydranten.data]
  );

  /**
   * Create or update a hint on the backend and mutate local state
   */
  const submitHint = useCallback(
    async (hint: Hint) => {
      if (!leafletMap || !selectedMission || isShifted) return;
      if (hint.uid) {
        const historyEntry: HistoryEntry = await updateHint(axios, hint);
        appendHistoryEntries(historyEntry);
      } else {
        const newHint: Hint = {
          ...hint,
          missionUid: selectedMission.uid,
        };
        const historyEntry: HistoryEntry = await createHint(axios, newHint);
        appendHistoryEntries(historyEntry);
      }
      setMapLayersVisibility((layers) => ({ ...layers, HINT: true }));
    },
    [axios, leafletMap, selectedMission, appendHistoryEntries, isShifted]
  );

  /**
   * Memorized the mission hints data on the map
   */
  const missionHintItems = useMemo<MapItem[]>(
    () =>
      createHintMapItems(
        hints ?? [],
        <PlaceholderIcon height={40} width={40} />,
        (hint) => {
          setSelectedHint(hint);
          toggleMapLayerBarVisibility(false);
        },
        async (hint, newPosition) => {
          const updatedHint: Hint = {
            ...hint,
            coords: newPosition,
          };
          await submitHint(updatedHint);
        }
      ),

    [hints, submitHint]
  );

  const { show, hideAll } = useContextMenu({
    id: "map-context-menu",
  });

  /**
   * Util to open the context menu and set the position
   * @param event  the event which triggered the context menu
   */
  const handleContextMenu = (event: React.MouseEvent): void => {
    event.preventDefault();
    setContextPosition([event.nativeEvent.offsetX, event.nativeEvent.offsetY]);
    show({
      event,
      position: {
        x: event.nativeEvent.offsetX + 10,
        y: event.nativeEvent.offsetY + 10,
      },
    });
  };

  const menuItems = useMemo((): ItemWithLabel[] => {
    if (!leafletMap) return [];
    if (isShifted)
      return [
        {
          label: t("components.mapOverlay.contextMenu.hint.noShifted"),
          onClick: () => {},
          disabled: true,
          children: undefined,
        },
      ];
    return selectedMission
      ? [
          {
            id: "add-hint",
            label: t("components.mapOverlay.contextMenu.hint.addHint"),
            onClick: () => {
              toggleMapLayerBarVisibility(false);
              const point: Point = new Point(
                contextPosition[0],
                contextPosition[1]
              );
              const latLng: LatLng = leafletMap.containerPointToLatLng(point);
              setSelectedHint(
                createEmptyHint({ coords: [latLng.lng, latLng.lat] })
              );
            },
            children: undefined,
          },
        ]
      : [
          {
            id: "no-mission",
            label: t("components.mapOverlay.contextMenu.hint.noMission"),
            onClick: () => {},
            disabled: true,
            children: undefined,
          },
        ];
  }, [selectedMission, t, contextPosition, leafletMap, isShifted]);

  return (
    <div onContextMenu={handleContextMenu} className="map-overlay-wrapper">
      <MapComponent
        center={mapCenter}
        mapItems={[
          ...generalItems,
          ...missionItems,
          ...liveItems,
          ...stagingItems,
          ...hydrantenItems,
          ...missionHintItems,
        ]}
        mapSections={sections}
        visibleMapLayers={visibileMapLayers}
        setLassoHandler={setLassoHandler}
        setLeafletMap={setLeafletMap}
        closeContextOnDrag={() => hideAll()}
      />
      {leafletMap && (
        <div className="map-overlay">
          <MapLeftMenu lassoHandler={lassoHandler} />
          {selectedHint && (
            <MapHintForm
              submitHint={submitHint}
              selectedHint={selectedHint}
              setSelectedHint={setSelectedHint}
            />
          )}
          <div className="mapoverlay-bar-controls-container ">
            <MapLayerBar
              active={mapLayerBarVisible}
              onClose={() => toggleMapLayerBarVisibility(false)}
              mapLayers={Object.keys(MapLayer)
                .map(
                  (key): SingleMapLayerProps => ({
                    key: key as MapLayer,
                    layerName: t(`components.mapLayerBar.${key}`),
                    visible: !!mapLayersVisibility[key as MapLayer],
                    onClick: (visible: boolean) =>
                      setMapLayersVisibility((layers) => ({
                        ...layers,
                        [key]: visible,
                      })),
                  })
                )
                .filter((layer) => layer.key !== MapLayer.GENERAL)}
            />
            <MapOverlayControls
              active={mapLayerBarVisible}
              onClick={(value) => {
                toggleMapLayerBarVisibility(value);
                setSelectedHint(undefined);
              }}
              icon={<EyeIcon />}
            />
          </div>
          <ContextMenu
            title={t("components.mapOverlay.contextMenu.title")}
            menuId="map-context-menu"
            menuItems={menuItems}
          />
        </div>
      )}
    </div>
  );
};

export default MapOverlay;
