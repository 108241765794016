import {
  ButtonComponent,
  TableCell,
  TableComponent,
  TableRow,
  ToggleComponent,
} from "articon-component-library";
import useSWR from "swr";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import VehicleEditor from "../components/vehicle/VehicleEditor";
import { formatNumberToString } from "../utils/general/String.utils";
import { Vehicle } from "../utils/vehicle/Vehicle.types";
import {
  getAllVehicleBlueprint,
  getAllVehiclesByCustomerAndMission,
} from "../utils/vehicle/Vehicle.axios";
import { useUser } from "../utils/auth/UserContext";
import { useNavigate } from "react-router";
import { useMission } from "../utils/mission/Mission.context";
import { User, UserRank } from "../utils/user/User.types";
import { loadManyUsers } from "../utils/user/User.axios";
import { flattenMapValues } from "../utils/General.util";
import { getQualifications } from "../utils/qualifications/Qualification.axios";
import {
  Qualification,
  QualificationDisplayType,
} from "../utils/qualifications/Qualification.types";
import { sortUserByRank } from "../utils/user/User.utils";
import { fetchEinheitenWithIds } from "../utils/einheit/Einheit.axios";
import { updateMissionTactical } from "../utils/mission/Mission.axios";

const TacticalOverview: React.FC = () => {
  const { t } = useTranslation();
  const { user, axios } = useUser();
  const { selectedMission } = useMission();
  const [isExtendedView, toggleExtendedView] = useState<boolean>(false);
  const [clickedRowId, setClickedRowId] = useState<string>("");
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();

  const vehicles = useSWR(
    selectedMission
      ? [
          "mission/vehicle/mission/customer",
          user.customerUid,
          selectedMission.uid,
        ]
      : null,
    ([, customerUid, missionUid]) =>
      getAllVehiclesByCustomerAndMission(axios, customerUid, missionUid),
    {
      fallbackData: [],
    }
  );

  const vehicleBlueprints = useSWR(
    !!axios ? "/mission/vehicle/blueprint" : null,
    () => (!!axios ? getAllVehicleBlueprint(axios) : []),
    { fallbackData: [] }
  );

  const vehicleUsers = useSWR(
    !!axios && selectedMission
      ? ["mission/vehicle/mission/users", selectedMission.uid]
      : null,
    () =>
      loadManyUsers(
        axios,
        flattenMapValues(selectedMission?.fahrzeugeWithUsers ?? new Map())
      ),
    {
      fallbackData: [],
    }
  );

  const einheiten = useSWR(
    !!axios && selectedMission && vehicles.data ? "mission/units" : null,
    () =>
      fetchEinheitenWithIds(
        axios,
        vehicles.data.flatMap((vehicle) => vehicle.unit)
      ),
    {
      fallbackData: [],
    }
  );

  const qualifications = useSWR(
    !!axios ? "/qualifications" : null,
    () => (!!axios ? getQualifications(axios) : []),
    { fallbackData: [] }
  );

  const navigate = useNavigate();

  /**
   * Memorize the header row of the table
   */
  const header = useMemo<TableRow>(() => {
    let qualificationHeaders: TableCell[] = isExtendedView
      ? qualifications.data
          ?.filter(
            (quali) =>
              quali.displayType === QualificationDisplayType.PRIMARY ||
              quali.displayType === QualificationDisplayType.SECONDARY
          )
          .map((quali) => ({ value: quali.name })) || []
      : qualifications.data
          ?.filter(
            (quali) => quali.displayType === QualificationDisplayType.PRIMARY
          )
          .map((quali) => ({ value: quali.name })) || [];
    return {
      value: [
        { value: t("pages.tacticalOverview.tableHeader.funkrufname") },
        { value: t("pages.tacticalOverview.tableHeader.type") },
        { value: t("pages.tacticalOverview.tableHeader.section") },
        { value: t("pages.tacticalOverview.tableHeader.besatzung") },
        ...qualificationHeaders,
        { value: t("pages.tacticalOverview.tableHeader.watertank") },
        { value: t("pages.tacticalOverview.tableHeader.schaummittel") },
        { value: t("pages.tacticalOverview.tableHeader.einheit") },
        { value: t("pages.tacticalOverview.tableHeader.lastUpdate") },
      ],
    };
  }, [t, isExtendedView, qualifications.data]);
  /**
   * Memorize the header row of the table
   */
  const bottomHeader = useMemo<TableRow>(() => {
    let qualificationHeaders: TableCell[] = isExtendedView
      ? qualifications.data
          ?.filter(
            (quali) =>
              quali.displayType === QualificationDisplayType.PRIMARY ||
              quali.displayType === QualificationDisplayType.SECONDARY
          )
          .map((quali) => ({ value: quali.name })) || []
      : qualifications.data
          ?.filter(
            (quali) => quali.displayType === QualificationDisplayType.PRIMARY
          )
          .map((quali) => ({ value: quali.name })) || [];
    return {
      value: [
        { value: "" },
        { value: t("pages.tacticalOverview.tableHeader.besatzung") },
        ...qualificationHeaders,
        { value: t("pages.tacticalOverview.tableHeader.watertank") },
        { value: t("pages.tacticalOverview.tableHeader.schaummittel") },
      ],
    };
  }, [t, isExtendedView, qualifications.data]);

  /**
   * Helper to render the crew column
   * @param filteredVehicleUsers  The users to create the crew column for
   * @returns  The rendered crew column container
   */
  const getSortedUserElementForCrewColumn = (
    filteredVehicleUsers: User[],
    isTotalRow: boolean = false
  ): JSX.Element[] => {
    const sortedUserElement: JSX.Element[] = [];
    const sortedUsersArray = Array.from(
      sortUserByRank(filteredVehicleUsers).entries()
    );
    sortedUsersArray.forEach(([rank, users], index) => {
      sortedUserElement.push(
        <p className="tactical-overview__single-crew">
          {users.length}
          {index < Object.values(UserRank).length - 1 && "/"}
        </p>
      );
    });
    isTotalRow
      ? sortedUserElement.push(
          <p className="tactical-overview__total-crew">
            {` = ${filteredVehicleUsers.length}`}
          </p>
        )
      : sortedUserElement.push(
          <p className="tactical-overview__total-crew">
            {`(${filteredVehicleUsers.length})`}
          </p>
        );
    return sortedUserElement;
  };

  const getHackyNumber = (vehicleName: string): number => {
    const blueprintName: string =
      vehicleBlueprints.data.find((print) => print.uid === vehicleName)?.name ||
      "";
    switch (blueprintName) {
      case "ELW 1":
        return 0;
      case "SW 2000":
        return 0;
      case "DLK 23/12":
        return 2;
      case "LF 8/6":
        return 4;
      case "HLF 10":
        return 4;
      case "LF 20":
        return 4;
      default:
        return 0;
    }
  };

  /**
   * Helper to render the qualification columns
   * @param qualifications The qualifications to render
   * @param filteredVehicleUsers The users to create the qualification columns for
   * @returns The rendered qualification columns
   */
  const getQualifiedForUsers = (
    qualifications: Qualification[],
    filteredVehicleUsers: User[],
    isTotalRow: boolean = false,
    vehicleBluePrintUid: string = ""
  ): TableCell[] => {
    return qualifications.map((quali) => {
      const amountUsersWithQualification: number = filteredVehicleUsers.filter(
        (user) =>
          !!user.qualifications.find(
            (qualification) => qualification.uid === quali.uid
          )
      ).length;
      const qualificationColor: string =
        amountUsersWithQualification === 0
          ? " red"
          : amountUsersWithQualification >= getHackyNumber(vehicleBluePrintUid)
          ? " green"
          : " yellow";
      return {
        value: (
          <p
            className={[
              "tactical-overview__qualification",
              isTotalRow ? "-total" : "",
              qualificationColor,
            ].join("")}
          >
            {`${
              filteredVehicleUsers.filter(
                (user) =>
                  !!user.qualifications.find(
                    (qualification) => qualification.uid === quali.uid
                  )
              ).length
            } ${
              isTotalRow ? "" : `| ${getHackyNumber(vehicleBluePrintUid)}`
            }  `}
          </p>
        ),
      };
    });
  };

  /**
   * Memorize the table rows based on the demo data
   */
  const rows: TableRow[] = useMemo(() => {
    return vehicles.data.map((vehicle) => {
      //Replace as soon as ART-169 is ready
      const filteredVehicleUsers = vehicleUsers.data.filter(
        (user) =>
          selectedMission?.fahrzeugeWithUsers
            .get(vehicle.uid)
            ?.includes(user.uid) ?? false
      );
      console.log(vehicle.blueprint);
      const qualificationValues: TableCell[] = isExtendedView
        ? getQualifiedForUsers(
            qualifications.data?.filter(
              (quali) =>
                quali.displayType === QualificationDisplayType.PRIMARY ||
                quali.displayType === QualificationDisplayType.SECONDARY
            ),
            filteredVehicleUsers,
            false,
            vehicle.blueprint?.uid
          )
        : getQualifiedForUsers(
            qualifications.data?.filter(
              (quali) => quali.displayType === QualificationDisplayType.PRIMARY
            ),
            filteredVehicleUsers,
            false,
            vehicle.blueprint?.uid
          );
      return {
        value: [
          {
            value: vehicle.names.join(", "),
          },
          {
            value: vehicle.blueprint?.name || "",
          },
          {
            value:
              selectedMission?.sections.find(
                (section) => section.uid === vehicle.sectionId
              )?.name ?? t("pages.tacticalOverview.tableHeader.noSection"),
          },
          {
            value: (
              <div className="tactical-overview__crew">
                {getSortedUserElementForCrewColumn(filteredVehicleUsers)}
              </div>
            ),
          },
          ...qualificationValues,
          {
            value: formatNumberToString(vehicle.watertankInLiters, {
              suffix: " l",
              falsyFallback: 0,
            }),
          },
          {
            value: vehicle.firefightingFoamInLiters,
          },
          {
            value:
              einheiten.data?.find((einheit) => einheit.uid === vehicle.unit)
                ?.displayName || "",
          },
          {
            value: dayjs(vehicle.lastUpdate).fromNow(),
          },
        ],

        onClick: () =>
          clickedRowId === vehicle.uid
            ? setClickedRowId("")
            : setClickedRowId(vehicle.uid),
        bottomActions: clickedRowId === vehicle.uid && [
          <ButtonComponent
            value={
              vehicle.gatewayUid
                ? t("pages.tacticalOverview.action.jumpMap")
                : t("pages.tacticalOverview.action.noGateway")
            }
            disabled={!vehicle.gatewayUid}
            onClick={() =>
              navigate("/map", {
                state: { selectedGatewayUid: vehicle.gatewayUid },
              })
            }
          />,
        ],
        bottomActionColor: "#fafafa",
      } as TableRow;
    });
  }, [
    vehicles,
    navigate,
    clickedRowId,
    t,
    isExtendedView,
    qualifications.data,
    einheiten.data,
    selectedMission,
    vehicleUsers.data,
  ]);

  /**
   * Memorize the table rows based on the demo data
   */
  const bottomRows: TableRow = useMemo(() => {
    //Replace as soon as ART-169 is ready
    const qualificationValues: TableCell[] = isExtendedView
      ? getQualifiedForUsers(
          qualifications.data?.filter(
            (quali) =>
              quali.displayType === QualificationDisplayType.PRIMARY ||
              quali.displayType === QualificationDisplayType.SECONDARY
          ),
          vehicleUsers.data,
          true
        )
      : getQualifiedForUsers(
          qualifications.data?.filter(
            (quali) => quali.displayType === QualificationDisplayType.PRIMARY
          ),
          vehicleUsers.data,
          true
        );
    return {
      onClick: () => {},
      value: [
        {
          value: <h2>Gesamt</h2>,
        },
        {
          value: (
            <div className="tactical-overview__crew-total">
              {getSortedUserElementForCrewColumn(vehicleUsers.data, true)}
            </div>
          ),
        },
        ...qualificationValues,
        {
          value: formatNumberToString(
            vehicles.data
              .flatMap((vehicle) => vehicle.watertankInLiters)
              .reduce((sum, currentLiters) => (sum += currentLiters), 0),
            {
              suffix: " l",
              falsyFallback: 0,
            }
          ),
        },
        {
          value: vehicles.data
            .flatMap((vehicle) => vehicle.firefightingFoamInLiters)
            .reduce((sum, currentLiters) => (sum += currentLiters), 0),
        },
      ],
    } as TableRow;
  }, [isExtendedView, qualifications.data, vehicleUsers.data, vehicles.data]);

  return (
    <div className="tactical-overview__wrapper">
      <div className="tactical-overview__padding">
        <div className="tactical-overview__header-container">
          <h2>{t("pages.tacticalOverview.header")}</h2>
          <ButtonComponent
            value={t("pages.tacticalOverview.updatedManually")}
            onClick={() =>
              updateMissionTactical(axios, selectedMission?.uid || "")
            }
          />
          <div className="tactical-overview__toggle-wrapper">
            <p>{t("pages.tacticalOverview.isExtendedView")}</p>

            <ToggleComponent
              checked={isExtendedView}
              onCheck={() => toggleExtendedView(!isExtendedView)}
            />
          </div>
        </div>
        <TableComponent tableHeader={header} tableEntries={rows} />
        <VehicleEditor
          vehicle={selectedVehicle}
          onClose={() => setSelectedVehicle(undefined)}
          onSave={() => {
            alert("Save");
            setSelectedVehicle(undefined);
          }}
        />
      </div>
      <div className="tactical-overview__bottom-wrapper">
        <TableComponent
          tableHeader={bottomHeader}
          tableEntries={[bottomRows]}
        />
      </div>
    </div>
  );
};

export default TacticalOverview;
