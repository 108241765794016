import { UserQualification } from "../qualifications/Qualification.types";

/**
 * The role defines what permissions the user has
 */
export enum UserRole {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  UNIT_ADMIN = "UNIT_ADMIN",
  SUPER_USER = "SUPER_USER",
  USER = "USER",
  UNIT_USER = "UNIT_USER",
}

/**
 * This constant defines the general rank a user has in the group. As this is
 * highly operative the values are not translated but used as they are in the
 * field!
 */
export enum UserRank {
  VF = "VF",
  ZF = "ZF",
  GF = "GF",
  MANNSCHAFT = "MANNSCHAFT",
}

/**
 * This is the basic user
 */
export interface User {
  uid: string;
  firstname: string;
  lastname: string;
  mail: string;
  role: UserRole;
  customerUid: string;
  tokens: string[];
  userRank: UserRank;
  birthdate?: Date;
  serviceUser: boolean;
  notAuthenticated: boolean;
  qualifications: UserQualification[];
}

export type NoMailUser = User & { mail?: string };

/**
 * generates a new empty user for creation
 * @returns empty user
 */
export const generateEmptyUser = (customerUid: string): User => ({
  uid: undefined!,
  firstname: "",
  lastname: "",
  mail: "",
  role: UserRole.UNIT_USER,
  customerUid,
  tokens: [],
  userRank: UserRank.MANNSCHAFT,
  serviceUser: false,
  qualifications: [],
  notAuthenticated: false,
});
