import React from "react";

export interface MapOverlayControlsProps {
  active: boolean;
  onClick: (checked: boolean) => void;
  icon: JSX.Element;
}

const MapOverlayControls: React.FC<MapOverlayControlsProps> = ({
  active,
  onClick,
  icon,
}) => {
  return (
    <div
      className={["mapoverlay-controls__wrapper", active ?? "active"].join(" ")}
      onClick={() => onClick(!active)}
    >
      {icon}
    </div>
  );
};

export default MapOverlayControls;
