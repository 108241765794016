import {
  InputComponent,
  ButtonComponent,
  DropdownComponent,
} from "articon-component-library";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createNewStagingRoom,
  updateStagingRoom,
} from "../utils/staging/Staging.axios";
import {
  generateEmptyStagingRoom,
  StagingRoom,
  StagingRoomStatus,
} from "../utils/staging/Staging.types";
import { createDropDownOptionsForStagingRoomStatus } from "../utils/staging/Staging.utils";
import { useUser } from "../utils/auth/UserContext";
import { AddressForm } from "./AddressForm";

interface CreateOrUpdateStagingRoomProps {
  stagingRoomToEdit?: StagingRoom;
  updateStagingRoomLocal?(stagingRoom: StagingRoom): void;
}

const CreateOrUpdateStagingRoom: React.FC<CreateOrUpdateStagingRoomProps> = ({
  stagingRoomToEdit,
  updateStagingRoomLocal,
}) => {
  const { t } = useTranslation();
  const [stagingRoom, setStagingRoom] = useState<StagingRoom>(
    stagingRoomToEdit || generateEmptyStagingRoom()
  );
  const { axios, user } = useUser();

  /**
   * Helper to submit customer after creation/change, will update when customer has uid and create
   * a new one otherwise
   */
  const handleSubmit = (): void => {
    if (!axios) {
      console.error(
        "Axios is undefined, therefore creation can't be performed"
      );
      return;
    }
    if (stagingRoom.uid)
      updateStagingRoom(axios, stagingRoom).then(() =>
        updateStagingRoomLocal?.(stagingRoom)
      );
    else
      createNewStagingRoom(axios, {
        ...stagingRoom,
        customerUid: user.customerUid,
      }).then((uid) => updateStagingRoomLocal?.({ ...stagingRoom, uid: uid }));
  };

  return (
    <form
      id="create-staging"
      onSubmit={(evt) => {
        evt.preventDefault();
        handleSubmit();
      }}
    >
      <InputComponent
        required
        label={t("components.createStaging.name")}
        value={stagingRoom.name}
        onChange={(value) =>
          setStagingRoom((staging) => ({ ...staging, name: value }))
        }
      />
      <AddressForm
        initAddress={stagingRoom.address}
        initCoordinates={[stagingRoom.lat, stagingRoom.lon]}
        onChange={(address, coordinates) =>
          setStagingRoom((current) => ({
            ...current,
            address,
            lat: coordinates[0],
            lng: coordinates[1],
          }))
        }
      />
      <DropdownComponent
        label={t("components.createStaging.status")}
        options={createDropDownOptionsForStagingRoomStatus()}
        selectedOption={stagingRoom.status}
        onChange={(value) =>
          setStagingRoom((staging) => ({
            ...staging,
            status: value as StagingRoomStatus,
          }))
        }
      />
      <ButtonComponent
        value={t(`buttons.${stagingRoom.uid ? "save" : "create"}`)}
        form="create-staging"
        type="submit"
      />
    </form>
  );
};
export default CreateOrUpdateStagingRoom;
