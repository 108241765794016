import {
  InputComponent,
  DropdownComponent,
  ButtonComponent,
} from "articon-component-library";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../utils/auth/UserContext";
import {
  updateHydrant,
  createNewHydrant,
} from "../utils/hydranten/Hydrant.axios";
import {
  Hydrant,
  generateEmptyHydrant,
  HydrantenDurchmesser,
  HydrantenTyp,
  Verteilerleitung,
} from "../utils/hydranten/Hydrant.types";
import {
  createDropDownOptionsForHydrantDurchmesser,
  createDropDownOptionsForHydrantenTyp,
  createDropDownOptionsForHydrantVerteilerLeitung,
} from "../utils/hydranten/Hydrant.utils";

interface CreateOrUpdateHydrantProps {
  hydrantToEdit?: Hydrant;
  updateHydrantLocal?(hydrant: Hydrant): void;
}

const CreateOrUpdateHydrant: React.FC<CreateOrUpdateHydrantProps> = ({
  hydrantToEdit,
  updateHydrantLocal,
}) => {
  const { t } = useTranslation();
  const [hydrant, setHydrant] = useState<Hydrant>(
    hydrantToEdit || generateEmptyHydrant()
  );
  const { axios } = useUser();

  /**
   * Helper to submit hydrant after creation/change, will update when hydrant has uid and create
   * a new one otherwise
   */
  const handleSubmit = (): void => {
    if (!axios) {
      console.error(
        "Axios is undefined, therefore creation can't be performed"
      );
      return;
    }
    if (hydrant.uid)
      updateHydrant(axios, hydrant).then(() => updateHydrantLocal?.(hydrant));
    else
      createNewHydrant(axios, hydrant).then((uid) =>
        updateHydrantLocal?.({ ...hydrant, uid: uid })
      );
  };

  return (
    <form
      id="create-hydrant"
      onSubmit={(evt) => {
        evt.preventDefault();
        handleSubmit();
      }}
    >
      <InputComponent
        required
        label={t("components.createHydrant.name")}
        value={hydrant.name}
        onChange={(value) =>
          setHydrant((staging) => ({ ...staging, name: value }))
        }
      />

      <DropdownComponent
        label={t("components.createHydrant.durchmesser")}
        required
        options={createDropDownOptionsForHydrantDurchmesser()}
        selectedOption={hydrant.durchmesser}
        onChange={(durchmesser) => {
          setHydrant({
            ...hydrant,
            durchmesser: durchmesser as HydrantenDurchmesser,
          });
        }}
      />
      <DropdownComponent
        label={t("components.createHydrant.typ")}
        options={createDropDownOptionsForHydrantenTyp()}
        selectedOption={hydrant.type}
        onChange={(hydrantKind) => {
          setHydrant({
            ...hydrant,
            type: hydrantKind as HydrantenTyp,
          });
        }}
      />
      <DropdownComponent
        label={t("components.createHydrant.verteilerLeitung")}
        options={createDropDownOptionsForHydrantVerteilerLeitung()}
        selectedOption={hydrant.verteilerLeitung}
        onChange={(verteilung) => {
          setHydrant({
            ...hydrant,
            verteilerLeitung: verteilung as Verteilerleitung,
          });
        }}
      />
      <InputComponent
        label={t("components.createHydrant.description")}
        value={hydrant.description}
        onChange={(value) =>
          setHydrant((hydrant) => ({ ...hydrant, description: value }))
        }
      />

      <div className="create-hydrant__coordinates-container">
        <InputComponent
          label={t("components.createHydrant.lat")}
          value={hydrant.lat}
          type="number"
          onChangeNumber={(value) =>
            setHydrant((hydrant) => ({ ...hydrant, lat: value }))
          }
        />
        <InputComponent
          label={t("components.createHydrant.lon")}
          value={hydrant.lon}
          type="number"
          onChangeNumber={(value) =>
            setHydrant((hydrant) => ({ ...hydrant, lon: value }))
          }
        />
      </div>

      <ButtonComponent
        value={t(`buttons.${hydrant.uid ? "save" : "create"}`)}
        form="create-hydrant"
        type="submit"
      />
    </form>
  );
};
export default CreateOrUpdateHydrant;
