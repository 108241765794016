import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { missionServiceBaseUrl } from "../user/User.axios";
import { Hint } from "./Hint.types";
import { HistoryEntry } from "../history/History.types";

/**
 * Retrieves all hints for a given missionUid
 *
 * @param {AxiosInstance} axios - An axios instance
 * @param {string} missionUid - The missionUid for which to retrieve all hints
 * @returns {Promise<Hint[]>} - A Promise containing an array of Hint objects
 */
export const getAllHintsByMissionUid = (
  axios: AxiosInstance,
  missionUid: string
): Promise<Hint[]> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${missionServiceBaseUrl}/hint/mission/`, {
      params: { missionUid },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error while loading hints", err);
      return [];
    });
};

/**
 * Delete hint with given hintUid
 *
 * @param {AxiosInstance} axios - An axios instance
 * @param {string} hintUid - The hintUid of the hint to be deleted
 * @returns {Promise<boolean>} - A Promise containing the success status of the deletion
 */
export const deleteHintByUid = (
  axios: AxiosInstance,
  hintUid: string
): Promise<HistoryEntry> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.reject();
  return axios
    .post(`${missionServiceBaseUrl}/hint/delete/`, undefined, {
      params: { hintUid },
    })
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Error while deleting hint", err);
      return Promise.reject(err);
    });
};

/**
 * Creates a new hint
 *
 * @param {AxiosInstance} axios - An axios instance
 * @param {Hint} newHint - The new hint to be created
 * @returns {HistoryEntry} - A Promise containing the HistoryEntry of the created hint
 */
export const createHint = (
  axios: AxiosInstance,
  newHint: Hint
): Promise<HistoryEntry> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.reject();
  return axios
    .post(`${missionServiceBaseUrl}/hint/`, newHint)
    .then((res) => {
      if (res.status === 201) return res.data;
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Error while creating hint", err);
      return Promise.reject(err);
    });
};

/**
 * Updates an existing hint
 *
 * @param {AxiosInstance} axios - An axios instance u
 * @param {Hint} updatedHint - The hint object with updated fields
 * @returns {Promise<HistoryEntry>} - A Promise containing the history entry
 */
export const updateHint = (
  axios: AxiosInstance,
  updatedHint: Hint
): Promise<HistoryEntry> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.reject();
  return axios
    .post(`${missionServiceBaseUrl}/hint/update/`, updatedHint)
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Error while loading hints", err);
      return Promise.reject(err);
    });
};

/**
 * Get all hint history entries for a given mission
 *
 * @param axios An axios instance
 * @param missionUid The missionUid for which to retrieve all hint history entries
 * @returns Promised list of hint history entries
 */
export const getHintHistory = (
  axios: AxiosInstance,
  missionUid: string
): Promise<HistoryEntry[]> =>
  axios
    .get(`${missionServiceBaseUrl}/hint/history/`, { params: { missionUid } })
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error("Request failed with status code " + res.status);
    })
    .catch((err) => {
      console.error("Error while loading hint history entries", err);
      return Promise.reject(err);
    });
