import { useState } from "react";
import { useMission } from "../../../utils/mission/Mission.context";
import WeatherWidget from "./leftMenuWidgets/WeatherWidget";
import { ReactComponent as ArrowIcon } from "../../../assets/close.svg";
import {
  ButtonComponent,
  DisclosureComponent,
} from "articon-component-library";
import { TimeControl } from "../../TimeControl";
import MapSectionTool from "./MapSectionTool";
import { LassoHandler } from "leaflet-lasso";
import { useTranslation } from "react-i18next";
import ForcesWidget, { WidgetType } from "./leftMenuWidgets/ForcesWidget";

const MapLeftMenu: React.FC<{
  lassoHandler?: LassoHandler;
}> = ({ lassoHandler }) => {
  const { t } = useTranslation();
  const { selectedMission } = useMission();
  const [isMenuOpen, toggleMenuOpen] = useState(false);

  return (
    <div
      className={`map-left-menu__wrapper ${isMenuOpen ? "opened" : "closed"}`}
      onContextMenu={(evt) => evt.stopPropagation()}
    >
      <div className="map-left-menu__open">
        <ButtonComponent
          value={t("components.mapLeftMenu.openMenu")}
          onClick={() => toggleMenuOpen(true)}
        />
      </div>
      <div
        className="map-left-menu__close"
        onClick={() => toggleMenuOpen(false)}
      >
        <ArrowIcon />
      </div>
      <div className="map-left-menu__content">
        {selectedMission && (
          <>
            <WeatherWidget selectedMission={selectedMission} />
            <div className="map-left-menu__content__disclosures">
              <ForcesWidget
                mission={selectedMission}
                type={WidgetType.ON_SITE}
              />
              <ForcesWidget
                mission={selectedMission}
                type={WidgetType.ON_APPROACH}
              />
              <ForcesWidget
                mission={selectedMission}
                type={WidgetType.ON_STAGING_ROOM}
              />
              <DisclosureComponent title={t("components.mapLeftMenu.sections")}>
                <MapSectionTool lassoHandler={lassoHandler} />
              </DisclosureComponent>
            </div>
            <TimeControl />
          </>
        )}
      </div>
    </div>
  );
};

export default MapLeftMenu;
