import { InputComponent, ButtonComponent } from "articon-component-library";
import React from "react";
import { useMission } from "../../../utils/mission/Mission.context";
import { useTranslation } from "react-i18next";
import { Hint } from "../../../utils/hint/Hint.types";
import { deleteHintByUid } from "../../../utils/hint/Hint.axios";
import { useUser } from "../../../utils/auth/UserContext";
import { useHistory } from "../../../utils/history/History.context";

interface MapHintFormProps {
  submitHint: (hint: Hint) => void;
  selectedHint: Hint;
  setSelectedHint: React.Dispatch<React.SetStateAction<Hint | undefined>>;
}

const MapHintForm: React.FC<MapHintFormProps> = ({
  submitHint,
  selectedHint,
  setSelectedHint,
}) => {
  const { selectedMission } = useMission();
  const { t } = useTranslation();
  const { axios } = useUser();
  const { appendHistoryEntries, isShifted } = useHistory();

  /**
   * Handles the deletion of a hint
   */
  const handleHintDeletion = async (): Promise<void> => {
    const historyEntry = await deleteHintByUid(axios, selectedHint.uid);
    appendHistoryEntries(historyEntry);
  };

  return (
    <div className="hint-container">
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          if (isShifted) return;
          submitHint(selectedHint);
          setSelectedHint(undefined);
        }}
      >
        <p>
          {selectedHint.uid
            ? t("components.mapOverlay.contextMenu.hint.header.edit")
            : t("components.mapOverlay.contextMenu.hint.header.new")}
        </p>
        <InputComponent
          disabled
          value={
            selectedMission?.stichwort ||
            t("components.mapSectionTool.noMission")
          }
          label={t("components.mapSectionTool.selectedMission")}
          required
        />
        <InputComponent
          required
          disabled={isShifted}
          value={selectedHint.content}
          label={t("components.mapOverlay.contextMenu.hint.content")}
          onChange={(content) => setSelectedHint({ ...selectedHint, content })}
        />
        <div className="hint-container__button-container">
          <ButtonComponent
            value={t("buttons.save")}
            type="submit"
            disabled={isShifted}
          />
          <ButtonComponent
            value={t("components.mapOverlay.contextMenu.hint.cancel")}
            onClick={() => {
              setSelectedHint(undefined);
            }}
          />
          <ButtonComponent
            value={t("buttons.delete")}
            onClick={() => {
              if (isShifted) return;
              handleHintDeletion();
              setSelectedHint(undefined);
            }}
            disabled={isShifted}
          />
        </div>
      </form>
    </div>
  );
};

export default MapHintForm;
