import React from "react";
import { SingleMapLayerProps } from "../../../utils/map/Map.types";
import { ToggleComponent } from "articon-component-library";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../../assets/close.svg";

interface MapLayerBarProps {
  mapLayers?: SingleMapLayerProps[];
  active: boolean;
  onClose: () => void;
}

/**
 * Describes a single map layer with its name and icon
 */
const SingleMapLayer: React.FC<SingleMapLayerProps> = ({
  layerName,
  layerIcon,
  onClick = () => {},
  visible,
}) => {
  return (
    <div className="maplayer__wrapper" onClick={() => onClick(!visible)}>
      <div className="maplayer__name">{layerName}</div>
      <ToggleComponent checked={visible} onCheck={onClick} />
    </div>
  );
};

/**
 * A component bar that displays toggles for diffetent map layers
 */
const MapLayerBar: React.FC<MapLayerBarProps> = ({
  mapLayers = [],
  active,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <div className={["maplayerbar__wrapper", active ? "active" : ""].join(" ")}>
      <div className="maplayerbar__layers">
        <div className="maplayerbar__header-container">
          <p>{t("components.mapLayerBar.header")}</p>
          <CloseIcon onClick={() => onClose()} />
        </div>
        <div className="maplayerbar__toggle-wrapper">
          <p>{t("components.mapLayerBar.toggle")}</p>
          {mapLayers.map((layer) => (
            <SingleMapLayer {...layer} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MapLayerBar;
