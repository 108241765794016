import axios from "axios";
import { OpenWeatherModel, OpenWeatherResponse } from "./Weather.types";

const openWeatherApiKey: string = process.env
  .REACT_APP_OPEN_WEATHER_KEY as string;

/**
 * Util method to load the openweather weather for the given position
 *
 * @param pos The position to fetch the weather for in the format [lat,long]
 * @returns The weather object
 */
export const loadWeatherData = async (
  pos?: number[]
): Promise<OpenWeatherResponse | undefined> => {
  if (!pos) return;
  return await axios
    .get(
      `https://api.openweathermap.org/data/2.5/weather?lat=${pos[0]}&lon=${pos[1]}&appid=${openWeatherApiKey}&units=metric&lang=de`
    )
    .then((resp) => OpenWeatherModel.parse(resp.data))
    .catch((exc) => {
      console.error("Error during weather fetch!", exc);
      return undefined;
    });
};

/**
 * Helper to load the correct weather icon for the current weather
 *
 * @param weatherObject The loaded openweather object
 * @returns The URL of the weather icon
 * @TODO test this method as soon as ART-172 is implemented!
 */
export const getIconUrlForWeather = (
  weatherObject: OpenWeatherResponse | undefined
): string => {
  if (!weatherObject || weatherObject.weather?.length === 0) return "";
  const weatherCode: string = weatherObject.weather[0].icon || "";
  if (!weatherCode) return "";
  return `https://openweathermap.org/img/wn/${weatherCode}@4x.png`;
};

/**
 * Helper to transform mps in kmph
 *
 * @param mps The velocity in meter per seconds
 * @returns The velocity in kilometer per hour
 * @TODO test this method as soon as ART-172 is implemented!
 */
export const calculateKmphFromMps = (mps: number = 0): number => {
  return +((mps || 0) * 3.6).toFixed(0);
};
